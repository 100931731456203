import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Icon,
  Button,
  Box,
  Flex,
} from '@chakra-ui/react';
import { RiDeleteBinLine, RiEditLine } from 'react-icons/ri';
import { ILeadContactsModalFormData } from '../LeadContactsModal';

export interface ILeadContactsTableContact extends ILeadContactsModalFormData {
  index: number;
}

interface ILeadContactsTableProps {
  leadContacts: ILeadContactsModalFormData[];
  onDelete?: (contactIndex: number) => void;
  onEdit?: (leadContact: ILeadContactsTableContact) => void;
}

export const LeadContactsTable = ({
  leadContacts,
  onDelete,
  onEdit,
}: ILeadContactsTableProps): JSX.Element => (
  <Table colorScheme="blue">
    <Thead>
      <Tr>
        <Th>Nome</Th>
        <Th>Contatos</Th>
        <Th>Descrição</Th>
        {!!onDelete && !!onEdit && <Th w="8" />}
      </Tr>
    </Thead>
    <Tbody>
      {leadContacts.map((contact, index) => (
        <Tr key={String(index)}>
          <Td>
            <Text fontWeight="bold">{contact.name}</Text>
          </Td>

          <Td>
            <Box>
              <Text>{contact.phone}</Text>
              <Text mt="2">{contact.email}</Text>
            </Box>
          </Td>

          <Td>
            <Text>{contact.description}</Text>
          </Td>

          {!!onDelete && !!onEdit && (
            <Td>
              <Flex flexDirection="column">
                <Button
                  size="sm"
                  fontSize="sm"
                  colorScheme="yellow"
                  color="white"
                  onClick={() => onEdit({ ...contact, index })}
                >
                  <Icon as={RiEditLine} fontSize="20" />
                </Button>

                <Button
                  mt="2"
                  size="sm"
                  fontSize="sm"
                  colorScheme="red"
                  onClick={() => onDelete(index)}
                >
                  <Icon as={RiDeleteBinLine} fontSize="20" />
                </Button>
              </Flex>
            </Td>
          )}
        </Tr>
      ))}
    </Tbody>
  </Table>
);
