import {
  Box,
  BoxProps,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';

export type TeamTableItem = {
  id: string;
  interest: string;
  leadsMax: number;
  minValue: number;
  maxValue: number;
  name: string;
  type: string;
};

interface ITeamTableProps extends BoxProps {
  currentOrder?: 'ASC' | 'DESC';
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'name' | 'type' | 'interest') => void;
  sortBy?: string;
  teams: TeamTableItem[];
  totalPages?: number;
}

export const TeamTable = ({
  currentOrder,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  sortBy,
  teams,
  totalPages,
  ...rest
}: ITeamTableProps): JSX.Element => {
  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(sort as 'name' | 'type' | 'interest');
      }
    },
    [onSort],
  );

  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Tipo de time',
        value: 'type',
      },
      {
        label: 'Tipo de interesse',
        value: 'interest',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        currentOrder={currentOrder}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={handleSort}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Ativo</Th>
            <Th>Tipo de time</Th>
            <Th>Tipo de interesse</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {teams.map((team) => (
            <Tr key={team.id}>
              <Td>
                <Text fontWeight="bold">{team.name}</Text>
              </Td>

              <Td>ativo</Td>

              <Td>{team.type}</Td>

              <Td>{team.interest}</Td>

              <Td>
                <LinkButton
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/teams/details',
                    state: {
                      teamId: team.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
