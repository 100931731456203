import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';
import { UserRoleBase } from '../../models/userRole';

interface IUserTeamsUnassignProps {
  userId: string;
  teamsId: Array<string>;
}

export interface IUserTeamsUnassign extends UserBase {
  teams: TeamBase[];
  userRoles: UserRoleBase[];
}

export const userTeamsUnassignService = async ({
  teamsId,
  userId,
}: IUserTeamsUnassignProps): Promise<IUserTeamsUnassign> => {
  const { data } = await api.patch<IUserTeamsUnassign>(
    `/users/${userId}/teams-unassign`,
    { teamsId },
  );

  return data;
};
