import { Box, Divider, Heading, VStack } from '@chakra-ui/react';

import { DefaultLayout } from '../../_layout/DefaultLayout';
import AppointmentHistoryReports from './components/AppointmentHistoryReports';
import AppointmentMonthStatusReports from './components/AppointmentMonthStatusReports';
import AppointmentReports from './components/AppointmentReports';

const Reports = (): JSX.Element => (
  <DefaultLayout>
    <Box flex="1" borderRadius={8} bg="white" p="8">
      <Heading size="lg" fontWeight="normal">
        Relatórios
      </Heading>

      <VStack align="flex-start" spacing="8" mt="8">
        <AppointmentReports />

        <Divider borderColor="gray.300" />

        <AppointmentMonthStatusReports />

        <Divider borderColor="gray.300" />

        <AppointmentHistoryReports />
      </VStack>
    </Box>
  </DefaultLayout>
);

export default Reports;
