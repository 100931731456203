import {
  Box,
  Table,
  Text,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';

export type PropertyOwnerTableItem = {
  id: string;
  email: string;
  mainPhone: string;
  name: string;
};

interface IPropertyOwnerTableProps extends BoxProps {
  currentOrder?: 'ASC' | 'DESC';
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'name' | 'email' | 'mainPhone') => void;
  propertyOwners: PropertyOwnerTableItem[];
  sortBy?: string;
  totalPages?: number;
}

export const PropertyOwnerTable = ({
  currentOrder,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  propertyOwners,
  sortBy,
  totalPages,
  ...rest
}: IPropertyOwnerTableProps): JSX.Element => {
  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(sort as 'name' | 'email' | 'mainPhone');
      }
    },
    [onSort],
  );

  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'Telefone principal',
        value: 'mainPhone',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        currentOrder={currentOrder}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={handleSort}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Email</Th>
            <Th>Telefone</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {propertyOwners.map((propertyOwner) => (
            <Tr key={propertyOwner.id}>
              <Td>
                <Text fontWeight="bold">{propertyOwner.name}</Text>
              </Td>

              <Td>
                <Text>{propertyOwner.email}</Text>
              </Td>

              <Td>
                <Text>{propertyOwner.mainPhone}</Text>
              </Td>

              <Td>
                <LinkButton
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/property-owners/details',
                    state: {
                      propertyOwnerId: propertyOwner.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
