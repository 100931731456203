import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  SimpleGrid,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  ButtonGroup,
  HStack,
  Divider,
} from '@chakra-ui/react';
import {
  RiAddLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
  RiEyeLine,
  RiPencilLine,
} from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import { LeadContactsTable } from '../components/LeadContactsTable';
import { AppointmentUserAssignModal } from '../../components/AppointmentUserAssignModal';
import {
  IDetailedLead,
  ILeadDeailedAppointment,
  showLeadsService,
} from '../../../../../services/Leads/ShowLeadsService';
import {
  AppointmentStatus,
  AppointmentStatusColor,
} from '../../../../../models/appointment';
import { createAppointmentsService } from '../../../../../services/Appointments/CreateAppointmentsService';
import { deleteLeadsService } from '../../../../../services/Leads/DeleteLeadsService';
import { useAuth } from '../../../../../hooks/auth';

enum Interest {
  sell = 'Compra',
  rent = 'Locação',
  sellAndRent = 'Compra e locação',
}
interface IAppointment extends ILeadDeailedAppointment {
  parsedDate: string;
}

interface ILead extends IDetailedLead {
  appointments: IAppointment[];
  parsedDate?: string;
}

interface ILocationState {
  leadId?: string;
}

export const LeadDetails = (): JSX.Element => {
  const { user } = useAuth();
  const toast = useToast();
  const { push } = useHistory();
  const {
    state: { leadId },
  } = useLocation<ILocationState>();

  const [lead, setLead] = useState<ILead>();
  const [showContacts, setShowContacts] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isCustomerServiceAssignModalVisible,
    setIsCustomerServiceAssignModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadLead(showLeadId: string): Promise<void> {
      try {
        const leadDetails = await showLeadsService(showLeadId);

        const parsedAppointments = leadDetails.appointments.map(
          (appointment) => ({
            ...appointment,
            parsedDate: maskDate(appointment.createdAt),
          }),
        );

        const parsedLeads = {
          ...leadDetails,
          appointments: parsedAppointments,
          parsedDate: leadDetails.createdAt && maskDate(leadDetails.createdAt),
        };

        setLead(parsedLeads);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do lead, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (leadId) {
      loadLead(leadId);
    }
  }, [leadId, toast]);

  const handleToggleShowContacts = useCallback(() => {
    setShowContacts((prevState) => !prevState);
  }, []);

  const handleToggleCustomerServiceAssignModal = useCallback(() => {
    setIsCustomerServiceAssignModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleAppointmentUserAssign = useCallback(
    async (selectedUserId: string) => {
      if (leadId) {
        try {
          const newAppointment = await createAppointmentsService({
            leadId,
            userId: selectedUserId,
          });

          toast({
            title: 'Vinculado com sucesso',
            description: 'O atendimento foi vinculado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          push('/appointments/details', { appointmentId: newAppointment.id });
        } catch (err) {
          toast({
            title: 'Falha ao vincular',
            description:
              'Ocorreu um erro ao vincular atendimento, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [leadId, push, toast],
  );

  const handleDeleteLead = useCallback(async () => {
    if (leadId) {
      try {
        await deleteLeadsService(leadId);

        toast({
          title: 'Excluído com sucesso',
          description: 'O lead foi excluído corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/leads');
      } catch (err) {
        toast({
          title: 'Falha ao excluir',
          description: 'Ocorreu um erro ao excluir o lead, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [leadId, push, toast]);

  return (
    <DefaultLayout>
      <AppointmentUserAssignModal
        isOpen={isCustomerServiceAssignModalVisible}
        onClose={handleToggleCustomerServiceAssignModal}
        onSubmit={handleAppointmentUserAssign}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteLead}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do lead
          </Heading>

          {user.isAdmin && (
            <ButtonGroup>
              <LinkButton
                colorScheme="yellow"
                color="white"
                icon={<Icon as={RiEditLine} fontSize="16" />}
                to={{
                  pathname: '/leads/update',
                  state: {
                    leadId,
                  },
                }}
              >
                Editar
              </LinkButton>

              <Button
                size="sm"
                fontSize="sm"
                colorScheme="red"
                onClick={handleToggleDeleteConfirmationModal}
                leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
              >
                Excluir
              </Button>
            </ButtonGroup>
          )}
        </Flex>

        {lead && (
          <>
            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {lead.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {lead.nickname}
              </Text>
            </Flex>

            <HStack justify="center" spacing="8" mt="8">
              <Flex align="center">
                <Icon
                  as={
                    lead.isActive
                      ? RiCheckboxCircleLine
                      : RiCheckboxBlankCircleLine
                  }
                  fontSize="xx-large"
                  color={lead.isActive ? 'green' : 'gray.400'}
                />
                <Text as="span" fontSize="xl" color="gray.500" ml="2">
                  Ativo
                </Text>
              </Flex>
            </HStack>

            <SimpleGrid
              minChildWidth="315px"
              spacingX="8"
              spacingY="4"
              px="8"
              w="100%"
              mt="4"
            >
              <Text>
                Telefone:
                <Text as="span" color="gray.500" ml="2">
                  {lead.phone}
                </Text>
              </Text>

              <Text>
                E-mail:
                <Text as="span" color="gray.500" ml="2">
                  {lead.email}
                </Text>
              </Text>

              <Text>
                Interesse:
                <Text as="span" color="gray.500" ml="2">
                  {Interest[lead.interest]}
                </Text>
              </Text>

              <Text>
                Tipo de mídia:
                <Text as="span" color="gray.500" ml="2">
                  {lead.media?.name}
                </Text>
              </Text>

              <Text>
                Cadastrado em:
                <Text as="span" color="gray.500" ml="2">
                  {lead.parsedDate}
                </Text>
              </Text>

              {lead.description && (
                <Text maxW="900">
                  Descrição:
                  <Text as="span" color="gray.500" ml="2">
                    {lead.description}
                  </Text>
                </Text>
              )}
            </SimpleGrid>

            <Divider my="4" borderColor="gray.300" />

            <Box>
              <HStack spacing="8" align="flex-end">
                <Heading size="md" fontWeight="normal">
                  Contatos
                </Heading>

                <Button
                  size="sm"
                  fontSize="sm"
                  colorScheme="blue"
                  variant="link"
                  onClick={handleToggleShowContacts}
                  leftIcon={<Icon as={RiEyeLine} fontSize="20" />}
                >
                  Ver contatos
                </Button>
              </HStack>

              {showContacts && (
                <LeadContactsTable leadContacts={lead.contacts} />
              )}
            </Box>

            <Divider my="8" borderColor="gray.300" />

            <Flex my="4" justify="space-between" align="center">
              <Heading size="md" fontWeight="normal">
                Atendimentos vinculados
              </Heading>

              {user.isAdmin && (
                <Button
                  size="sm"
                  fontSize="sm"
                  colorScheme="green"
                  onClick={handleToggleCustomerServiceAssignModal}
                  leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                >
                  Abrir atendimento
                </Button>
              )}
            </Flex>

            <Table colorScheme="blue">
              <Thead>
                <Tr>
                  <Th>Corretor</Th>
                  <Th>Status</Th>
                  <Th>Em atendimento desde</Th>
                  <Th w="8" />
                </Tr>
              </Thead>
              <Tbody>
                {lead.appointments.map((appointment) => (
                  <Tr>
                    <Td>
                      <Text fontWeight="bold">{appointment.user.name}</Text>
                      <Text fontSize="sm">{appointment.user.creci}</Text>
                    </Td>

                    <Td>
                      <Box
                        borderRadius={8}
                        bg={AppointmentStatusColor[appointment.status]}
                        textAlign="center"
                        padding="2"
                      >
                        <Text color="white">
                          {AppointmentStatus[appointment.status]}
                        </Text>
                      </Box>
                    </Td>

                    <Td>{appointment.parsedDate}</Td>

                    <Td>
                      <LinkButton
                        icon={<Icon as={RiPencilLine} fontSize="16" />}
                        to={{
                          pathname: '/appointments/details',
                          state: {
                            appointmentId: appointment.id,
                            leadId,
                          },
                        }}
                      >
                        Detalhes
                      </LinkButton>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        )}
      </Box>
    </DefaultLayout>
  );
};
