import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Thead,
  Th,
  Table,
  Td,
  Tbody,
  Tr,
} from '@chakra-ui/react';
import {
  AppointmentHistory,
  AppointmentStatus,
} from '../../../../../../../models/appointment';

interface IHistory extends AppointmentHistory {
  parsedDate: string;
}

interface ILeadsAssignModalProps {
  histories: IHistory[];
  isOpen: boolean;
  onClose: () => void;
}

export const AppointmentHistoryModal = ({
  histories,
  isOpen,
  onClose,
}: ILeadsAssignModalProps): JSX.Element => (
  <Modal size="6xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="lg" fontWeight="normal">
          Histórico de atendimento
        </Heading>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box>
          <Table>
            <Thead>
              <Th>Data</Th>
              <Th>Descrição</Th>
              <Th>Status</Th>
            </Thead>
            <Tbody>
              {histories.map((history) => (
                <Tr>
                  <Td>{history.parsedDate}</Td>
                  <Td>{history.description}</Td>
                  <Td>{AppointmentStatus[history.status]}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);
