import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Link as ChakraLink,
  Flex,
  Button,
  Icon,
  Text,
  SimpleGrid,
  Divider,
  useToast,
  ButtonGroup,
  VStack,
  Image,
  HStack,
} from '@chakra-ui/react';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
} from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import venturePlaceholder from '../../../../../assets/venture_placeholder.png';
import {
  maskArea,
  maskDate,
  maskMoney,
  maskZipCode,
} from '../../../../../utils/formatters/handleMask';
import { LinkButton } from '../../../../../components/LinkButton';
import {
  IDetailedProperty,
  showPropertiesService,
} from '../../../../../services/Properties/ShowPropertiesService';
import { deletePropertiesService } from '../../../../../services/Properties/DeletePropertiesService';
import { useAuth } from '../../../../../hooks/auth';

enum BusinessType {
  rent = 'Locação',
  sell = 'Venda',
  sellAndRent = 'Venda e Locação',
}

enum PropertyLevel {
  high = 'Alto',
  low = 'Baixo',
  medium = 'Médio',
  regular = 'Regular',
}

enum RegionStandardLevel {
  good = 'Bom',
  medium = 'Médio',
  veryGood = 'Muito bom',
  privileged = 'Privilegiado',
  regular = 'Regular',
}

enum PropertyCategory {
  apartment = 'Apartamento',
  commercialRoom = 'Sala comercial',
  default = 'Padrão',
  duplex = 'Duplex',
  duplexPenthouse = 'Cobertura Duplex',
  farm = 'Chácara',
  farmhouse = 'Fazenda',
  garageBox = 'BoxGaragem',
  house = 'Casa',
  penthouse = 'Cobertura',
  smallFarm = 'Sítio',
  studio = 'Studio',
  triplex = 'Triplex',
  twoStoryHouse = 'Sobrado',
  woodhouse = 'Casa de madeira',
}

enum KeyHolder {
  user = 'Corretor',
  realEstate = 'Imobiliária',
  concierge = 'Porteiro',
  propertyOwner = 'Proprietário',
}

enum CommercialStatus {
  default = 'Padrão',
  futureRelease = 'Futuro lançamento',
  lastUnits = 'Últimas unidades',
  preRelease = 'Pré-lançamento',
  ready = 'Pronto para morar',
  release = 'Lançamento',
  resale = 'Revenda',
}

enum Topography {
  downhill = 'Declive',
  plain = 'Plano',
  uphill = 'Aclive',
}

enum Stage {
  done = 'Pronto',
  underConstruction = 'Em construção',
}

interface IProperty extends IDetailedProperty {
  formattedCreatedAt: string;
  formattedCondominiumPrice: string;
  formattedDeliveryDate: string;
  formattedMonthlyUrbanTaxPrice: string;
  formattedRentPrice: string;
  formattedSellPrice: string;
  formattedTotalArea?: string;
  formattedUsefulArea: string;
  formattedZipCode: string;
}

interface ILocationState {
  propertyId?: string;
}

export const PropertyDetails = (): JSX.Element => {
  const { user } = useAuth();
  const toast = useToast();
  const {
    state: { propertyId },
  } = useLocation<ILocationState>();
  const { push } = useHistory();

  const [property, setProperty] = useState<IProperty>();
  const [previewIndex, setPeviewIndex] = useState(0);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadProperty(showPropertyId: string): Promise<void> {
      try {
        const propertyDetails = await showPropertiesService(showPropertyId);

        const parsedProperty = {
          ...propertyDetails,
          formattedCreatedAt: maskDate(propertyDetails.createdAt),
          formattedDeliveryDate: propertyDetails.deliveryDate
            ? maskDate(propertyDetails.deliveryDate)
            : '-',
          formattedCondominiumPrice: propertyDetails.condominiumPrice
            ? maskMoney(propertyDetails.condominiumPrice)
            : '-',
          formattedMonthlyUrbanTaxPrice: propertyDetails.monthlyUrbanTax
            ? maskMoney(propertyDetails.monthlyUrbanTax)
            : '-',
          formattedRentPrice: propertyDetails.rentPrice
            ? maskMoney(propertyDetails.rentPrice)
            : '-',
          formattedSellPrice: propertyDetails.sellPrice
            ? maskMoney(propertyDetails.sellPrice)
            : '-',
          formattedTotalArea: propertyDetails.totalArea
            ? maskArea(String(propertyDetails.totalArea))
            : '-',
          formattedUsefulArea: propertyDetails.usefulArea
            ? maskArea(String(propertyDetails.usefulArea))
            : '-',
          formattedZipCode: propertyDetails.address.zipCode
            ? maskZipCode(String(propertyDetails.address.zipCode))
            : '-',
        };

        setProperty(parsedProperty);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do emprendimento, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (propertyId) {
      loadProperty(propertyId);
    }
  }, [propertyId, toast]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteProperty = useCallback(async () => {
    if (propertyId) {
      try {
        await deletePropertiesService(propertyId);

        toast({
          title: 'Excluído com sucesso',
          description: 'O imóvel foi excluído corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/properties');
      } catch (err) {
        toast({
          title: 'Falha ao excluir',
          description: 'Ocorreu um erro ao excluir o imóvel, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [propertyId, push, toast]);

  const handleChangePreview = useCallback(
    (amount: number) => {
      if (
        !property?.images.length ||
        amount < 0 ||
        amount >= property.images.length
      ) {
        return;
      }

      setPeviewIndex(amount);
    },
    [property?.images.length],
  );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteProperty}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do imóvel
          </Heading>

          {user.isAdmin && (
            <ButtonGroup>
              <LinkButton
                colorScheme="yellow"
                color="white"
                icon={<Icon as={RiEditLine} fontSize="16" />}
                to={{
                  pathname: '/properties/update',
                  state: {
                    propertyId,
                  },
                }}
              >
                Editar
              </LinkButton>

              <Button
                size="sm"
                fontSize="sm"
                colorScheme="red"
                onClick={handleToggleDeleteConfirmationModal}
                leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
              >
                Excluir
              </Button>
            </ButtonGroup>
          )}
        </Flex>

        {property && (
          <Flex direction="column">
            <Flex align="center" justify="center" px="8">
              <Button
                colorScheme="blackAlpha"
                h="12"
                w="12"
                mr="8"
                borderRadius="50%"
                display="flex"
                p="2"
                onClick={() => handleChangePreview(previewIndex - 1)}
              >
                <Icon fontSize="xx-large" color="white" as={RiArrowLeftSLine} />
              </Button>

              <Box maxH="480px" minH="240px" minW="384px">
                <Image
                  borderRadius="8"
                  maxH="480px"
                  srcSet={
                    property.images.length
                      ? property.images[previewIndex].imageUrl
                      : venturePlaceholder
                  }
                />
              </Box>

              <Button
                colorScheme="blackAlpha"
                h="12"
                w="12"
                ml="8"
                borderRadius="50%"
                display="flex"
                p="2"
                onClick={() => handleChangePreview(previewIndex + 1)}
              >
                <Icon
                  fontSize="xx-large"
                  color="white"
                  as={RiArrowRightSLine}
                />
              </Button>
            </Flex>

            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {property.title}
              </Text>
              <Text color="gray.500">{property.condominiumName}</Text>
            </Flex>
            <HStack justify="center" spacing="8" mt="8">
              <Flex align="center">
                <Icon
                  as={
                    property.isActive
                      ? RiCheckboxCircleLine
                      : RiCheckboxBlankCircleLine
                  }
                  fontSize="xx-large"
                  color={property.isActive ? 'green' : 'gray.400'}
                />
                <Text as="span" fontSize="xl" color="gray.500" ml="2">
                  Ativo
                </Text>
              </Flex>

              <Flex align="center">
                <Icon
                  as={
                    property.isPublished
                      ? RiCheckboxCircleLine
                      : RiCheckboxBlankCircleLine
                  }
                  fontSize="xx-large"
                  color={property.isPublished ? 'green' : 'gray.400'}
                />
                <Text as="span" fontSize="xl" color="gray.500" ml="2">
                  Publicado
                </Text>
              </Flex>
            </HStack>

            <Divider my="6" borderColor="gray.300" />

            <VStack spacing="4">
              <SimpleGrid
                minChildWidth="315px"
                spacingX="8"
                spacingY="4"
                px="8"
                w="100%"
              >
                <Text>
                  Disponível para:
                  <Text as="span" color="blue.500" ml="2">
                    {BusinessType[property.businessType]}
                  </Text>
                </Text>

                <Text>
                  Fase:
                  <Text as="span" color="gray.500" ml="2">
                    {Stage[property.stage]}
                  </Text>
                </Text>

                <Text>
                  Preço do condomínio
                  <Text as="span" color="blue.500" ml="2">
                    {property.formattedCondominiumPrice}
                  </Text>
                </Text>

                <Text>
                  Preço de venda
                  <Text as="span" color="blue.500" ml="2">
                    {property.formattedSellPrice}
                  </Text>
                </Text>

                <Text>
                  Preço do aluguel
                  <Text as="span" color="blue.500" ml="2">
                    {property.formattedRentPrice}
                  </Text>
                </Text>

                <Text>
                  IPTU:
                  <Text as="span" color="gray.500" ml="2">
                    {property.formattedMonthlyUrbanTaxPrice}
                  </Text>
                </Text>

                <Text>
                  Área útil:
                  <Text as="span" color="gray.500" ml="2">
                    {property.formattedUsefulArea}
                  </Text>
                </Text>

                <Text>
                  Área total:
                  <Text as="span" color="gray.500" ml="2">
                    {property.formattedTotalArea}
                  </Text>
                </Text>

                <Text>
                  Data de entrega:
                  <Text as="span" color="gray.500" ml="2">
                    {property.formattedDeliveryDate}
                  </Text>
                </Text>

                <Text>
                  Código:
                  <Text as="span" color="gray.500" ml="2">
                    {property.ref || '-'}
                  </Text>
                </Text>

                <Text>
                  Matrícula do imóvel:
                  <Text as="span" color="gray.500" ml="2">
                    {property.propertyReg || '-'}
                  </Text>
                </Text>

                <Text>
                  Blocos:
                  <Text as="span" color="gray.500" ml="2">
                    {property.buildings || '-'}
                  </Text>
                </Text>

                <Text>
                  Salas:
                  <Text as="span" color="gray.500" ml="2">
                    {property.rooms || '-'}
                  </Text>
                </Text>

                <Text>
                  Suítes
                  <Text as="span" color="gray.500" ml="2">
                    {property.suites || '-'}
                  </Text>
                </Text>

                <Text>
                  Dormitórios:
                  <Text as="span" color="gray.500" ml="2">
                    {property.dormitories || '-'}
                  </Text>
                </Text>

                <Text>
                  Banheiros:
                  <Text as="span" color="gray.500" ml="2">
                    {property.bathrooms || '-'}
                  </Text>
                </Text>

                <Text>
                  Vagas:
                  <Text as="span" color="gray.500" ml="2">
                    {property.vacancies || '-'}
                  </Text>
                </Text>

                <Text>
                  Status comercial:
                  <Text as="span" color="gray.500" ml="2">
                    {property.commercialStatus
                      ? CommercialStatus[property.commercialStatus]
                      : '-'}
                  </Text>
                </Text>

                <Text>
                  Ano de construção:
                  <Text as="span" color="gray.500" ml="2">
                    {property.constructionYear || '-'}
                  </Text>
                </Text>

                <Text>
                  Local da chave:
                  <Text as="span" color="gray.500" ml="2">
                    {property.keyHolder
                      ? KeyHolder[property.keyHolder]
                      : 'Não especificado'}
                  </Text>
                </Text>

                <Text>
                  Categoria:
                  <Text as="span" color="gray.500" ml="2">
                    {property.propertyCategory
                      ? PropertyCategory[property.propertyCategory]
                      : 'Sem categoria'}
                  </Text>
                </Text>

                <Text>
                  Nível do imóvel:
                  <Text as="span" color="gray.500" ml="2">
                    {property.propertyLevel
                      ? PropertyLevel[property.propertyLevel]
                      : '-'}
                  </Text>
                </Text>

                <Text>
                  Padrão de região:
                  <Text as="span" color="gray.500" ml="2">
                    {property.regionStandardLevel
                      ? RegionStandardLevel[property.regionStandardLevel]
                      : '-'}
                  </Text>
                </Text>

                <Text>
                  Cadastrado em:
                  <Text as="span" color="gray.500" ml="2">
                    {property.formattedCreatedAt}
                  </Text>
                </Text>

                <Text>
                  Topografia:
                  <Text as="span" color="gray.500" ml="2">
                    {property.topography
                      ? Topography[property.topography]
                      : '-'}
                  </Text>
                </Text>

                <Text>
                  Aceita permuta:
                  <Text as="span" color="gray.500" ml="2">
                    {property.acceptExchange ? 'Sim' : 'Não'}
                  </Text>
                </Text>

                <Text>
                  Aceita Financiamento:
                  <Text as="span" color="gray.500" ml="2">
                    {property.acceptFunding ? 'Sim' : 'Não'}
                  </Text>
                </Text>

                <Text>
                  Exclusividade:
                  <Text as="span" color="gray.500" ml="2">
                    {property.isExclusive ? 'Sim' : 'Não'}
                  </Text>
                </Text>

                <Text>
                  Mobiliado:
                  <Text as="span" color="gray.500" ml="2">
                    {property.isFurnished ? 'Sim' : 'Não'}
                  </Text>
                </Text>
              </SimpleGrid>

              <Divider borderColor="gray.300" />

              <SimpleGrid
                minChildWidth="315px"
                spacingX="8"
                spacingY="4"
                px="8"
                w="100%"
              >
                <Text>
                  Cidade:
                  <Text as="span" color="gray.500" ml="2">
                    {property.address.city}
                  </Text>
                </Text>

                <Text>
                  Estado:
                  <Text as="span" color="gray.500" ml="2">
                    {property.address.state}
                  </Text>
                </Text>

                <Text>
                  Rua:
                  <Text as="span" color="gray.500" ml="2">
                    {`${property.address.street},
                     ${property.address.number}`}
                  </Text>
                </Text>

                <Text>
                  Complemento:
                  <Text as="span" color="gray.500" ml="2">
                    {property.address.complement}
                  </Text>
                </Text>

                <Text>
                  Bairro:
                  <Text as="span" color="gray.500" ml="2">
                    {property.address.neighborhood}
                  </Text>
                </Text>

                <Text>
                  CEP:
                  <Text as="span" color="gray.500" ml="2">
                    {property.formattedZipCode}
                  </Text>
                </Text>
              </SimpleGrid>

              <Divider borderColor="gray.300" />

              <SimpleGrid
                minChildWidth="50%"
                spacingX="8"
                spacingY="4"
                px="8"
                w="100%"
              >
                <Text>
                  Descrição:
                  <Text color="gray.500" ml="2">
                    {property.description || '-'}
                  </Text>
                </Text>

                <Text>
                  Detalhes da negociação:
                  <Text color="gray.500" ml="2">
                    {property.negotiationDetails || '-'}
                  </Text>
                </Text>
              </SimpleGrid>
            </VStack>

            <Divider my="6" borderColor="gray.300" />

            {property.propertyOwner && (
              <Flex justify="space-between" px="8" direction="column">
                <Text>Proprietário</Text>
                <Text fontSize="lg" fontWeight="bold" mt="1">
                  {property.propertyOwner?.name}
                </Text>
                <Flex align="center" justify="space-between" mt="1">
                  <Text fontSize="sm" color="gray.500">
                    {`Telefone: ${property.propertyOwner?.mainPhone}`}
                  </Text>

                  <Text fontSize="sm" color="gray.500">
                    {`E-mail: ${property.propertyOwner?.email}`}
                  </Text>

                  <ChakraLink
                    as={Link}
                    to={{
                      pathname: '/property-owners/details',
                      state: { propertyOwnerId: property.propertyOwner?.id },
                    }}
                  >
                    <Text color="blue.300">Detalhes</Text>
                  </ChakraLink>
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
