import {
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Heading,
  useToast,
  ButtonGroup,
  Checkbox,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import {
  IUserListItem,
  listUsersService,
} from '../../../../../services/Users/ListUsersService';

interface IAppointmentUserAssignModal {
  defaultValue?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (selectedUserId: string) => void;
}

export const AppointmentUserAssignModal = ({
  defaultValue,
  isOpen,
  onClose,
  onSubmit,
}: IAppointmentUserAssignModal): JSX.Element => {
  const toast = useToast();
  const [users, setUsers] = useState<IUserListItem[]>([]);
  const [selectedUserId, setSelectedUserId] = useState(defaultValue);

  const loadUsers = useCallback(
    async (name?: string) => {
      try {
        const { items } = await listUsersService({ name });

        setUsers(items);
      } catch (err) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar dados dos usuários, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const debounceLoadUsers = debounce(loadUsers, 1000);

  const handleAppointmentSubmit: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      if (selectedUserId) {
        onSubmit(selectedUserId);
      } else {
        toast({
          title: 'Selecione um corretor',
          description: 'Selecione um corretor para o atendimento',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }, [onSubmit, selectedUserId, toast]);

  const handleSelectUser = useCallback((userId: string) => {
    setSelectedUserId(userId);
  }, []);

  return (
    <Modal size="6xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Vincular corretor
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box width="100%">
            <Box>
              <Text>Filtrar por nome</Text>
              <Input
                size="sm"
                w="96"
                borderColor="gray.300"
                bg="gray.100"
                focusBorderColor="blue.300"
                variant="outline"
                onChange={(e) => debounceLoadUsers(e.target.value)}
              />
            </Box>
            <Table colorScheme="blue">
              <Thead>
                <Tr>
                  <Th px="6" w="8" />
                  <Th>Nome</Th>
                  <Th>Telefone</Th>
                  <Th>CRECI</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((user) => (
                  <Tr key={user.id}>
                    <Td px="6">
                      <Checkbox
                        colorScheme="blue"
                        isChecked={selectedUserId === user.id}
                        borderColor="gray.300"
                        onChange={() => handleSelectUser(user.id)}
                      />
                    </Td>
                    <Td>
                      <Text fontWeight="bold">{user.name}</Text>
                    </Td>
                    <Td>{user.phone}</Td>
                    <Td>{user.creci}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleAppointmentSubmit}>
              Vincular
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
