import api from '../api';
import {
  PropertyBase,
  PropertyAddress,
  PropertyImage,
} from '../../models/property';

interface IUpdatePropertyAddressProps {
  city: string;
  complement?: string;
  neighborhood?: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface IUpdatePropertyProps {
  propertyId: string;
  acceptExchange?: boolean;
  acceptFunding?: boolean;
  address?: IUpdatePropertyAddressProps;
  bathrooms?: number;
  buildings?: number;
  businessType?: 'rent' | 'sell' | 'sellAndRent';
  commercialStatus?:
    | 'default'
    | 'futureRelease'
    | 'lastUnits'
    | 'preRelease'
    | 'ready'
    | 'release'
    | 'resale';
  condominiumName?: string;
  condominiumPrice?: number;
  constructionYear?: number;
  deliveryDate?: string;
  description?: string;
  dormitories?: number;
  images?: PropertyImage[];
  isActive?: boolean;
  isExclusive?: boolean;
  isFurnished?: boolean;
  isPublished?: boolean;
  isShared?: boolean;
  keyHolder?: 'user' | 'concierge' | 'propertyOwner' | 'realEstate';
  monthlyUrbanTax?: number;
  negotiationDetails?: string;
  propertyCategory?:
    | 'commercialRoom'
    | 'default'
    | 'duplex'
    | 'duplexPenthouse'
    | 'farm'
    | 'farmhouse'
    | 'garageBox'
    | 'house'
    | 'penthouse'
    | 'studio'
    | 'smallFarm'
    | 'twoStoryHouse'
    | 'triplex'
    | 'woodhouse';
  propertyLevel?: 'high' | 'low' | 'medium' | 'regular';
  propertyOwnerId?: string;
  propertyReg?: string;
  ref?: string;
  regionStandardLevel?:
    | 'good'
    | 'medium'
    | 'privileged'
    | 'veryGood'
    | 'regular';
  rentPrice?: number;
  rooms?: number;
  sellPrice?: number;
  stage?: 'done' | 'underConstruction';
  suites?: number;
  title?: string;
  topography?: 'downhill' | 'plain' | 'uphill';
  totalArea?: string | number;
  usefulArea?: string | number;
  vacancies?: number;
  ventureId?: string;
  videoUrl?: string;
  virtualTourUrl?: string;
}

export interface IUpdatedProperty extends PropertyBase {
  address: PropertyAddress;
}

export const updatePropertiesService = async ({
  propertyId,
  acceptExchange,
  acceptFunding,
  address,
  bathrooms,
  buildings,
  businessType,
  commercialStatus,
  condominiumName,
  condominiumPrice,
  constructionYear,
  deliveryDate,
  description,
  dormitories,
  images,
  isActive,
  isExclusive,
  isFurnished,
  isPublished,
  isShared,
  keyHolder,
  monthlyUrbanTax,
  negotiationDetails,
  propertyCategory,
  propertyLevel,
  propertyOwnerId,
  propertyReg,
  ref,
  regionStandardLevel,
  rentPrice,
  rooms,
  sellPrice,
  stage,
  suites,
  title,
  topography,
  totalArea,
  usefulArea,
  vacancies,
  ventureId,
  videoUrl,
  virtualTourUrl,
}: IUpdatePropertyProps): Promise<IUpdatedProperty> => {
  const { data } = await api.patch<IUpdatedProperty>(
    `/properties/${propertyId}`,
    {
      acceptExchange,
      acceptFunding,
      address,
      bathrooms,
      buildings,
      businessType,
      commercialStatus,
      condominiumName,
      condominiumPrice,
      constructionYear,
      deliveryDate,
      description,
      dormitories,
      images,
      isActive,
      isExclusive,
      isFurnished,
      isPublished,
      isShared,
      keyHolder,
      monthlyUrbanTax,
      negotiationDetails,
      propertyCategory,
      propertyLevel,
      propertyOwnerId,
      propertyReg,
      ref,
      regionStandardLevel,
      rentPrice,
      rooms,
      sellPrice,
      stage,
      suites,
      title,
      topography,
      totalArea,
      usefulArea,
      vacancies,
      ventureId,
      videoUrl,
      virtualTourUrl,
    },
  );

  return data;
};
