import api from '../api';
import { TeamBase } from '../../models/team';

interface ICreateTeamProps {
  interest: string;
  leadsMax: number;
  maxValue: number;
  minValue: number;
  name: string;
  type: string;
}

export type INewTeam = TeamBase;

export const createTeamsService = async ({
  interest,
  leadsMax,
  maxValue,
  minValue,
  name,
  type,
}: ICreateTeamProps): Promise<INewTeam> => {
  const { data } = await api.post<INewTeam>('/teams', {
    interest,
    leadsMax,
    maxValue,
    minValue,
    name,
    type,
  });

  return data;
};
