import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
} from '@chakra-ui/react';
import fileDownload from 'js-file-download';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiDownload2Line } from 'react-icons/ri';
import { SelectOption } from '../../../../../../components/Form/ReactSelect';
import { getAppointmentsReportService } from '../../../../../../services/Appointments/GetAppointmentsReportService';
import { listVenturesService } from '../../../../../../services/Ventures/ListVenturesService';

const AppointmentReports = (props: BoxProps): JSX.Element => {
  const [filterSelectOptions, setFilterSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [filterBy, setFilterBy] = useState('userName');
  const [currentFilters, setCurrentFilters] = useState<{
    [key: string]: string | undefined;
  }>();

  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome do corretor',
        value: 'userName',
      },
      {
        label: 'Nome do lead',
        value: 'leadName',
      },
      {
        label: 'Empreendimento',
        value: 'ventureId',
      },
      {
        label: 'Status do atendimento',
        value: 'status',
      },
      {
        label: 'Status do lead',
        value: 'leadStatus',
      },
    ],
    [],
  );

  const filterInputType = useMemo(() => {
    switch (filterBy) {
      case 'leadStatus':
        return 'select';

      case 'status':
        return 'select';

      case 'ventureId':
        return 'select';

      default:
        return 'input';
    }
  }, [filterBy]);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const { handleSubmit, formState, reset } = useForm();

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items } = await listVenturesService();

      setFilterSelectOptions([
        {
          label: 'Todos',
          value: '',
        },
        ...items.map((venture) => ({
          label: venture.title,
          value: venture.id,
        })),
      ]);
    }

    switch (filterBy) {
      case 'leadStatus':
        setFilterSelectOptions([
          { value: '', label: 'Todos' },
          { value: 'active', label: 'Ativos' },
          { value: 'inactive', label: 'Inativos' },
        ]);
        break;

      case 'status':
        setFilterSelectOptions([
          { value: '', label: 'Todos' },
          { value: 'waiting', label: 'Aguardando atendimento' },
          { value: 'preService', label: 'Pré atendimento' },
          { value: 'contacted', label: 'Primeiro contato feito' },
          { value: 'informationsRequested', label: 'Informações requisitadas' },
          { value: 'expressedInterest', label: 'Interesse especificado' },
          { value: 'ongoing', label: 'Manter contato' },
          { value: 'scheduledVisit', label: 'Visita agendada' },
          { value: 'visited', label: 'Visita realizada' },
          { value: 'followUp', label: 'Follow up' },
          { value: 'proposal', label: 'Proposta enviada' },
          { value: 'negotiation', label: 'Em negociação' },
          { value: 'deal', label: 'Proposta assinada' },
          { value: 'inactive', label: 'Inativo' },
        ]);

        break;

      case 'ventureId':
        loadVentures();

        break;

      default:
        break;
    }
  }, [filterBy]);

  const handleDownloadAppointmentsReport = useCallback(async () => {
    const reportData = await getAppointmentsReportService({
      ...currentFilters,
      sort: 'createdAt',
      order: 'ASC',
    });

    const fileName = `${new Date().toISOString()}-jhsf-broker-appointments-report.xlsx`;

    fileDownload(reportData, fileName);

    reset({});
  }, [currentFilters, reset]);

  return (
    <Box
      w="100%"
      as="form"
      onSubmit={handleSubmit(handleDownloadAppointmentsReport)}
      {...props}
    >
      <Heading size="md" fontWeight="normal">
        Atendimentos
      </Heading>

      <Box px="8" mt="8" w="100%">
        <InputGroup size="lg">
          <InputLeftAddon p="0">
            <Select
              variant="outline"
              borderColor="gray.300"
              focusBorderColor="blue.300"
              size="lg"
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              {filterByOptions.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Select>
          </InputLeftAddon>

          {filterInputType === 'input' && (
            <Input
              borderColor="gray.300"
              borderLeftWidth={0}
              bg="gray.100"
              focusBorderColor="blue.300"
              variant="outline"
              onChange={(e) => handleFilter(e.target.value)}
            />
          )}

          {filterInputType === 'select' && (
            <Select
              borderColor="gray.300"
              borderLeftWidth={0}
              bg="gray.100"
              focusBorderColor="blue.300"
              variant="outline"
              onChange={(e) => handleFilter(e.target.value)}
            >
              {filterSelectOptions.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Select>
          )}
        </InputGroup>
      </Box>

      <Flex justify="flex-end" px="8" mt="8">
        <Button
          type="submit"
          size="md"
          colorScheme="green"
          leftIcon={<Icon as={RiDownload2Line} fontSize="20" />}
          isLoading={formState.isSubmitting}
        >
          Exportar
        </Button>
      </Flex>
    </Box>
  );
};

export default AppointmentReports;
