import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { ILeadTableItem, LeadTable } from './components/LeadTable';
import { listLeadsService } from '../../../../../services/Leads/ListLeadsService';
import { useAuth } from '../../../../../hooks/auth';

interface ILoadLeadsProps {
  name?: string;
  email?: string;
  phone?: string;
  page?: number;
  sort?: 'name' | 'email' | 'phone' | 'createdAt' | 'interest';
  order?: 'ASC' | 'DESC';
}

export const LeadList = (): JSX.Element => {
  const { user } = useAuth();
  const toast = useToast();

  const [leadsList, setLeadsList] = useState<ILeadTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<
    'name' | 'email' | 'phone' | 'createdAt' | 'interest'
  >('createdAt');
  const [totalPages, setTotalPages] = useState<number>();

  const loadLeads = useCallback(
    async ({ name, email, phone, page, sort, order }: ILoadLeadsProps) => {
      try {
        const { items, pages } = await listLeadsService({
          name: name || undefined,
          email: email || undefined,
          phone: phone || undefined,
          page,
          sort,
          order,
        });

        const leadsWithParsedDate = items.map((lead) => ({
          ...lead,
          parsedDate: maskShortDate(lead.createdAt),
        }));

        setLeadsList(leadsWithParsedDate);
        setTotalPages(pages);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados dos leads, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadLeads({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [currentFilters, currentOrdering, currentPage, loadLeads, sortBy]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'name' | 'email' | 'phone' | 'createdAt' | 'interest') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Leads
          </Heading>
          {user.isAdmin && (
            <LinkButton
              colorScheme="green"
              icon={<Icon as={RiAddLine} fontSize="16" />}
              to="/leads/register"
            >
              Cadastrar novo
            </LinkButton>
          )}
        </Flex>

        <LeadTable
          mt="4"
          currentOrder={currentOrdering}
          currentPage={currentPage}
          filterBy={filterBy}
          leads={leadsList}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
