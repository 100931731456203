import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import fileDownload from 'js-file-download';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RiDownload2Line } from 'react-icons/ri';
import * as Yup from 'yup';
import { DatePicker } from '../../../../../../components/Form/DatePicker';
import { getAppointmentsMonthStatusReportService } from '../../../../../../services/Appointments/GetAppointmentsMonthStatusReportService';

type MonthReportFormData = {
  endDate: Date;
  startDate: Date;
};

const reportFormSchema = Yup.object().shape({
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'Data inválida')
    .nullable()
    .required('Requerido')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  startDate: Yup.date()
    .nullable()
    .required('Requerido')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

const AppointmentMonthStatusReports = (props: BoxProps): JSX.Element => {
  const { handleSubmit, formState, reset, register, control } = useForm({
    resolver: yupResolver(reportFormSchema),
  });

  const { errors } = formState;

  const handleDownloadMonthStatusReport: SubmitHandler<MonthReportFormData> =
    useCallback(
      async ({ endDate, startDate }) => {
        const reportData = await getAppointmentsMonthStatusReportService({
          endDate,
          startDate,
        });

        const fileName = `${new Date().toISOString()}-jhsf-broker-appointments-month-report.xlsx`;

        fileDownload(reportData, fileName);

        reset({});
      },
      [reset],
    );

  return (
    <Box
      w="100%"
      as="form"
      onSubmit={handleSubmit(handleDownloadMonthStatusReport)}
      {...props}
    >
      <Heading size="md" fontWeight="normal">
        Atendimentos status mensal
      </Heading>

      <SimpleGrid
        alignItems="flex-end"
        minChildWidth="240px"
        spacing="8"
        px="8"
        mt="8"
        w="100%"
      >
        <DatePicker
          label="Data inicial"
          maxDate={new Date()}
          control={control}
          error={errors.startDate}
          {...register('startDate')}
        />

        <DatePicker
          label="Data final"
          maxDate={new Date()}
          control={control}
          error={errors.endDate}
          {...register('endDate')}
        />
      </SimpleGrid>

      <Flex justify="flex-end" px="8" mt="8">
        <Button
          type="submit"
          size="md"
          colorScheme="green"
          leftIcon={<Icon as={RiDownload2Line} fontSize="20" />}
          isLoading={formState.isSubmitting}
        >
          Exportar
        </Button>
      </Flex>
    </Box>
  );
};

export default AppointmentMonthStatusReports;
