import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';
import { UserRoleBase } from '../../models/userRole';

export interface IDetailedUser extends UserBase {
  teams: TeamBase[];
  userRoles: UserRoleBase[];
}

export const showUsersService = async (
  userId: string,
): Promise<IDetailedUser> => {
  const { data } = await api.get<IDetailedUser>(`/users/${userId}`);

  return data;
};
