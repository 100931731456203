import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';

interface IListUserProps {
  limit?: number;
  name?: string;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'name' | 'email' | 'creci' | 'phone' | 'createdAt';
}

export interface IUserListItem extends UserBase {
  teams: TeamBase[];
}

export interface IUserList {
  items: IUserListItem[];
  pages: number;
  total: number;
}

export const listUsersService = async (
  filters?: IListUserProps,
): Promise<IUserList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          limit: filters.limit,
          name: filters.name || undefined,
          order: filters.order,
          page: filters.page,
          sort: filters.sort,
        }
      : undefined;

  const { data } = await api.get<IUserList>('/users', { params });

  return data;
};
