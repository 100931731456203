import { useCallback, useMemo, useEffect, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  ButtonGroup,
  HStack,
  Text,
  Switch,
  Icon,
} from '@chakra-ui/react';
import { FiLogIn } from 'react-icons/fi';
import { Input } from '../../../../../../components/Form/MaskedInput';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePicker } from '../../../../../../components/Form/DatePicker';
import {
  maskArea,
  maskMoney,
  unmaskNumber,
} from '../../../../../../utils/formatters/handleMask';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../../components/Form/ReactSelect';

export type VentureFormData = {
  buildings?: number;
  businessType: 'rent' | 'sell' | 'sellAndRent';
  condominiumName: string;
  coveredGarages?: number;
  deliveryDate?: string;
  description?: string;
  developerReg?: string;
  floors?: number;
  floorUnits?: number;
  isActive: boolean;
  isPublished: boolean;
  maxDormitories?: number;
  maxPrice?: string | number;
  maxPrivativeArea?: string | number;
  maxSuites?: number;
  maxTotalArea?: string | number;
  maxUsefulArea?: string | number;
  maxVacancies?: number;
  minDormitories?: number;
  minPrice?: string | number;
  minPrivativeArea?: string | number;
  minSuites?: number;
  minTotalArea?: string | number;
  minUsefulArea?: string | number;
  minVacancies?: number;
  negotiationDetails?: string;
  previousRef?: string;
  ref: string;
  stage: 'done' | 'underConstruction';
  title: string;
  uncoveredGarages?: number;
  videoUrl?: string;
  virtualTourUrl?: string;
};
interface IVentureProps {
  onSubmit: (ventureData: VentureFormData) => void;
  venture?: VentureFormData;
}

const ventureRegisterFormSchema = Yup.object().shape({
  buildings: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  businessType: Yup.string().nullable().required('Requerido'),
  condominiumName: Yup.string().required('Requerido'),
  coveredGarages: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  deliveryDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  developerReg: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  floors: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  floorUnits: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Inválido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  maxDormitories: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  maxPrice: Yup.number()
    .typeError('Inválido')
    .positive('Inválido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  maxPrivativeArea: Yup.number()
    .typeError('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  maxSuites: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  maxTotalArea: Yup.number()
    .typeError('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  maxUsefulArea: Yup.number()
    .typeError('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  maxVacancies: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  minDormitories: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  minPrice: Yup.number()
    .typeError('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  minPrivativeArea: Yup.number()
    .typeError('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  minSuites: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  minTotalArea: Yup.number()
    .typeError('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  minUsefulArea: Yup.number()
    .typeError('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  minVacancies: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  negotiationDetails: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  previousRef: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ref: Yup.string().required('Requerido'),
  stage: Yup.string().nullable().required('Requerido'),
  title: Yup.string().required('Requerido'),
  uncoveredGarages: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  videoUrl: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  virtualTourUrl: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const Venture = ({ onSubmit, venture }: IVentureProps): JSX.Element => {
  const { goBack } = useHistory();
  const [isActive, setIsActive] = useState(true);
  const [isPublished, setIsPublished] = useState(true);

  const { register, handleSubmit, control, formState, reset } = useForm({
    resolver: yupResolver(ventureRegisterFormSchema),
    defaultValues: {
      ...venture,
      deliveryDate: venture?.deliveryDate && new Date(venture.deliveryDate),
    },
  });

  useEffect(() => {
    if (venture) {
      reset({
        ...venture,
        deliveryDate: venture.deliveryDate && new Date(venture.deliveryDate),
        maxPrice: venture.maxPrice && maskMoney(venture.maxPrice),
        maxPrivativeArea:
          venture.maxPrivativeArea && maskArea(venture.maxPrivativeArea),
        maxTotalArea: venture.maxTotalArea && maskArea(venture.maxTotalArea),
        maxUsefulArea: venture.maxUsefulArea && maskArea(venture.maxUsefulArea),
        minPrice: venture.minPrice && maskMoney(venture.minPrice),
        minPrivativeArea:
          venture.minPrivativeArea && maskArea(venture.minPrivativeArea),
        minTotalArea: venture.minTotalArea && maskArea(venture.minTotalArea),
        minUsefulArea: venture.minUsefulArea && maskArea(venture.minUsefulArea),
      });
      setIsActive(!!venture.isActive);
      setIsPublished(!!venture.isPublished);
    }
  }, [reset, venture]);

  const { errors } = formState;

  const stageSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Em construção', value: 'underConstruction' },
      { label: 'Pronto', value: 'done' },
    ],
    [],
  );

  const businessTypeSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Locação', value: 'rent' },
      { label: 'Venda', value: 'sell' },
      { label: 'Venda e locação', value: 'sellAndRent' },
    ],
    [],
  );

  const handleToggleIsActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked);
    },
    [],
  );

  const handleToggleIsPublished = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsPublished(event.target.checked);
    },
    [],
  );

  const handleNewVenture: SubmitHandler<VentureFormData> = useCallback(
    (ventureData) => {
      onSubmit({ ...ventureData, isActive, isPublished });
    },
    [isActive, onSubmit, isPublished],
  );

  return (
    <Box as="form" onSubmit={handleSubmit(handleNewVenture)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <Input label="Título" error={errors.title} {...register('title')} />

          <Input
            label="Nome do condomínio"
            error={errors.condominiumName}
            {...register('condominiumName')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <Input label="Código" error={errors.ref} {...register('ref')} />

            <Input
              label="Ref. anterior"
              error={errors.previousRef}
              {...register('previousRef')}
            />
          </HStack>

          <HStack spacing="4" align="flex-end">
            <Input
              label="Registro de incorp."
              error={errors.developerReg}
              {...register('developerReg')}
            />

            <ReactSelect
              name="businessType"
              control={control}
              label="Tipo de negócio"
              options={businessTypeSelectOptions}
              error={errors.businessType}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <ReactSelect
              name="stage"
              control={control}
              label="Fase"
              options={stageSelectOptions}
              error={errors.stage}
            />

            <DatePicker
              label="Data de entrega"
              control={control}
              minDate={new Date()}
              error={errors.deliveryDate}
              {...register('deliveryDate')}
            />
          </HStack>

          <HStack spacing="4" align="flex-end">
            <Input
              label="Nº de blocos/torres"
              type="number"
              error={errors.buildings}
              {...register('buildings')}
            />

            <Input
              label="Andares"
              type="number"
              error={errors.floors}
              {...register('floors')}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="340px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <Input
              label="Unidades por andar"
              type="number"
              error={errors.floorUnits}
              {...register('floorUnits')}
            />
            <Input
              label="Garagens cobertas"
              type="number"
              error={errors.coveredGarages}
              {...register('coveredGarages')}
            />
            <Input
              label="Garagens descob."
              type="number"
              error={errors.uncoveredGarages}
              {...register('uncoveredGarages')}
            />
          </HStack>

          <HStack align="flex-end">
            <Input
              label="Dorm. mín."
              type="number"
              error={errors.minDormitories}
              {...register('minDormitories')}
            />
            <Input
              label="Dorm. máx."
              type="number"
              error={errors.maxDormitories}
              {...register('maxDormitories')}
            />
            <Input
              label="Suítes mín."
              type="number"
              error={errors.minSuites}
              {...register('minSuites')}
            />
            <Input
              label="Suítes máx."
              type="number"
              error={errors.maxSuites}
              {...register('maxSuites')}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="340px" spacing="8" w="100%">
          <HStack align="flex-end">
            <Input
              label="Vagas mínimo"
              type="number"
              error={errors.minVacancies}
              {...register('minVacancies')}
            />
            <Input
              label="Vagas máximo"
              type="number"
              error={errors.maxVacancies}
              {...register('maxVacancies')}
            />
            <Input
              label="Área útil mín."
              mask="area"
              error={errors.minUsefulArea}
              {...register('minUsefulArea')}
            />
            <Input
              label="Área útil máx."
              mask="area"
              error={errors.maxUsefulArea}
              {...register('maxUsefulArea')}
            />
          </HStack>

          <HStack align="flex-end">
            <Input
              label="Área priv. mín."
              mask="area"
              error={errors.minPrivativeArea}
              {...register('minPrivativeArea')}
            />
            <Input
              label="Área priv. máx."
              mask="area"
              error={errors.maxPrivativeArea}
              {...register('maxPrivativeArea')}
            />
            <Input
              label="Área total mín."
              mask="area"
              error={errors.minTotalArea}
              {...register('minTotalArea')}
            />
            <Input
              label="Área total máx."
              mask="area"
              error={errors.maxTotalArea}
              {...register('maxTotalArea')}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <Input
              label="Preço mínimo"
              error={errors.minPrice}
              mask="money"
              {...register('minPrice')}
            />
            <Input
              label="Preço máximo"
              error={errors.maxPrice}
              mask="money"
              {...register('maxPrice')}
            />
          </HStack>

          <HStack spacing="4" align="flex-end">
            <Input
              label="Vídeo link"
              type="url"
              error={errors.videoUrl}
              {...register('videoUrl')}
            />

            <Input
              label="Tour virtual link"
              error={errors.virtualTourUrl}
              {...register('virtualTourUrl')}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <Input
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Descrição"
            error={errors.description}
            {...register('description')}
          />
          <Input
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Detalhes da negociação"
            error={errors.negotiationDetails}
            {...register('negotiationDetails')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="80px" spacing="8" w="100%">
          <Flex align="center" justify="flex-end">
            <Text mr="2">Ativo</Text>
            <Switch isChecked={isActive} onChange={handleToggleIsActive} />
          </Flex>

          <Flex align="center">
            <Switch
              isChecked={isPublished}
              onChange={handleToggleIsPublished}
            />
            <Text ml="2">Publicado</Text>
          </Flex>
        </SimpleGrid>
      </VStack>

      <Flex mt="12" justify="flex-end">
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={goBack}>
            Cancelar
          </Button>
          <Button
            type="submit"
            colorScheme="green"
            isLoading={formState.isSubmitting}
          >
            Preencher endereço
            <Icon as={FiLogIn} fontSize="20" ml="2" />
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
