import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Flex,
  Box,
  VStack,
  Button,
  Icon,
} from '@chakra-ui/react';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import { useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/toast';
import { DeleteConfirmationModal } from '../../../../../../../components/DeleteConfirmationModal';
import venturePlaceholder from '../../../../../../../assets/venture_placeholder.png';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { IPropertyListItem } from '../../../../../../../services/Properties/ListPropertiesService';
import { appointmentOpportunitiesUnassignService } from '../../../../../../../services/Appointments/AppointmentOpportunitiesUnassignService';

enum BusinessType {
  sell = 'Venda',
  rent = 'Locação',
  sellAndRent = 'Venda e Locação',
}

export interface IPropertyTableItem extends IPropertyListItem {
  coverImageUrl?: string;
  formattedDeliveryDate?: string;
  formattedRentPrice?: string;
  formattedSellPrice?: string;
  formattedTotalArea?: string;
  formattedUsefulArea?: string;
}

interface IPropertyTableProps {
  property: IPropertyTableItem;
  appointmentId: string;
  onPropertyUnassign: (propertyId: string) => void;
}

export const AppointmentPropertyTable = ({
  property,
  appointmentId,
  onPropertyUnassign,
}: IPropertyTableProps): JSX.Element => {
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const handlePropertyUnassign = useCallback(async () => {
    try {
      await appointmentOpportunitiesUnassignService({
        appointmentId,
        propertyId: property.id,
      });

      onPropertyUnassign(property.id);
      handleToggleDeleteConfirmationModal();

      toast({
        title: 'Desvinculado com sucesso',
        description: 'O imóvel foi desvinculado corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    } catch (err) {
      toast({
        title: 'Erro ao desvincular',
        description: 'Ocorreu um erro ao desvincular o imóvel, tente novamente',
        status: 'error',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    }
  }, [
    appointmentId,
    handleToggleDeleteConfirmationModal,
    onPropertyUnassign,
    property.id,
    toast,
  ]);

  return (
    <Box>
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handlePropertyUnassign}
      />

      <Table colorScheme="blue">
        <Thead>
          <Tr>
            <Th w="55%" />
            <Th w="25%" />
            <Th w="10%" />
            <Th w="10%" />
          </Tr>
        </Thead>
        <Tbody>
          <Tr key={property.id}>
            <Td>
              <Flex>
                <Box
                  bgImage={property.coverImageUrl || venturePlaceholder}
                  bgRepeat="no-repeat"
                  bgSize="cover"
                  borderRadius="8"
                  h="40"
                  minW="240px"
                />

                <Flex direction="column" justify="space-between" ml="4">
                  <Box>
                    <Text fontSize="xl" fontWeight="bold">
                      {property.title}
                    </Text>
                    <Text color="gray.500" fontSize="sm">
                      {property.condominiumName}
                    </Text>
                  </Box>

                  <Box>
                    <Text fontSize="sm" color="gray.500" mt="2">
                      {`${property.address.street},  ${property.address.number}`}
                    </Text>
                    <Text fontSize="sm" color="gray.500" mb="4">
                      {`${property.address.neighborhood}, ${property.address.city} - ${property.address.state}`}
                    </Text>
                    <Text as="strong">
                      {property.ref && `${property.ref} / `}
                      <Text as="span" fontWeight="normal">
                        {property.propertyReg}
                      </Text>
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Td>

            <Td>
              <Flex direction="column" justify="space-between" h="40">
                <Box>
                  <Text fontSize="sm">A partir de:</Text>
                  <Text as="strong" color="blue.500">
                    {property.formattedSellPrice}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="sm">Aluguel partir de:</Text>
                  <Text as="strong" color="blue.500">
                    {property.formattedRentPrice}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="sm">Área total:</Text>
                  <Text as="strong">{property.formattedTotalArea}</Text>
                </Box>
                <Box>
                  <Text fontSize="sm">Área útil:</Text>
                  <Text as="strong">{property.formattedUsefulArea}</Text>
                </Box>
                <Box>
                  <Text fontSize="sm" color="blue.500">
                    {property.formattedDeliveryDate}
                  </Text>
                </Box>
              </Flex>
            </Td>

            <Td>
              <Flex direction="column" justify="space-between" h="40">
                <Box>
                  <Text fontSize="sm">
                    {'Disponível para: '}
                    <Text as="strong" color="blue.500">
                      {BusinessType[property.businessType]}
                    </Text>
                  </Text>
                </Box>

                <VStack flex="1" justify="center" align="left" spacing="1">
                  {property.bathrooms && (
                    <Flex align="center">
                      <Box
                        textAlign="center"
                        bgColor="gray.200"
                        borderRadius="8"
                        minW="10"
                        py="0.5"
                      >
                        <Text as="strong" fontSize="sm">
                          {`${property.bathrooms} `}
                        </Text>
                      </Box>
                      <Text as="span" fontWeight="normal" ml="2" fontSize="sm">
                        Banheiros
                      </Text>
                    </Flex>
                  )}

                  {property.rooms && (
                    <Flex align="center">
                      <Box
                        textAlign="center"
                        bgColor="gray.200"
                        borderRadius="8"
                        minW="10"
                        py="0.5"
                      >
                        <Text as="strong" fontSize="sm">
                          {`${property.rooms} `}
                        </Text>
                      </Box>
                      <Text as="span" fontWeight="normal" ml="2" fontSize="sm">
                        Salas
                      </Text>
                    </Flex>
                  )}

                  {property.dormitories && (
                    <Flex align="center">
                      <Box
                        textAlign="center"
                        bgColor="gray.200"
                        borderRadius="8"
                        minW="10"
                        py="0.5"
                      >
                        <Text as="strong" fontSize="sm">
                          {`${property.dormitories} `}
                        </Text>
                      </Box>
                      <Text as="span" fontWeight="normal" ml="2" fontSize="sm">
                        Dormitórios
                      </Text>
                    </Flex>
                  )}

                  {property.suites && (
                    <Flex align="center">
                      <Box
                        textAlign="center"
                        bgColor="gray.200"
                        borderRadius="8"
                        minW="10"
                        py="0.5"
                      >
                        <Text as="strong" fontSize="sm">
                          {`${property.suites} `}
                        </Text>
                      </Box>
                      <Text as="span" fontWeight="normal" ml="2" fontSize="sm">
                        Suítes
                      </Text>
                    </Flex>
                  )}
                </VStack>
              </Flex>
            </Td>

            <Td>
              <Flex direction="column">
                <LinkButton
                  w="100%"
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/properties/details',
                    state: {
                      propertyId: property.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>

                <Button
                  mt="2"
                  size="sm"
                  fontSize="sm"
                  colorScheme="red"
                  onClick={handleToggleDeleteConfirmationModal}
                  leftIcon={<Icon as={RiDeleteBinLine} fontSize="16" />}
                >
                  Desvincular
                </Button>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};
