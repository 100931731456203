import { Flex, Text } from '@chakra-ui/react';
import { ReactNode, useCallback } from 'react';
import Dropzone from 'react-dropzone';

interface IImageDropzoneProps {
  onChange: (file: File) => void;
}

export const ImageDropzone = ({
  onChange,
}: IImageDropzoneProps): JSX.Element => {
  const renderDragMessage = useCallback(
    (isDragActive: boolean, isDragReject: boolean): ReactNode => {
      if (!isDragActive) {
        return (
          <Text color="gray.500">
            Clique para selecionar ou arraste e solte uma imagem aqui
          </Text>
        );
      }

      if (isDragReject) {
        return <Text color="red.500">Arquivo não suportado</Text>;
      }

      return <Text color="green.500">Solte o arquivo aqui</Text>;
    },
    [],
  );

  return (
    <Dropzone accept="image/*" onDropAccepted={(files) => onChange(files[0])}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
        let currentBorderColor = 'gray.300';
        let currentBgColor = 'gray.100';

        if (isDragActive) {
          currentBorderColor = 'green.300';
          currentBgColor = 'green.50';
        }

        if (isDragReject) {
          currentBorderColor = 'red.300';
          currentBgColor = 'red.50';
        }

        return (
          <Flex
            p="8"
            align="center"
            justify="center"
            alignSelf="stretch"
            bg={currentBgColor}
            borderColor={currentBorderColor}
            borderWidth={1}
            borderRadius={8}
            cursor="pointer"
            className="dropzone"
            {...getRootProps()}
          >
            {renderDragMessage(isDragActive, isDragReject)}
            <input {...getInputProps()} />
          </Flex>
        );
      }}
    </Dropzone>
  );
};
