import api from '../api';
import { AppointmentBase, AppointmentHistory } from '../../models/appointment';

interface ICreateAppointmentProps {
  leadId: string;
  userId: string;
}

export interface INewAppointment extends AppointmentBase {
  histories: AppointmentHistory[];
}

export const createAppointmentsService = async ({
  leadId,
  userId,
}: ICreateAppointmentProps): Promise<INewAppointment> => {
  const { data } = await api.post<INewAppointment>('/appointments', {
    leadId,
    userId,
  });

  return data;
};
