import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';

interface ITeamUsersUnassignProps {
  teamId: string;
  usersId: Array<string>;
}

export interface ITeamUsersUnassign extends TeamBase {
  users: UserBase[];
}

export const teamUsersUnassignService = async ({
  teamId,
  usersId,
}: ITeamUsersUnassignProps): Promise<ITeamUsersUnassign> => {
  const { data } = await api.patch<ITeamUsersUnassign>(
    `/teams/${teamId}/users-unassign`,
    { usersId },
  );

  return data;
};
