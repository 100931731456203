import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { useAuth } from '../../../../../hooks/auth';
import {
  maskMoney,
  unmaskNumber,
} from '../../../../../utils/formatters/handleMask';
import { showTeamsService } from '../../../../../services/Teams/ShowTeamsService';
import { updateTeamsService } from '../../../../../services/Teams/UpdateTeamsService';

type UpdateTeamFormData = {
  interest: string;
  leadsMax: number;
  maxValue: number | string;
  minValue: number | string;
  name: string;
  type: string;
};

interface ILocationState {
  teamId?: string;
}

const teamRegisterFormSchema = Yup.object().shape({
  interest: Yup.string().required('Requerido'),
  leadsMax: Yup.string().required('Requerido'),
  minValue: Yup.number()
    .required('Requerido')
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  maxValue: Yup.number()
    .required('Requerido')
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  name: Yup.string().required('Requerido'),
  type: Yup.string().required('Requerido'),
});

export const TeamUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { signOut } = useAuth();
  const { state } = useLocation<ILocationState>();

  const [updatingTeam, setUpdatingTeam] = useState<UpdateTeamFormData>();

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(teamRegisterFormSchema),
    defaultValues: updatingTeam,
  });

  const { errors } = formState;
  const { teamId } = state;

  useEffect(() => {
    async function loadTeam(showTeamId: string): Promise<void> {
      try {
        const teamDetails = await showTeamsService(showTeamId);

        const parsedTeam = {
          ...teamDetails,
          minValue: maskMoney(teamDetails.minValue),
          maxValue: maskMoney(teamDetails.maxValue),
        };

        setUpdatingTeam(parsedTeam);
        reset(parsedTeam);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do time, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (teamId) {
      loadTeam(teamId);
    }
  }, [teamId, reset, toast, signOut]);

  const handleUpdateTeam: SubmitHandler<UpdateTeamFormData> = useCallback(
    async ({ interest, leadsMax, maxValue, minValue, name, type }) => {
      if (teamId) {
        try {
          await updateTeamsService({
            teamId,
            interest,
            leadsMax,
            maxValue: Number(maxValue),
            minValue: Number(minValue),
            name,
            type,
          });

          toast({
            title: 'Editado com sucesso',
            description: 'O time foi editado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description: 'Ocorreu um erro ao editar time, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [goBack, teamId, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateTeam)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar time
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input label="Nome" error={errors.name} {...register('name')} />
            <Input
              label="Tipo de time"
              error={errors.type}
              {...register('type')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="Tipo de interesse"
              error={errors.interest}
              {...register('interest')}
            />
            <Input
              label="Máximo de leads por corretor"
              error={errors.leadsMax}
              {...register('leadsMax')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="Valor mínimo dos empreendimentos"
              mask="money"
              error={errors.minValue}
              {...register('minValue')}
            />
            <Input
              label="Valor máximo dos empreendimentos"
              mask="money"
              error={errors.maxValue}
              {...register('maxValue')}
            />
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
