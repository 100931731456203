import { Box, BoxProps, Icon, Text } from '@chakra-ui/react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import { RiPencilLine } from 'react-icons/ri';
import { useCallback, useMemo } from 'react';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { ILeadListItem } from '../../../../../../../services/Leads/ListLeadsService';

enum Interest {
  sell = 'Venda',
  rent = 'Locação',
  sellAndRent = 'Venda e locação',
}

export interface ILeadTableItem extends ILeadListItem {
  parsedDate?: string;
}

interface ILeadTableProps extends BoxProps {
  currentOrder?: 'ASC' | 'DESC';
  currentPage?: number;
  filterBy?: string;
  leads: ILeadTableItem[];
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (
    sort: 'name' | 'email' | 'phone' | 'createdAt' | 'interest',
  ) => void;
  sortBy?: string;
  totalPages?: number;
}

export const LeadTable = ({
  currentOrder,
  currentPage,
  filterBy,
  leads,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  sortBy,
  totalPages,
  ...rest
}: ILeadTableProps): JSX.Element => {
  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(sort as 'name' | 'email' | 'phone' | 'createdAt' | 'interest');
      }
    },
    [onSort],
  );

  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'Telefone',
        value: 'phone',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Tipo de interesse',
        value: 'interest',
      },
      {
        label: 'Telefone',
        value: 'phone',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'Data de cadastro',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        currentOrder={currentOrder}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={handleSort}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Cliente</Th>
            <Th>Interesse</Th>
            <Th>Contatos</Th>
            <Th>Cadastrado em</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {leads.map((lead) => (
            <Tr key={lead.id}>
              <Td>
                <Box>
                  <Text fontWeight="bold">{lead.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {lead.nickname}
                  </Text>
                </Box>
              </Td>

              <Td>{Interest[lead.interest]}</Td>

              <Td>
                <Box>
                  <Text>{lead.phone}</Text>
                  <Text mt="2">{lead.email}</Text>
                </Box>
              </Td>

              <Td>{lead.parsedDate}</Td>

              <Td>
                <LinkButton
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/leads/details',
                    state: {
                      leadId: lead.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
