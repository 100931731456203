import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Text,
  SimpleGrid,
  VStack,
  Divider,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import {
  RiDeleteBinLine,
  RiEditLine,
  RiPencilLine,
  RiUserAddLine,
  RiUserUnfollowLine,
} from 'react-icons/ri';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { useAuth } from '../../../../../hooks/auth';
import { LinkButton } from '../../../../../components/LinkButton';
import {
  maskDate,
  maskMoney,
} from '../../../../../utils/formatters/handleMask';
import { UsersAssignModal } from './components/UsersAssignModal';
import {
  IDetailedTeam,
  showTeamsService,
} from '../../../../../services/Teams/ShowTeamsService';
import { teamUsersUnassignService } from '../../../../../services/Teams/TeamUsersUnassignService';
import { deleteTeamsService } from '../../../../../services/Teams/DeleteTeamsService';
import { UserBase } from '../../../../../models/user';

interface ITeam extends IDetailedTeam {
  parsedDate: string;
  formattedMinValue: string;
  formattedMaxValue: string;
}

interface IHandleSelectUserProps {
  userId: string;
  checked: boolean;
  index: number;
}

interface ILocationState {
  teamId?: string;
}

export const TeamDetails = (): JSX.Element => {
  const toast = useToast();
  const { signOut } = useAuth();
  const { push } = useHistory();
  const {
    state: { teamId },
  } = useLocation<ILocationState>();

  const [team, setTeam] = useState<ITeam>();
  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);
  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const allChecked = checkedItems.every(Boolean) && !!team?.users.length;
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [isUsersAssignModalVisible, setIsUsersAssignModalVisible] =
    useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadTeam(showTeamId: string): Promise<void> {
      try {
        const teamDetails = await showTeamsService(showTeamId);

        const parsedTeam = {
          ...teamDetails,
          parsedDate: maskDate(teamDetails.createdAt),
          formattedMaxValue: maskMoney(teamDetails.maxValue),
          formattedMinValue: maskMoney(teamDetails.minValue),
        };

        setTeam(parsedTeam);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do time, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (teamId) {
      loadTeam(teamId);
    }
  }, [teamId, toast]);

  useEffect(() => {
    setCheckedItems(
      Array.from({ length: team?.users.length || 0 }, () => false),
    );
    setSelectedUsersId([]);
  }, [team?.users.length]);

  const handleToggleSelectAllUsers = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setCheckedItems((prevState) => prevState.fill(event.target.checked));

      if (!event.target.checked) {
        setSelectedUsersId([]);
      } else if (team) {
        setSelectedUsersId(team.users.map((user) => user.id));
      }
    },
    [team],
  );

  const handleSelectUser = useCallback(
    ({ userId, checked, index }: IHandleSelectUserProps) => {
      setCheckedItems((prevState) =>
        prevState.map((check, checkIndex) =>
          checkIndex === index ? !check : check,
        ),
      );

      if (!checked) {
        setSelectedUsersId((prevState) =>
          prevState.filter((selectedUserId) => selectedUserId !== userId),
        );
      } else {
        setSelectedUsersId((prevState) => [...prevState, userId]);
      }
    },
    [],
  );

  const handleToggleUsersAssignModal = useCallback(() => {
    setIsUsersAssignModalVisible((prevState) => !prevState);
  }, []);

  const handleUsersAssign = useCallback(
    (users: UserBase[]) => {
      setTeam((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            users,
          };
        }

        return prevState;
      });

      handleToggleUsersAssignModal();
    },
    [handleToggleUsersAssignModal],
  );

  const handleUserUnassign = useCallback(async () => {
    if (selectedUsersId.length) {
      if (teamId) {
        try {
          const updatedTeam = await teamUsersUnassignService({
            teamId,
            usersId: selectedUsersId,
          });

          const parsedTeam = {
            ...updatedTeam,
            parsedDate: maskDate(updatedTeam.createdAt),
            formattedMaxValue: maskMoney(updatedTeam.maxValue),
            formattedMinValue: maskMoney(updatedTeam.minValue),
          };

          setTeam(parsedTeam);

          toast({
            title: 'Desvinculado com sucesso',
            description: 'O usuário foi desvinculado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao desvincular',
              description:
                'Ocorreu um erro ao desvincular o usuário, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    }
  }, [selectedUsersId, teamId, toast]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteTeam = useCallback(async () => {
    if (teamId) {
      try {
        await deleteTeamsService(teamId);

        toast({
          title: 'Time excluído com sucesso',
          description: 'O time foi excluído corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/teams');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          signOut();
        }

        toast({
          title: 'Falha ao excluir',
          description: 'Ocorreu um erro ao excluir o time, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [teamId, toast, push, signOut]);

  return (
    <DefaultLayout>
      {teamId && (
        <UsersAssignModal
          teamId={teamId}
          isOpen={isUsersAssignModalVisible}
          onClose={handleToggleUsersAssignModal}
          onSubmit={handleUsersAssign}
        />
      )}

      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteTeam}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do time
          </Heading>

          <ButtonGroup>
            <LinkButton
              colorScheme="yellow"
              color="white"
              icon={<Icon as={RiEditLine} fontSize="16" />}
              to={{
                pathname: '/teams/update',
                state: {
                  teamId,
                },
              }}
            >
              Editar
            </LinkButton>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button>
          </ButtonGroup>
        </Flex>

        {team && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold">
                {team.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {`${team.interest} - JHSF`}
              </Text>
            </Flex>

            <VStack mt="12">
              <SimpleGrid
                minChildWidth="240px"
                spacing="8"
                w="100%"
                align="center"
              >
                <Text>
                  Tipo:
                  <Text as="span" color="gray.500" ml="2">
                    {team.type}
                  </Text>
                </Text>
                <Text>
                  Interesse:
                  <Text as="span" color="gray.500" ml="2">
                    {team.interest}
                  </Text>
                </Text>
                <Text>
                  Máximo de leads:
                  <Text as="span" color="gray.500" ml="2">
                    {team.leadsMax}
                  </Text>
                </Text>
              </SimpleGrid>
              <SimpleGrid
                minChildWidth="240px"
                spacing="8"
                px="8"
                pt="2"
                w="100%"
                align="center"
              >
                <Text>
                  Valor mínimo do empreendimento:
                  <Text as="span" color="gray.500" ml="2">
                    {team.formattedMinValue}
                  </Text>
                </Text>
                <Text>
                  Valor máximo do empreendimento:
                  <Text as="span" color="gray.500" ml="2">
                    {team.formattedMaxValue}
                  </Text>
                </Text>
                <Text>
                  Data de cadastro:
                  <Text as="span" color="gray.500" ml="2">
                    {team.parsedDate}
                  </Text>
                </Text>
              </SimpleGrid>
            </VStack>

            <Divider my="6" borderColor="gray.300" />

            <Flex mb="8" justify="space-between" align="center">
              <Heading size="md" fontWeight="normal">
                Corretores vinculados
              </Heading>

              <ButtonGroup>
                <Button
                  size="sm"
                  fontSize="sm"
                  colorScheme="red"
                  onClick={handleUserUnassign}
                  leftIcon={<Icon as={RiUserUnfollowLine} fontSize="20" />}
                >
                  Desvincular corretores
                </Button>
                <Button
                  size="sm"
                  fontSize="sm"
                  colorScheme="green"
                  onClick={handleToggleUsersAssignModal}
                  leftIcon={<Icon as={RiUserAddLine} fontSize="20" />}
                >
                  Vincular corretores
                </Button>
              </ButtonGroup>
            </Flex>

            <Table colorScheme="blue">
              <Thead>
                <Tr>
                  <Th px="6" w="8">
                    <Checkbox
                      colorScheme="blue"
                      borderColor="gray.300"
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={handleToggleSelectAllUsers}
                    />
                  </Th>
                  <Th>Nome</Th>
                  <Th>Email</Th>
                  <Th>Telefone</Th>
                  <Th w="8" />
                </Tr>
              </Thead>
              <Tbody>
                {team.users.map((user, index) => (
                  <Tr key={user.id}>
                    <Td px="6">
                      <Checkbox
                        colorScheme="blue"
                        borderColor="gray.300"
                        isChecked={checkedItems[index]}
                        onChange={(event) =>
                          handleSelectUser({
                            userId: user.id,
                            checked: event.target.checked,
                            index,
                          })
                        }
                      />
                    </Td>
                    <Td>
                      <Text fontWeight="bold">{user.name}</Text>
                    </Td>
                    <Td>{user.email}</Td>
                    <Td>{user.phone}</Td>
                    <Td>
                      <LinkButton
                        icon={<Icon as={RiPencilLine} fontSize="16" />}
                        to={{
                          pathname: '/users/details',
                          state: {
                            userId: user.id,
                          },
                        }}
                      >
                        Detalhes
                      </LinkButton>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
