import { useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Text,
  SimpleGrid,
  Divider,
  Avatar,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import {
  RiEditLine,
  RiPencilLine,
  RiUserAddLine,
  RiUserUnfollowLine,
} from 'react-icons/ri';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { TeamsAssignModal } from './components/TeamsAssignModal';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import { TeamBase } from '../../../../../models/team';
import { userTeamsUnassignService } from '../../../../../services/Users/UserTeamsUnassignService';

interface IUser extends IDetailedUser {
  parsedDate: string;
}

interface IHandleSelectTeamProps {
  teamId: string;
  checked: boolean;
  index: number;
}

interface ILocationState {
  userId?: string;
}

export const UserDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { userId },
  } = useLocation<ILocationState>();
  // const { push } = useHistory();

  const [user, setUser] = useState<IUser>();
  const [selectedTeamsId, setSelectedTeamsId] = useState<string[]>([]);
  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const allChecked = checkedItems.every(Boolean) && !!user?.teams.length;
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [isTeamsAssignModalVisible, setIsTeamsAssignModalVisible] =
    useState(false);
  // const [
  //   isDeleteConfirmationModalVisible,
  //   setIsDeleteConfirmationModalVisible,
  // ] = useState(false);

  useEffect(() => {
    async function loadUser(showUserId: string): Promise<void> {
      try {
        const userDetails = await showUsersService(showUserId);

        const parsedUser = {
          ...userDetails,
          parsedDate: maskDate(userDetails.createdAt),
        };

        setUser(parsedUser);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os detalhes do usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (userId) {
      loadUser(userId);
    }
  }, [userId, toast]);

  useEffect(() => {
    setCheckedItems(
      Array.from({ length: user?.teams.length || 0 }, () => false),
    );
    setSelectedTeamsId([]);
  }, [user?.teams.length]);

  const handleToggleSelectAllTeams = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setCheckedItems((prevState) => prevState.fill(event.target.checked));

      if (!event.target.checked) {
        setSelectedTeamsId([]);
      } else if (user) {
        setSelectedTeamsId(user.teams.map((team) => team.id));
      }
    },
    [user],
  );

  const handleSelectTeam = useCallback(
    ({ teamId, checked, index }: IHandleSelectTeamProps) => {
      setCheckedItems((prevState) =>
        prevState.map((check, checkIndex) =>
          checkIndex === index ? !check : check,
        ),
      );

      if (!checked) {
        setSelectedTeamsId((prevState) =>
          prevState.filter((selectedTeamId) => selectedTeamId !== teamId),
        );
      } else {
        setSelectedTeamsId((prevState) => [...prevState, teamId]);
      }
    },
    [],
  );

  const handleToggleTeamsAssignModal = useCallback(() => {
    setIsTeamsAssignModalVisible((prevState) => !prevState);
  }, []);

  const handleTeamsAssign = useCallback(
    (teams: TeamBase[]) => {
      setUser((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            teams,
          };
        }

        return prevState;
      });

      handleToggleTeamsAssignModal();
    },
    [handleToggleTeamsAssignModal],
  );

  const handleTeamUnassign = useCallback(async () => {
    if (selectedTeamsId.length) {
      if (userId) {
        try {
          const updatedUser = await userTeamsUnassignService({
            userId,
            teamsId: selectedTeamsId,
          });

          const parsedUser = {
            ...updatedUser,
            parsedDate: maskDate(updatedUser.createdAt),
          };

          setUser(parsedUser);

          toast({
            title: 'Time desvinculado',
            description: 'O time foi desvinculado com sucesso',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao desvincular',
              description:
                'Ocorreu um erro ao desvincular time, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    }
  }, [userId, selectedTeamsId, toast]);

  // const handleToggleDeleteConfirmationModal = useCallback(() => {
  //   setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  // }, []);

  // const handleDeleteUser = useCallback(async () => {
  //   if (userId) {
  //     try {
  //       await deleteUsersService(userId);

  //       toast({
  //         title: 'Excluído com sucesso',
  //         description: 'O usuário foi excluído corretamente',
  //         status: 'success',
  //         duration: 3000,
  //         isClosable: true,
  //         variant: 'subtle',
  //         position: 'top-right',
  //       });

  //       push('/users');
  //     } catch (err) {
  //       toast({
  //         title: 'Falha ao excluir',
  //         description: 'Ocorreu um erro ao excluir o usuário, tente novamente',
  //         status: 'error',
  //         duration: 3000,
  //         isClosable: true,
  //         variant: 'subtle',
  //         position: 'top-right',
  //       });
  //     }
  //   }
  // }, [userId, push, toast]);

  return (
    <DefaultLayout>
      {userId && (
        <TeamsAssignModal
          userId={userId}
          isOpen={isTeamsAssignModalVisible}
          onClose={handleToggleTeamsAssignModal}
          onSubmit={handleTeamsAssign}
        />
      )}

      {/* <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteUser}
      /> */}

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do usuário
          </Heading>

          <ButtonGroup>
            <LinkButton
              colorScheme="yellow"
              color="white"
              icon={<Icon as={RiEditLine} fontSize="16" />}
              to={{
                pathname: '/users/update',
                state: {
                  userId,
                },
              }}
            >
              Editar
            </LinkButton>

            {/* <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button> */}
          </ButtonGroup>
        </Flex>

        {user && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Avatar size="2xl" name={user.name} src={user.avatarUrl} />
              <Flex>
                {user.userRoles &&
                  user.userRoles.map((role, index) => (
                    <Text
                      fontSize="lg"
                      display="row"
                      mt="1"
                      mr="1"
                      color="gray.500"
                    >
                      {` ${role.name} `}
                      {index !== user.userRoles.length - 1 && '-'}
                    </Text>
                  ))}
              </Flex>
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {user.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {user.bio}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="12"
              w="100%"
              align="center"
            >
              <Text>
                Telefone:
                <Text as="span" color="gray.500" ml="2">
                  {user.phone}
                </Text>
              </Text>
              <Text>
                E-mail:
                <Text as="span" color="gray.500" ml="2">
                  {user.email}
                </Text>
              </Text>
              <Text>
                Data de cadastro:
                <Text as="span" color="gray.500" ml="2">
                  {user.parsedDate}
                </Text>
              </Text>
            </SimpleGrid>

            <Divider my="6" borderColor="gray.300" />

            <Flex mb="8" justify="space-between" align="center">
              <Heading size="md" fontWeight="normal">
                Times vinculados
              </Heading>

              <ButtonGroup>
                <Button
                  size="sm"
                  fontSize="sm"
                  colorScheme="red"
                  onClick={handleTeamUnassign}
                  leftIcon={<Icon as={RiUserUnfollowLine} fontSize="20" />}
                >
                  Desvincular time
                </Button>

                <Button
                  size="sm"
                  fontSize="sm"
                  colorScheme="green"
                  onClick={handleToggleTeamsAssignModal}
                  leftIcon={<Icon as={RiUserAddLine} fontSize="20" />}
                >
                  Vincular time
                </Button>
              </ButtonGroup>
            </Flex>

            <Table colorScheme="blue">
              <Thead>
                <Tr>
                  <Th px="6" w="8">
                    <Checkbox
                      colorScheme="blue"
                      borderColor="gray.300"
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={handleToggleSelectAllTeams}
                    />
                  </Th>
                  <Th>Nome</Th>
                  <Th>Email</Th>
                  <Th>Telefone</Th>
                  <Th w="8" />
                </Tr>
              </Thead>
              <Tbody>
                {user.teams.map((team, index) => (
                  <Tr key={team.id}>
                    <Td px="6">
                      <Checkbox
                        colorScheme="blue"
                        borderColor="gray.300"
                        isChecked={checkedItems[index]}
                        onChange={(event) =>
                          handleSelectTeam({
                            teamId: team.id,
                            checked: event.target.checked,
                            index,
                          })
                        }
                      />
                    </Td>
                    <Td>
                      <Text fontWeight="bold">{team.name}</Text>
                    </Td>
                    <Td>{team.type}</Td>
                    <Td>{team.interest}</Td>
                    <Td>
                      <LinkButton
                        icon={<Icon as={RiPencilLine} fontSize="16" />}
                        to={{
                          pathname: '/teams/details',
                          state: {
                            teamId: team.id,
                          },
                        }}
                      >
                        Detalhes
                      </LinkButton>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
