import api from '../api';
import { MediaBase } from '../../models/media';

export type IDetailedMedia = MediaBase;

export const showMediasService = async (
  mediaId: string,
): Promise<IDetailedMedia> => {
  const { data } = await api.get<IDetailedMedia>(`/medias/${mediaId}`);

  return data;
};
