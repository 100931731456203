import { Stack } from '@chakra-ui/react';
import {
  RiBuilding2Line,
  RiBuilding4Line,
  RiClipboardLine,
  RiContactsLine,
  RiDashboardLine,
  RiDraftLine,
  RiFilmLine,
  RiHomeWifiLine,
  RiSuitcaseLine,
  RiTeamLine,
} from 'react-icons/ri';
import { useAuth } from '../../hooks/auth';
import { NavLink } from './NavLink';
import { NavSection } from './NavSection';

export const SidebarNav = (): JSX.Element => {
  const { user } = useAuth();

  // const handleAthleteReportExport = useCallback(async () => {
  //   const { data } = await api.get('/run-the-village/reports', {
  //     responseType: 'blob',
  //   });

  //   const now = new Date();

  // eslint-disable-next-line max-len
  //   const fileName = `${now.getDate()}-${now.getMonth()}-${now.getFullYear()}-${now.getHours()}-${now.getMinutes()}_(${
  //     now.getSeconds() + now.getMilliseconds()
  //   })-jhsf-broker-athletes_report.xlsx`;

  //   fileDownload(data, fileName);
  // }, []);

  return (
    <Stack spacing="12" align="flex-start" minWidth="170px">
      <NavSection title="GERAL">
        <NavLink icon={RiDashboardLine} to="/dashboard">
          Dashboard
        </NavLink>
      </NavSection>

      <NavSection title="PROPRIEDADES">
        <NavLink icon={RiBuilding2Line} to="/ventures">
          Empreendimentos
        </NavLink>
        <NavLink icon={RiHomeWifiLine} to="/leads">
          Leads
        </NavLink>
        <NavLink icon={RiClipboardLine} to="/appointments">
          Atendimentos
        </NavLink>
        <NavLink icon={RiBuilding4Line} to="/properties">
          Imóveis
        </NavLink>
      </NavSection>

      {user.isAdmin && (
        <NavSection title="ADMINISTRATIVO">
          <NavLink icon={RiSuitcaseLine} to="/property-owners">
            Proprietários
          </NavLink>

          <NavLink icon={RiContactsLine} to="/users">
            Usuários
          </NavLink>

          <NavLink icon={RiTeamLine} to="/teams">
            Times
          </NavLink>

          <NavLink icon={RiFilmLine} to="/medias">
            Tipos de mídia
          </NavLink>

          <NavLink icon={RiDraftLine} to="/reports">
            Relatórios
          </NavLink>
        </NavSection>
      )}

      {/* {user.isAdmin && (
        <NavSection title="RUN THE VILLAGE">
          <Flex>
            <Button
              marginLeft="auto"
              colorScheme="green"
              onClick={handleAthleteReportExport}
            >
              Exportar atletas inscritos
            </Button>
          </Flex>
        </NavSection>
      )} */}
    </Stack>
  );
};
