import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Flex,
  Box,
  VStack,
  Button,
  Icon,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import venturePlaceholder from '../../../../../../../assets/venture_placeholder.png';
import { DeleteConfirmationModal } from '../../../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { appointmentOpportunitiesUnassignService } from '../../../../../../../services/Appointments/AppointmentOpportunitiesUnassignService';
import { IDetailedVenture } from '../../../../../../../services/Ventures/ShowVenturesService';

enum BusinessType {
  rent = 'Locação',
  sell = 'Venda',
  sellAndRent = 'Venda e Locação',
}

export interface IVentureTableItem extends IDetailedVenture {
  coverImageUrl?: string;
  formattedDeliveryDate: string;
  formattedPrice: string;
  formattedMinPrivativeArea: string;
}

interface IVentureTableProps {
  venture: IVentureTableItem;
  appointmentId: string;
  onVentureUnassign: (ventureId: string) => void;
}

export const AppointmentVentureTable = ({
  venture,
  appointmentId,
  onVentureUnassign,
}: IVentureTableProps): JSX.Element => {
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const handleVentureUnassign = useCallback(async () => {
    try {
      await appointmentOpportunitiesUnassignService({
        appointmentId,
        ventureId: venture.id,
      });

      onVentureUnassign(venture.id);
      handleToggleDeleteConfirmationModal();

      toast({
        title: 'Desvinculado com sucesso',
        description: 'O empreendimento foi desvinculado corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    } catch (err) {
      toast({
        title: 'Erro ao desvincular',
        description:
          'Ocorreu um erro ao desvincular o empreendimento, tente novamente',
        status: 'error',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    }
  }, [
    appointmentId,
    handleToggleDeleteConfirmationModal,
    onVentureUnassign,
    toast,
    venture.id,
  ]);

  return (
    <Box>
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleVentureUnassign}
      />

      <Table colorScheme="blue">
        <Thead>
          <Tr>
            <Th w="55%" />
            <Th w="25%" />
            <Th w="10%" />
            <Th w="10%" />
          </Tr>
        </Thead>
        <Tbody>
          <Tr key={venture.id}>
            <Td>
              <Flex>
                <Box
                  bgImage={venture.coverImageUrl || venturePlaceholder}
                  bgRepeat="no-repeat"
                  bgSize="cover"
                  borderRadius="8"
                  h="40"
                  minW="240px"
                />

                <Flex direction="column" justify="space-between" ml="4">
                  <Box>
                    <Text fontSize="xl" fontWeight="bold">
                      {venture.title}
                    </Text>
                    <Text color="gray.500" fontSize="sm">
                      {venture.condominiumName}
                    </Text>
                  </Box>

                  <Box>
                    <Text fontSize="sm" color="gray.500" mt="2">
                      {`${venture.address.street},  ${venture.address.number}`}
                    </Text>
                    <Text fontSize="sm" color="gray.500" mb="4">
                      {`${venture.address.neighborhood}, ${venture.address.city} - ${venture.address.state}`}
                    </Text>
                    <Text as="strong">
                      {venture.ref && `${venture.ref} / `}
                      <Text as="span" fontWeight="normal">
                        {venture.developerReg}
                      </Text>
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Td>

            <Td>
              <Flex direction="column" justify="space-between" h="40">
                <Box>
                  <Text fontSize="sm">A partir de:</Text>
                  <Text as="strong" color="blue.500">
                    {venture.formattedPrice}
                  </Text>
                </Box>

                <Box>
                  <Text fontSize="sm">A partir de:</Text>
                  <Text as="strong">{`${venture.formattedMinPrivativeArea} m²`}</Text>
                </Box>

                <Box>
                  <Text fontSize="sm">Previsão de entrega:</Text>
                  <Text fontSize="sm" color="blue.500">
                    {venture.formattedDeliveryDate}
                  </Text>
                </Box>
              </Flex>
            </Td>

            <Td>
              <Flex direction="column" justify="space-between" h="40">
                <Box>
                  <Text fontSize="sm">
                    {'Disponível para: '}
                    <Text as="strong" color="blue.500">
                      {BusinessType[venture.businessType]}
                    </Text>
                  </Text>
                </Box>

                <VStack flex="1" justify="center" align="left" spacing="1">
                  {venture.floors && (
                    <Flex align="center">
                      <Box
                        textAlign="center"
                        bgColor="gray.200"
                        borderRadius="8"
                        minW="10"
                        py="0.5"
                      >
                        <Text as="strong" fontSize="sm">
                          {venture.floors}
                        </Text>
                      </Box>
                      <Text as="span" fontWeight="normal" ml="2" fontSize="sm">
                        Andares
                      </Text>
                    </Flex>
                  )}

                  {venture.coveredGarages && (
                    <Flex align="center">
                      <Box
                        textAlign="center"
                        bgColor="gray.200"
                        borderRadius="8"
                        minW="10"
                        py="0.5"
                      >
                        <Text as="strong" fontSize="sm">
                          {venture.coveredGarages}
                        </Text>
                      </Box>
                      <Text as="span" fontWeight="normal" ml="2" fontSize="sm">
                        Garagens cobertas
                      </Text>
                    </Flex>
                  )}

                  {venture.minDormitories && (
                    <Flex align="center">
                      <Box
                        textAlign="center"
                        bgColor="gray.200"
                        borderRadius="8"
                        minW="10"
                        py="0.5"
                      >
                        <Text as="strong" fontSize="sm">
                          {venture.minDormitories}
                        </Text>
                      </Box>
                      <Text as="span" fontWeight="normal" ml="2" fontSize="sm">
                        Dormitórios
                      </Text>
                    </Flex>
                  )}

                  {venture.minSuites && (
                    <Flex align="center">
                      <Box
                        textAlign="center"
                        bgColor="gray.200"
                        borderRadius="8"
                        minW="10"
                        py="0.5"
                      >
                        <Text as="strong" fontSize="sm">
                          {venture.minSuites}
                        </Text>
                      </Box>
                      <Text as="span" fontWeight="normal" ml="2" fontSize="sm">
                        Suítes
                      </Text>
                    </Flex>
                  )}
                </VStack>
              </Flex>
            </Td>

            <Td>
              <Flex direction="column">
                <LinkButton
                  w="100%"
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/ventures/details',
                    state: {
                      ventureId: venture.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>

                <Button
                  mt="2"
                  size="sm"
                  fontSize="sm"
                  colorScheme="red"
                  onClick={handleToggleDeleteConfirmationModal}
                  leftIcon={<Icon as={RiDeleteBinLine} fontSize="16" />}
                >
                  Desvincular
                </Button>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};
