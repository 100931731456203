import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import axios from 'axios';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { showPropertyOwnersService } from '../../../../../services/PropertyOwners/ShowPropertyOwnersService';
import { updatePropertyOwnersService } from '../../../../../services/PropertyOwners/UpdatePropertyOwnersService';

type UpdatePropertyOwnerFormData = {
  description?: string;
  email: string;
  idCard?: string;
  mainPhone: string;
  name: string;
  secondaryPhone?: string;
  taxPayerId: string;
};

interface ILocationState {
  propertyOwnerId?: string;
}

const propertyOwnerUpdateFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome requerido'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  idCard: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  mainPhone: Yup.string()
    .required('Requerido')
    .test('phoneLength', 'Inválido', (value) =>
      value ? value.length > 9 : false,
    )
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  secondaryPhone: Yup.string()
    .nullable()
    .test('phoneLength', 'Inválido', (value) =>
      value ? value.length > 9 : true,
    )
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  taxPayerId: Yup.string().required('Requerido'),
});

export const PropertyOwnerUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();

  const [updatingPropertyOwner, setUpdatingPropertyOwner] =
    useState<UpdatePropertyOwnerFormData>();

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(propertyOwnerUpdateFormSchema),
    defaultValues: {
      email: updatingPropertyOwner?.email,
      name: updatingPropertyOwner?.name,
      description: updatingPropertyOwner?.description,
      idCard: updatingPropertyOwner?.idCard,
      mainPhone: updatingPropertyOwner?.mainPhone,
      secondaryPhone: updatingPropertyOwner?.secondaryPhone,
      taxPayerId: updatingPropertyOwner?.taxPayerId,
    },
  });

  const { errors } = formState;
  const { propertyOwnerId } = state;

  useEffect(() => {
    async function loadPropertyOwner(): Promise<void> {
      if (propertyOwnerId) {
        try {
          const propertyOwnerDetails = await showPropertyOwnersService(
            propertyOwnerId,
          );

          setUpdatingPropertyOwner(propertyOwnerDetails);
          reset({
            email: propertyOwnerDetails?.email,
            name: propertyOwnerDetails?.name,
            description: propertyOwnerDetails?.description,
            idCard: propertyOwnerDetails?.idCard,
            mainPhone: propertyOwnerDetails?.mainPhone,
            secondaryPhone: propertyOwnerDetails?.secondaryPhone,
            taxPayerId: propertyOwnerDetails?.taxPayerId,
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao carregar dados',
              description:
                'Ocorreu um erro ao carregar os dados do proprietário, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    }

    loadPropertyOwner();
  }, [propertyOwnerId, reset, toast]);

  const handleUpdatePropertyOwner: SubmitHandler<UpdatePropertyOwnerFormData> =
    useCallback(
      async (propertyOwnerData) => {
        if (propertyOwnerId) {
          try {
            await updatePropertyOwnersService({
              propertyOwnerId,
              ...propertyOwnerData,
            });

            toast({
              title: 'Editado com sucesso',
              description: 'O proprietário foi editado corretamente',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            goBack();
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha ao editar',
                description:
                  'Ocorreu um erro ao editar o proprietário, tente novamente',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        }
      },
      [propertyOwnerId, goBack, toast],
    );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdatePropertyOwner)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar Proprietário
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="Nome completo"
              error={errors.name}
              {...register('name')}
            />
            <Input
              type="email"
              label="E-mail"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <InternationalPhoneInput
              name="mainPhone"
              label="Telefone"
              control={control}
              error={errors.mainPhone}
            />
            <InternationalPhoneInput
              name="secondaryPhone"
              label="Telefone 2"
              control={control}
              error={errors.secondaryPhone}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="RG"
              mask="rg"
              error={errors.idCard}
              {...register('idCard')}
            />
            <Input
              label="CPF"
              mask="cpf"
              error={errors.taxPayerId}
              {...register('taxPayerId')}
            />
          </SimpleGrid>

          <Input
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Descrição"
            error={errors.description}
            {...register('description')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
