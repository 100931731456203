import api from '../api';
import {
  PropertyBase,
  PropertyAddress,
  PropertyImage,
} from '../../models/property';

interface IListPropertyProps {
  ignoreAssignedToAppointmentId?: string;
  limit?: number;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'title' | 'businessType' | 'isActive' | 'isPublished';
  title?: string;
}

export interface IPropertyListItem extends PropertyBase {
  address: PropertyAddress;
  images: PropertyImage[];
}

export interface IPropertyList {
  items: IPropertyListItem[];
  pages: number;
  total: number;
}

export const listPropertiesService = async (
  filters?: IListPropertyProps,
): Promise<IPropertyList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          ignore: filters.ignoreAssignedToAppointmentId,
          limit: filters.limit,
          order: filters.order,
          page: filters.page,
          sort: filters.sort,
          title: filters.title || undefined,
        }
      : undefined;

  const { data } = await api.get<IPropertyList>('/properties', {
    params,
  });

  return data;
};
