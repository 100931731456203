import api from '../api';
import { LeadBase, LeadContact } from '../../models/lead';
import { AppointmentBase, AppointmentHistory } from '../../models/appointment';
import { MediaBase } from '../../models/media';
import {
  PropertyBase,
  PropertyAddress,
  PropertyImage,
} from '../../models/property';
import { UserBase } from '../../models/user';
import {
  VentureBase,
  VentureAddress,
  VentureImage,
} from '../../models/venture';

interface IUpdateLeadContactProps {
  description?: string;
  email?: string;
  name: string;
  phone?: string;
}

interface IUpdateLeadProps {
  leadId: string;
  description?: string;
  email?: string;
  interest: 'rent' | 'sell' | 'sellAndRent';
  isActive: boolean;
  contacts?: IUpdateLeadContactProps[];
  mediaId?: string;
  name: string;
  nickname?: string;
  phone?: string;
}

interface IUpdateLeadAppointmentDetailedLead extends LeadBase {
  media: MediaBase;
}

interface IUpdateLeadAppointmentDetailedProperty extends PropertyBase {
  address: PropertyAddress;
  images: PropertyImage[];
}

interface IUpdateLeadAppointmentDetailedVenture extends VentureBase {
  address: VentureAddress;
  images: VentureImage[];
}

interface IUpdateLeadDetailedAppointment extends AppointmentBase {
  histories: AppointmentHistory[];
  lead: IUpdateLeadAppointmentDetailedLead;
  properties: IUpdateLeadAppointmentDetailedProperty[];
  user: UserBase;
  ventures: IUpdateLeadAppointmentDetailedVenture[];
}

export interface IUpdatedLead extends LeadBase {
  appointments: IUpdateLeadDetailedAppointment[];
  contacts: LeadContact[];
}

export const updateLeadsService = async ({
  leadId,
  description,
  email,
  interest,
  isActive,
  contacts,
  mediaId,
  name,
  nickname,
  phone,
}: IUpdateLeadProps): Promise<IUpdatedLead> => {
  const { data } = await api.patch<IUpdatedLead>(`/leads/${leadId}`, {
    description,
    email,
    interest,
    isActive,
    contacts,
    mediaId,
    name,
    nickname,
    phone,
  });

  return data;
};
