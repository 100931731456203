import api from '../api';
import { LeadBase } from '../../models/lead';

interface IListLeadProps {
  email?: string;
  limit?: number;
  name?: string;
  order?: 'ASC' | 'DESC';
  page?: number;
  phone?: string;
  sort?: 'email' | 'interest' | 'name' | 'phone' | 'createdAt';
}

export type ILeadListItem = LeadBase;

export interface ILeadList {
  items: ILeadListItem[];
  pages: number;
  total: number;
}

export const listLeadsService = async (
  filters?: IListLeadProps,
): Promise<ILeadList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          email: filters.email || undefined,
          limit: filters.limit,
          name: filters.name || undefined,
          order: filters.order,
          page: filters.page,
          phone: filters.phone || undefined,
          sort: filters.sort,
        }
      : undefined;

  const { data } = await api.get<ILeadList>('/leads', {
    params,
  });

  return data;
};
