import api from '../api';
import { VentureImage } from '../../models/venture';

interface ICreateVentureImageProps {
  imageData: FormData;
  ventureId: string;
}

export type INewVentureImage = VentureImage;

export const createVentureImagesService = async ({
  imageData,
  ventureId,
}: ICreateVentureImageProps): Promise<INewVentureImage> => {
  const { data } = await api.post<INewVentureImage>(
    `/images/ventures/${ventureId}`,
    imageData,
  );

  return data;
};
