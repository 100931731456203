import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { MediaTable, MediaTableItem } from './components/MediaTable';
import { listMediasService } from '../../../../../services/Medias/ListMediasService';

interface ILoadMediaProps {
  name?: string;
  page?: number;
  sort?: 'name' | 'isActive' | 'visible';
  order?: 'ASC' | 'DESC';
}

export const MediaList = (): JSX.Element => {
  const toast = useToast();

  const [mediasList, setMediasList] = useState<MediaTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<'name' | 'isActive' | 'visible'>('name');
  const [totalPages, setTotalPages] = useState<number>();

  const loadMedias = useCallback(
    async ({ name, page, sort, order }: ILoadMediaProps) => {
      try {
        const { pages, items } = await listMediasService({
          name: name || undefined,
          page,
          sort,
          order,
        });

        setTotalPages(pages);
        setMediasList(items);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados dos tipos de mídia, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadMedias({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [currentFilters, currentOrdering, currentPage, loadMedias, sortBy, toast]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'name' | 'isActive' | 'visible') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handleDeleteMedia = useCallback((mediaId: string) => {
    setMediasList((prevState) =>
      prevState.filter((media) => media.id !== mediaId),
    );
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Tipos de mídia
          </Heading>

          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to="/medias/register"
          >
            Cadastrar novo
          </LinkButton>
        </Flex>

        <MediaTable
          mt="4"
          currentPage={currentPage}
          currentOrder={currentOrdering}
          filterBy={filterBy}
          medias={mediasList}
          onChangeFilterBy={handleFilterBy}
          onDelete={handleDeleteMedia}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
