import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { PropertyTable } from '../../components/PropertyTable';
import {
  maskArea,
  maskDate,
  maskMoney,
} from '../../../../../utils/formatters/handleMask';
import { LinkButton } from '../../../../../components/LinkButton';
import { IPropertyTableItem } from '../../Appointments/AppointmentDetails/components/AppointmentPropertyTable';
import { listPropertiesService } from '../../../../../services/Properties/ListPropertiesService';
import { useAuth } from '../../../../../hooks/auth';

interface ILoadPropertyProps {
  title?: string;
  page?: number;
  sort?: 'title' | 'businessType' | 'isActive' | 'isPublished';
  order?: 'ASC' | 'DESC';
}

export const PropertyList = (): JSX.Element => {
  const { user } = useAuth();
  const toast = useToast();

  const [propertyList, setPropertyList] = useState<IPropertyTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('title');
  const [sortBy, setSortBy] = useState<
    'title' | 'businessType' | 'isActive' | 'isPublished'
  >('title');
  const [totalPages, setTotalPages] = useState<number>();

  const loadProperties = useCallback(
    async ({ title, page, sort, order }: ILoadPropertyProps) => {
      try {
        const listProperties = await listPropertiesService({
          title: title || undefined,
          page,
          sort,
          order,
        });

        const parsedProperties = listProperties.items.map((property) => ({
          ...property,
          coverImageUrl: property.images.find((image) => image.isCover)
            ?.imageUrl,
          formattedCondominiumPrice: property.condominiumPrice
            ? maskMoney(property.condominiumPrice)
            : '-',
          formattedDeliveryDate: property.deliveryDate
            ? maskDate(property.deliveryDate)
            : '-',
          formattedRentPrice: property.rentPrice
            ? maskMoney(property.rentPrice)
            : '-',
          formattedSellPrice: property.sellPrice
            ? maskMoney(property.sellPrice)
            : '-',
          formattedTotalArea: maskArea(String(property.totalArea)),
          formattedUsefulArea: property.usefulArea
            ? maskArea(String(property.usefulArea))
            : '-',
        }));

        setTotalPages(listProperties.pages);
        setPropertyList(parsedProperties);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados dos imóveis, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadProperties({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [
    currentFilters,
    currentOrdering,
    currentPage,
    loadProperties,
    sortBy,
    toast,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'title' | 'businessType' | 'isActive' | 'isPublished') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Imóveis
          </Heading>

          {user.isAdmin && (
            <LinkButton
              colorScheme="green"
              icon={<Icon as={RiAddLine} fontSize="16" />}
              to="/properties/register"
            >
              Cadastrar novo
            </LinkButton>
          )}
        </Flex>

        <PropertyTable
          mt="4"
          currentOrder={currentOrdering}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          properties={propertyList}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
