import { useLocation } from 'react-router-dom';
import { Box, Heading, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';

import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import {
  AppointmentTable,
  IAppointmentTableItem,
} from './components/AppointmentTable';
import { listAppointmentsService } from '../../../../../services/Appointments/ListAppointmentsService';

interface ILocationState {
  status?:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
}

interface ILoadAppointmentsProps {
  leadName?: string;
  userName?: string;
  ventureId?: string;
  status?:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
  leadStatus?: string;
  page?: number;
  sort?: 'status' | 'leadName' | 'userName' | 'createdAt' | 'interest';
  order?: 'ASC' | 'DESC';
}

export const AppointmentList = (): JSX.Element => {
  const toast = useToast();
  const { state } = useLocation<ILocationState>();

  const [appointmentList, setAppointmentList] = useState<
    IAppointmentTableItem[]
  >([]);
  const [currentFilters, setCurrentFilters] = useState<{
    [key: string]: string | undefined;
  }>({ status: state?.status });
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('leadName');
  const [sortBy, setSortBy] = useState<
    'status' | 'leadName' | 'userName' | 'createdAt' | 'interest'
  >('createdAt');
  const [totalPages, setTotalPages] = useState<number>();

  const loadAppointments = useCallback(
    async ({
      leadName,
      userName,
      ventureId,
      status,
      leadStatus,
      page,
      sort,
      order,
    }: ILoadAppointmentsProps) => {
      try {
        const { items, pages } = await listAppointmentsService({
          leadName: leadName || undefined,
          userName: userName || undefined,
          ventureId,
          status: status || undefined,
          leadStatus: leadStatus || undefined,
          page,
          sort,
          order,
        });

        const parsedAppintmentsList = items.map((appointment) => ({
          ...appointment,
          parsedDate: maskShortDate(appointment.createdAt),
        }));

        setAppointmentList(parsedAppintmentsList);
        setTotalPages(pages);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados dos agendamentos, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadAppointments({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [currentFilters, currentOrdering, currentPage, loadAppointments, sortBy]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'status' | 'leadName' | 'userName' | 'createdAt' | 'interest') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Atendimentos
        </Heading>

        <AppointmentTable
          mt="4"
          appointments={appointmentList}
          currentOrder={currentOrdering}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
