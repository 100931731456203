import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Flex,
  Box,
  Heading,
  VStack,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { RiPencilLine } from 'react-icons/ri';
import venturePlaceholder from '../../../../../assets/venture_placeholder.png';
import { LinkButton } from '../../../../../components/LinkButton';
import { Pagination } from '../../../../../components/Pagination';
import { TableFilters } from '../../../../../components/TableFilters';
import { IVentureListItem } from '../../../../../services/Ventures/ListVenturesService';

enum BusinessType {
  rent = 'Locação',
  sell = 'Venda',
  sellAndRent = 'Venda e Locação',
}

export interface IVentureTableItem extends IVentureListItem {
  coverImageUrl?: string;
  formattedDeliveryDate: string;
  formattedPrice: string;
}

interface IVentureTableProps extends BoxProps {
  currentOrder?: 'ASC' | 'DESC';
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (
    sort: 'title' | 'businessType' | 'isActive' | 'isPublished',
  ) => void;
  sortBy?: string;
  totalPages?: number;
  ventures: IVentureTableItem[];
}

export const VentureTable = ({
  currentOrder,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  sortBy,
  totalPages,
  ventures,
  ...rest
}: IVentureTableProps): JSX.Element => {
  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(sort as 'title' | 'businessType' | 'isActive' | 'isPublished');
      }
    },
    [onSort],
  );

  const filterByOptions = useMemo(
    () => [
      {
        label: 'Título',
        value: 'title',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Título',
        value: 'title',
      },
      {
        label: 'Tipo de negócio',
        value: 'businessType',
      },
      {
        label: 'Ativo',
        value: 'isActive',
      },
      {
        label: 'Publicado',
        value: 'isPublished',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        currentOrder={currentOrder}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={handleSort}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th w="55%" />
            <Th w="25%" />
            <Th w="10%" />
            <Th w="10%" />
          </Tr>
        </Thead>

        <Tbody>
          {ventures.map((venture) => (
            <Tr key={venture.id}>
              <Td>
                <Flex>
                  <Box
                    bgImage={venture.coverImageUrl || venturePlaceholder}
                    bgRepeat="no-repeat"
                    bgSize="cover"
                    borderRadius="8"
                    h="40"
                    minW="240px"
                  />
                  <Flex direction="column" justify="space-between" ml="4">
                    <Box>
                      <Heading fontSize="xl">{venture.title}</Heading>
                      <Text color="gray.500">{venture.condominiumName}</Text>
                    </Box>
                    <Box>
                      <Text fontSize="sm" color="gray.500" mt="2">
                        {`${venture.address.street},  ${venture.address.number}`}
                      </Text>

                      <Text fontSize="sm" color="gray.500" mb="4">
                        {`${venture.address.neighborhood}, ${venture.address.city} - ${venture.address.state}`}
                      </Text>

                      <Text as="strong">
                        {venture.ref && `${venture.ref} / `}
                        <Text as="span" fontWeight="normal">
                          {venture.developerReg}
                        </Text>
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Td>

              <Td>
                <Flex direction="column" justify="space-between" h="40">
                  {venture.formattedPrice && (
                    <Box>
                      <Text fontSize="sm">A partir de:</Text>
                      <Text as="strong" color="blue.500">
                        {venture.formattedPrice}
                      </Text>
                    </Box>
                  )}

                  {venture.minPrivativeArea && (
                    <Box>
                      <Text fontSize="sm">A partir de:</Text>
                      <Text as="strong">{`${venture.minPrivativeArea} m²`}</Text>
                    </Box>
                  )}

                  <Box>
                    <Text fontSize="sm">Previsão de entrega:</Text>
                    <Text fontSize="sm" color="blue.500">
                      {venture.formattedDeliveryDate}
                    </Text>
                  </Box>
                </Flex>
              </Td>

              <Td>
                <Flex direction="column" justify="space-between" h="40">
                  <Box>
                    <Text fontSize="sm">
                      {'Disponível para: '}
                      <Text as="strong" color="blue.500">
                        {BusinessType[venture.businessType]}
                      </Text>
                    </Text>
                  </Box>

                  <VStack flex="1" justify="center" align="left" spacing="1">
                    {venture.floors && (
                      <Flex align="center">
                        <Box
                          textAlign="center"
                          bgColor="gray.200"
                          borderRadius="8"
                          minW="10"
                          py="0.5"
                        >
                          <Text as="strong" fontSize="sm">
                            {venture.floors}
                          </Text>
                        </Box>

                        <Text
                          as="span"
                          fontWeight="normal"
                          ml="2"
                          fontSize="sm"
                        >
                          Andares
                        </Text>
                      </Flex>
                    )}

                    {venture.minDormitories && (
                      <Flex align="center">
                        <Box
                          textAlign="center"
                          bgColor="gray.200"
                          borderRadius="8"
                          minW="10"
                          py="0.5"
                        >
                          <Text as="strong" fontSize="sm">
                            {venture.minDormitories}
                          </Text>
                        </Box>

                        <Text
                          as="span"
                          fontWeight="normal"
                          ml="2"
                          fontSize="sm"
                        >
                          Dormitórios
                        </Text>
                      </Flex>
                    )}

                    {venture.minSuites && (
                      <Flex align="center">
                        <Box
                          textAlign="center"
                          bgColor="gray.200"
                          borderRadius="8"
                          minW="10"
                          py="0.5"
                        >
                          <Text as="strong" fontSize="sm">
                            {venture.minSuites}
                          </Text>
                        </Box>

                        <Text
                          as="span"
                          fontWeight="normal"
                          ml="2"
                          fontSize="sm"
                        >
                          Suítes
                        </Text>
                      </Flex>
                    )}
                  </VStack>
                </Flex>
              </Td>

              <Td>
                <LinkButton
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/ventures/details',
                    state: {
                      ventureId: venture.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
