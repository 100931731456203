import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';
import { UserRoleBase } from '../../models/userRole';

interface IUpdateUserAvatarProps {
  userId: string;
  avatarData: FormData;
}

export interface IUpdatedUserAvatar extends UserBase {
  teams: TeamBase[];
  userRoles: UserRoleBase[];
}

export const updateUserAvatarsService = async ({
  avatarData,
  userId,
}: IUpdateUserAvatarProps): Promise<IUpdatedUserAvatar> => {
  const { data } = await api.patch<IUpdatedUserAvatar>(
    `/avatars/users/${userId}`,
    avatarData,
  );

  return data;
};
