import api from '../api';
import { MediaBase } from '../../models/media';

interface ICreateMediaProps {
  isActive: boolean;
  name: string;
  visible: boolean;
}

export type INewMedia = MediaBase;

export const createMediasService = async ({
  isActive,
  name,
  visible,
}: ICreateMediaProps): Promise<INewMedia> => {
  const { data } = await api.post<INewMedia>('/medias', {
    isActive,
    name,
    visible,
  });

  return data;
};
