import api from '../api';
import { VentureBase, VentureAddress } from '../../models/venture';

interface ICreateVentureAddressProps {
  city: string;
  complement?: string;
  neighborhood?: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface ICreateVentureProps {
  address: ICreateVentureAddressProps;
  buildings?: number;
  businessType: 'rent' | 'sell' | 'sellAndRent';
  condominiumName: string;
  coveredGarages?: number;
  deliveryDate?: string;
  description?: string;
  developerReg?: string;
  floors?: number;
  floorUnits?: number;
  isActive: boolean;
  isPublished: boolean;
  maxDormitories?: number;
  maxPrice?: number;
  maxPrivativeArea?: number;
  maxSuites?: number;
  maxTotalArea?: number;
  maxUsefulArea?: number;
  maxVacancies?: number;
  minDormitories?: number;
  minPrice?: number;
  minPrivativeArea?: number;
  minSuites?: number;
  minTotalArea?: number;
  minUsefulArea?: number;
  minVacancies?: number;
  negotiationDetails?: string;
  previousRef?: string;
  ref: string;
  stage: 'done' | 'underConstruction';
  title: string;
  uncoveredGarages?: number;
  videoUrl?: string;
  virtualTourUrl?: string;
}

export interface INewVenture extends VentureBase {
  address: VentureAddress;
}

export const createVenturesService = async ({
  address,
  buildings,
  businessType,
  condominiumName,
  coveredGarages,
  deliveryDate,
  description,
  developerReg,
  floors,
  floorUnits,
  isActive,
  isPublished,
  maxDormitories,
  maxPrice,
  maxPrivativeArea,
  maxSuites,
  maxTotalArea,
  maxUsefulArea,
  maxVacancies,
  minDormitories,
  minPrice,
  minPrivativeArea,
  minSuites,
  minTotalArea,
  minUsefulArea,
  minVacancies,
  negotiationDetails,
  previousRef,
  ref,
  stage,
  title,
  uncoveredGarages,
  videoUrl,
  virtualTourUrl,
}: ICreateVentureProps): Promise<INewVenture> => {
  const { data } = await api.post<INewVenture>('/ventures', {
    address,
    buildings,
    businessType,
    condominiumName,
    coveredGarages,
    deliveryDate,
    description,
    developerReg,
    floors,
    floorUnits,
    isActive,
    isPublished,
    maxDormitories,
    maxPrice,
    maxPrivativeArea,
    maxSuites,
    maxTotalArea,
    maxUsefulArea,
    maxVacancies,
    minDormitories,
    minPrice,
    minPrivativeArea,
    minSuites,
    minTotalArea,
    minUsefulArea,
    minVacancies,
    negotiationDetails,
    previousRef,
    ref,
    stage,
    title,
    uncoveredGarages,
    videoUrl,
    virtualTourUrl,
  });

  return data;
};
