import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  Icon,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { TiDelete } from 'react-icons/ti';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ImageDropzone } from '../../../../../../components/Form/ImageDropzone';
import { DeleteConfirmationModal } from '../../../../../../components/DeleteConfirmationModal';
import { VentureImage } from '../../../../../../models/venture';
import { createVentureImagesService } from '../../../../../../services/Ventures/CreateVentureImagesService';
import { updateVenturesService } from '../../../../../../services/Ventures/UpdateVenturesService';
import { deleteVentureImagesService } from '../../../../../../services/Ventures/DeleteVentureImagesService';

interface IHandleImageOrderProps {
  currentIndex: number;
  image: VentureImage;
  newIndex: number;
}

interface IHandleDeleteImageProps {
  imageFilename: string;
  index: number;
}

interface IVentureImagesProps {
  images: VentureImage[];
  ventureId?: string;
}

export const VentureImages = ({
  images,
  ventureId,
}: IVentureImagesProps): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const [ventureImages, setVentureImages] = useState(images);
  const [deleteImage, setDeleteImage] = useState<IHandleDeleteImageProps>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    setVentureImages(images);
  }, [images]);

  const handleToggleDeleteConfirmationModal = useCallback(
    (data?: IHandleDeleteImageProps) => {
      setDeleteImage(data);
      setIsDeleteConfirmationModalVisible((prevState) => !prevState);
    },
    [],
  );

  const handleNewImage = useCallback(
    async (newImage: File) => {
      if (ventureId) {
        try {
          const formData = new FormData();

          formData.append('image', newImage);

          const ventureImage = await createVentureImagesService({
            ventureId,
            imageData: formData,
          });

          setVentureImages((prevState) => [...prevState, ventureImage]);
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha no upload',
              description:
                'Ocorreu um erro ao fazer upload da imgagem, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [toast, ventureId],
  );

  const handleImageOrder = useCallback(
    ({ currentIndex, image, newIndex }: IHandleImageOrderProps) => {
      const cleanVentureImages = ventureImages.filter(
        (_, index) => index !== currentIndex,
      );

      const updatedVentureImages = [
        ...cleanVentureImages.slice(0, newIndex),
        { ...image, showOrder: newIndex + 1 },
        ...cleanVentureImages.slice(newIndex),
      ];

      if (newIndex < currentIndex) {
        const updatedVentureImageOrders = updatedVentureImages.map(
          (img, index) =>
            index > newIndex && index <= currentIndex
              ? { ...img, showOrder: img.showOrder + 1 }
              : img,
        );

        setVentureImages(updatedVentureImageOrders);
      } else {
        const updatedVentureImageOrders = updatedVentureImages.map(
          (img, index) =>
            index < newIndex && index >= currentIndex
              ? { ...img, showOrder: img.showOrder - 1 }
              : img,
        );

        setVentureImages(updatedVentureImageOrders);
      }
    },
    [ventureImages],
  );

  const handleCoverImage = useCallback((imageId: string) => {
    setVentureImages((prevState) =>
      prevState.map((image) =>
        image.id === imageId
          ? { ...image, isCover: true }
          : { ...image, isCover: false },
      ),
    );
  }, []);

  const saveVentureImages = useCallback(async () => {
    if (ventureId) {
      try {
        await updateVenturesService({ ventureId, images: ventureImages });

        toast({
          title: 'Salvo com sucesso',
          description: 'As imagens foram salvas corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/ventures/details', { ventureId });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao salvar',
            description:
              'Ocorreu um erro ao salvar as imagens, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [push, toast, ventureId, ventureImages]);

  const handleDeleteImage = useCallback(async () => {
    if (!deleteImage) {
      return;
    }

    try {
      await deleteVentureImagesService(deleteImage.imageFilename);

      const cleanVentureImages = ventureImages.filter(
        (file) => file.image !== deleteImage.imageFilename,
      );

      const updatedVentureImageOrders = cleanVentureImages.map((img, idx) =>
        idx >= deleteImage.index
          ? {
              ...img,
              showOrder: img.showOrder - 1,
            }
          : img,
      );

      const findCover = updatedVentureImageOrders.findIndex(
        (image) => image.isCover,
      );

      if (findCover < 0 && updatedVentureImageOrders.length) {
        updatedVentureImageOrders[0].isCover = true;
      }

      setDeleteImage(undefined);
      handleToggleDeleteConfirmationModal();
      setVentureImages(updatedVentureImageOrders);

      toast({
        title: 'Excluído com sucesso',
        description: 'A imagem foi excluída corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description: 'Ocorreu um erro ao excluir a imagem, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [deleteImage, handleToggleDeleteConfirmationModal, toast, ventureImages]);

  return (
    <Box>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteImage}
      />

      <VStack spacing="8">
        <ImageDropzone onChange={handleNewImage} />

        <Divider borderColor="gray.300" />

        <Grid
          templateColumns={[
            null,
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
          ]}
          gap="4"
          width="100%"
        >
          {ventureImages.map((image, index) => (
            <Flex key={String(index)} justify="center">
              <Flex
                p="2"
                height="240px"
                width="320px"
                direction="column"
                borderRadius={8}
                justifyContent="space-between"
                bgImage={image.imageUrl}
                bgSize="cover"
                bgRepeat="no-repeat"
              >
                <Button
                  p="0"
                  ml="auto"
                  size="xs"
                  colorScheme="red"
                  onClick={() =>
                    handleToggleDeleteConfirmationModal({
                      imageFilename: image.image,
                      index,
                    })
                  }
                >
                  <Icon as={TiDelete} fontSize="md" />
                </Button>
                <ButtonGroup>
                  <Button
                    colorScheme={image.isCover ? 'green' : 'blackAlpha'}
                    flex={1}
                    fontWeight="normal"
                    onClick={() => handleCoverImage(image.id)}
                  >
                    Foto de capa
                  </Button>
                  <Select
                    bg="blue.500"
                    border={0}
                    color="white"
                    value={image.showOrder - 1}
                    width="20%"
                    onChange={(e) =>
                      handleImageOrder({
                        currentIndex: index,
                        image,
                        newIndex: Number(e.target.value),
                      })
                    }
                    _hover={{
                      filter: 'brightness(0.9)',
                      transition: 'filter 0.2s',
                    }}
                  >
                    {ventureImages.map((_, i) => (
                      <Text
                        key={String(i)}
                        color="gray.700"
                        value={i}
                        as="option"
                      >
                        {i + 1}
                      </Text>
                    ))}
                  </Select>
                </ButtonGroup>
              </Flex>
            </Flex>
          ))}
        </Grid>
      </VStack>

      <Flex mt="12" justify="flex-end">
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={goBack}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            isLoading={false}
            onClick={saveVentureImages}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
