import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  HStack,
  Icon,
} from '@chakra-ui/react';
import axios from 'axios';
import { RiAddLine } from 'react-icons/ri';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { Switch } from '../../../../../components/Form/Switch';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';
import { LeadContactsTable } from '../components/LeadContactsTable';
import {
  ILeadContactsModalFormData,
  LeadContactsModal,
} from '../components/LeadContactsModal';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { ExistingLeadModal } from '../components/ExistingLeadModal';
import { listMediasService } from '../../../../../services/Medias/ListMediasService';
import { createLeadsService } from '../../../../../services/Leads/CreateLeadsService';

type SelectOptions = {
  label: string;
  value: string;
};

interface IHandleLeadContactProps extends ILeadContactsModalFormData {
  index?: number;
}

type NewLeadFormData = {
  description?: string;
  email?: string;
  interest: 'rent' | 'sell' | 'sellAndRent';
  isActive: boolean;
  name: string;
  nickname?: string;
  phone?: string;
  mediaId?: string;
};

const leadRegisterFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Requerido'),
  nickname: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  email: Yup.string()
    .email('E-mail inválido')
    .nullable()
    .transform((value, originalValue) =>
      originalValue === '' ? null : value.toLowerCase(),
    ),
  phone: Yup.string()
    .when('email', {
      is: (val: string) => !val,
      then: Yup.string().required('Requerido'),
      otherwise: Yup.string(),
    })
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  interest: Yup.string().required('Requerido'),
  isActive: Yup.boolean().required(),
  mediaId: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const LeadRegister = (): JSX.Element => {
  const toast = useToast();
  const { push, goBack } = useHistory();

  const [leadContacts, setLeadContacts] = useState<
    ILeadContactsModalFormData[]
  >([]);
  const [mediaSelectOptions, setMediaSelectOptions] = useState<SelectOptions[]>(
    [],
  );
  const [updatingLeadContact, setUpdatingLeadContact] =
    useState<ILeadContactsModalFormData>();
  const [existingLeadId, setExistingLeadId] = useState<string>();
  const [isLeadContactsModalVisible, setIsLeadContactsModalVisible] =
    useState(false);
  const [isExistingLeadModalVisible, setIsExistingLeadModalVisible] =
    useState(false);

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(leadRegisterFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    async function loadMediaSelectOptions(): Promise<void> {
      try {
        const { items } = await listMediasService();

        const parsedMediaSelectOption = items.map((media) => ({
          label: media.name,
          value: media.id,
        }));

        setMediaSelectOptions(parsedMediaSelectOption);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados dos tipos de mídia, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadMediaSelectOptions();
    reset({ isActive: true });
  }, [reset, toast]);

  const handleLeadContactsModal = useCallback(
    (leadContact?: ILeadContactsModalFormData) => {
      setUpdatingLeadContact(leadContact);

      setIsLeadContactsModalVisible((prevState) => !prevState);
    },
    [],
  );

  const handleLeadContact = useCallback(
    ({ index, ...rest }: IHandleLeadContactProps) => {
      if (index || index === 0) {
        setLeadContacts((prevState) =>
          prevState.map((contact, idx) =>
            idx === index ? { ...contact, ...rest } : contact,
          ),
        );
      } else {
        setLeadContacts((prevState) => [...prevState, rest]);
      }

      handleLeadContactsModal(undefined);
    },
    [handleLeadContactsModal],
  );

  const handleDeleteContact = useCallback((contactIndex: number) => {
    setLeadContacts((prevState) =>
      prevState.filter((_, index) => index !== contactIndex),
    );
  }, []);

  const handleExistingLeadModal = useCallback((leadId?: string) => {
    setExistingLeadId(leadId);

    setIsExistingLeadModalVisible((prevState) => !prevState);
  }, []);

  const handleNewLead: SubmitHandler<NewLeadFormData> = useCallback(
    async ({ email, mediaId, phone, ...rest }) => {
      try {
        const newLead = await createLeadsService({
          ...rest,
          email,
          phone,
          mediaId: mediaId || undefined,
          contacts: leadContacts,
        });

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O lead foi cadastrado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/leads/details', { leadId: newLead.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          switch (err.response?.data.message) {
            case 'E-mail or phone already registered.':
              handleExistingLeadModal(err.response.data.metaData.leadId);

              break;

            default:
              toast({
                title: 'Falha ao cadastrar',
                description:
                  'Ocorreu um erro ao cadastrar lead, tente novamente',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });

              break;
          }
        }
      }
    },
    [handleExistingLeadModal, leadContacts, push, toast],
  );

  const handleExistingLead = useCallback(() => {
    push('/leads/details', { leadId: existingLeadId });
  }, [existingLeadId, push]);

  const interestSelectOptions = useMemo<SelectOptions[]>(
    () => [
      { label: 'Locação', value: 'rent' },
      { label: 'Compra', value: 'sell' },
      { label: 'Compra e locação', value: 'sellAndRent' },
    ],
    [],
  );

  return (
    <DefaultLayout>
      <ExistingLeadModal
        isOpen={isExistingLeadModalVisible}
        onClose={() => handleExistingLeadModal(undefined)}
        onConfirm={handleExistingLead}
      />

      <LeadContactsModal
        isOpen={isLeadContactsModalVisible}
        leadContact={updatingLeadContact}
        onClose={() => handleLeadContactsModal(undefined)}
        onConfirm={handleLeadContact}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewLead)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastro de lead
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input label="Cliente" error={errors.name} {...register('name')} />
            <Input
              label="Apelido"
              error={errors.nickname}
              {...register('nickname')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <HStack spacing="4">
              <InternationalPhoneInput
                name="phone"
                label="Telefone"
                control={control}
                error={errors.phone}
              />
              <ReactSelect
                name="interest"
                control={control}
                label="Interesse"
                options={interestSelectOptions}
                error={errors.interest}
              />
            </HStack>
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <ReactSelect
              name="mediaId"
              control={control}
              label="Tipo de mídia"
              options={mediaSelectOptions}
              error={errors.mediaId}
            />

            <Switch
              label="Ativo"
              error={errors.isActive}
              {...register('isActive')}
            />
          </SimpleGrid>

          <Input
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Descrição"
            error={errors.description}
            {...register('description')}
          />

          <Box w="100%">
            <HStack spacing="8" mb="8" align="flex-end">
              <Heading size="md" fontWeight="normal">
                Contatos
              </Heading>

              <Button
                size="sm"
                fontSize="sm"
                colorScheme="green"
                variant="link"
                onClick={() => handleLeadContactsModal(undefined)}
                leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              >
                Cadastrar contato
              </Button>
            </HStack>

            <LeadContactsTable
              leadContacts={leadContacts}
              onDelete={handleDeleteContact}
              onEdit={handleLeadContactsModal}
            />
          </Box>
        </VStack>

        <Flex mt="24" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
