import api from '../api';
import {
  VentureBase,
  VentureAddress,
  VentureImage,
} from '../../models/venture';

interface IListVentureProps {
  ignoreAssignedToAppointmentId?: string;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'title' | 'businessType' | 'isActive' | 'isPublished';
  title?: string;
}

export interface IVentureListItem extends VentureBase {
  address: VentureAddress;
  images: VentureImage[];
}

export interface IVentureList {
  items: IVentureListItem[];
  pages: number;
  total: number;
}

export const listVenturesService = async (
  filters?: IListVentureProps,
): Promise<IVentureList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          ignore: filters.ignoreAssignedToAppointmentId,
          order: filters.order,
          page: filters.page,
          sort: filters.sort,
          title: filters.title || undefined,
        }
      : undefined;

  const { data } = await api.get<IVentureList>('/ventures', { params });

  return data;
};
