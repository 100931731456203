import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { ReactMultiSelect } from '../../../../../components/Form/ReactMultiSelect';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { UserRoleBase } from '../../../../../models/userRole';
import { createUsersService } from '../../../../../services/Users/CreateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { listUserRolesService } from '../../../../../services/Users/ListUserRolesService';

type SelectOptions = {
  label: string;
  value: string;
};

type NewUserFormData = {
  bio?: string;
  creci?: string;
  email: string;
  name: string;
  password: string;
  passwordConfirmation: string;
  phone?: string;
  userRoles: UserRoleBase[];
};

const userRegisterFormSchema = Yup.object().shape({
  name: Yup.string().required('Requerido'),
  email: Yup.string()
    .email('Inválido')
    .required('Requerido')
    .transform((value) => value.toLowerCase()),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  creci: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  phone: Yup.string()
    .nullable()
    .test('phoneLength', 'Inválido', (value) =>
      value ? value.length > 9 : true,
    )
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  password: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  passwordConfirmation: Yup.string()
    .oneOf([null, Yup.ref('password')], 'Senhas não coincidem')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  userRoles: Yup.array()
    .min(1)
    .of(Yup.object())
    .required('Requerido')
    .transform((_, originalValue) =>
      originalValue.map((userRole: { value: string }) => ({
        id: userRole.value,
      })),
    ),
});

export const UserRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const [userRolesSelectOptions, setUserRolesSelectOptions] = useState<
    SelectOptions[]
  >([]);
  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(userRegisterFormSchema),
  });
  const toast = useToast();

  const { errors } = formState;

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewUser: SubmitHandler<NewUserFormData> = useCallback(
    async ({
      bio,
      creci,
      email,
      name,
      password,
      passwordConfirmation,
      phone,
      userRoles,
    }) => {
      try {
        const newUser = await createUsersService({
          bio,
          creci,
          email,
          name,
          password,
          passwordConfirmation,
          phone,
          userRoles,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateUserAvatarsService({
            userId: newUser.id,
            avatarData: formData,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O usuário foi cadastrado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/users/details', { userId: newUser.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              'Ocorreu um erro ao cadastrar o usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast],
  );

  useEffect(() => {
    async function loadUserRoles(): Promise<void> {
      try {
        const userRolesList = await listUserRolesService();

        setUserRolesSelectOptions(
          userRolesList.map((role) => ({
            label: role.name,
            value: role.id,
          })),
        );
      } catch (err) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar dados dos cargos de usuário, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }

    loadUserRoles();
  }, [toast]);

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewUser)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastro de usuário
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" w="50%">
            <ReactMultiSelect
              name="userRoles"
              label="Cargos"
              control={control}
              error={errors.userRoles}
              options={userRolesSelectOptions}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="Nome completo"
              error={errors.name}
              {...register('name')}
            />
            <Input
              type="email"
              label="E-mail"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="CRECI"
              mask="creci"
              error={errors.creci}
              {...register('creci')}
            />
            <InternationalPhoneInput
              name="phone"
              label="Telefone"
              control={control}
              error={errors.phone}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              label="Biografia"
              error={errors.bio}
              {...register('bio')}
            />

            <VStack spacing="8">
              <Input
                type="password"
                label="Senha"
                error={errors.password}
                {...register('password')}
              />
              <Input
                type="password"
                label="Confirmação de senha"
                error={errors.passwordConfirmation}
                {...register('passwordConfirmation')}
              />
            </VStack>
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
