import api from '../api';
import { LeadBase, LeadContact } from '../../models/lead';

interface ICreateLeadContactProps {
  description?: string;
  email?: string;
  name: string;
  phone?: string;
}

interface ICreateLeadProps {
  contacts?: ICreateLeadContactProps[];
  description?: string;
  email?: string;
  interest: 'rent' | 'sell' | 'sellAndRent';
  isActive: boolean;
  mediaId?: string;
  name: string;
  nickname?: string;
  phone?: string;
}

export interface INewLead extends LeadBase {
  contacts: LeadContact[];
}

export const createLeadsService = async ({
  contacts,
  description,
  email,
  interest,
  isActive,
  mediaId,
  name,
  nickname,
  phone,
}: ICreateLeadProps): Promise<INewLead> => {
  const { data } = await api.post<INewLead>('/leads', {
    contacts,
    description,
    email,
    interest,
    isActive,
    mediaId,
    name,
    nickname,
    phone,
  });

  return data;
};
