import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  SimpleGrid,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import { RiDeleteBinLine, RiEditLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedPropertyOwner,
  showPropertyOwnersService,
} from '../../../../../services/PropertyOwners/ShowPropertyOwnersService';
import { deletePropertyOwnersService } from '../../../../../services/PropertyOwners/DeletePropertyOwnersService';

interface IPropertyOwner extends IDetailedPropertyOwner {
  parsedDate: string;
}

interface ILocationState {
  propertyOwnerId?: string;
}

export const PropertyOwnerDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { propertyOwnerId },
  } = useLocation<ILocationState>();
  const { goBack } = useHistory();

  const [propertyOwner, setPropertyOwner] = useState<IPropertyOwner>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadPropertyOwner(
      showPropertyOwnerId: string,
    ): Promise<void> {
      try {
        const propertyOwnerDetails = await showPropertyOwnersService(
          showPropertyOwnerId,
        );

        const propertyOwnerWithParsedData = {
          ...propertyOwnerDetails,
          parsedDate: maskDate(propertyOwnerDetails.createdAt),
        };

        setPropertyOwner(propertyOwnerWithParsedData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do proprietário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (propertyOwnerId) {
      loadPropertyOwner(propertyOwnerId);
    }
  }, [propertyOwnerId, toast]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeletePropertyOwner = useCallback(async () => {
    if (propertyOwnerId) {
      try {
        await deletePropertyOwnersService(propertyOwnerId);

        toast({
          title: 'Excluído com sucesso',
          description: 'O proprietário foi excluído corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        toast({
          title: 'Falha ao excluir',
          description:
            'Ocorreu um erro ao excluir o proprietário, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [propertyOwnerId, goBack, toast]);

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeletePropertyOwner}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do proprietário
          </Heading>

          <ButtonGroup>
            <LinkButton
              colorScheme="yellow"
              color="white"
              icon={<Icon as={RiEditLine} fontSize="16" />}
              to={{
                pathname: '/property-owners/update',
                state: {
                  propertyOwnerId,
                },
              }}
            >
              Editar
            </LinkButton>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button>
          </ButtonGroup>
        </Flex>

        {propertyOwner && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {propertyOwner.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {propertyOwner.description}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="12"
              w="100%"
              align="center"
            >
              <Text>
                Telefone:
                <Text as="span" color="gray.500" ml="2">
                  {propertyOwner.mainPhone}
                </Text>
              </Text>

              <Text>
                E-mail:
                <Text as="span" color="gray.500" ml="2">
                  {propertyOwner.email}
                </Text>
              </Text>
              <Text>
                Data de cadastro:
                <Text as="span" color="gray.500" ml="2">
                  {propertyOwner.parsedDate}
                </Text>
              </Text>
            </SimpleGrid>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="8"
              w="100%"
              align="center"
            >
              <Text>
                Telefone2:
                <Text as="span" color="gray.500" ml="2">
                  {propertyOwner.secondaryPhone}
                </Text>
              </Text>
              <Text>
                RG:
                <Text as="span" color="gray.500" ml="2">
                  {propertyOwner.idCard}
                </Text>
              </Text>
              <Text>
                CPF:
                <Text as="span" color="gray.500" ml="2">
                  {propertyOwner.taxPayerId}
                </Text>
              </Text>
            </SimpleGrid>

            <Divider my="6" borderColor="gray.300" />

            <Heading size="md" fontWeight="normal">
              Imóveis
            </Heading>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
