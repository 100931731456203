import { AppointmentBase, AppointmentHistory } from '../../models/appointment';
import { LeadBase } from '../../models/lead';
import { MediaBase } from '../../models/media';
import {
  PropertyBase,
  PropertyAddress,
  PropertyImage,
} from '../../models/property';
import { UserBase } from '../../models/user';
import {
  VentureBase,
  VentureAddress,
  VentureImage,
} from '../../models/venture';
import api from '../api';

interface IAppointmentOpportunitiesAssignProps {
  appointmentId: string;
  propertiesId: Array<string>;
  venturesId: Array<string>;
}

interface IAppointmentOpportunitiesAssignDetailedLead extends LeadBase {
  media: MediaBase;
}

interface IAppointmentOpportunitiesAssignDetailedProperty extends PropertyBase {
  address: PropertyAddress;
  images: PropertyImage[];
}

interface IAppointmentOpportunitiesAssignDetailedVenture extends VentureBase {
  address: VentureAddress;
  images: VentureImage[];
}

export interface IAppointmentOpportunitiesAssign extends AppointmentBase {
  histories: AppointmentHistory[];
  lead: IAppointmentOpportunitiesAssignDetailedLead;
  properties: IAppointmentOpportunitiesAssignDetailedProperty[];
  user: UserBase;
  ventures: IAppointmentOpportunitiesAssignDetailedVenture[];
}

export const appointmentOpportunitiesAssignService = async ({
  appointmentId,
  propertiesId,
  venturesId,
}: IAppointmentOpportunitiesAssignProps): Promise<IAppointmentOpportunitiesAssign> => {
  const { data } = await api.post<IAppointmentOpportunitiesAssign>(
    `/appointments/${appointmentId}/opportunities-assign`,
    { propertiesId, venturesId },
  );

  return data;
};
