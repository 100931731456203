import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { TeamTable, TeamTableItem } from './components/TeamTable';
import { listTeamsService } from '../../../../../services/Teams/ListTeamsService';

interface ILoadTeamProps {
  name?: string;
  page?: number;
  sort?: 'name' | 'type' | 'interest';
  order?: 'ASC' | 'DESC';
}

export const TeamList = (): JSX.Element => {
  const toast = useToast();

  const [teamsList, setTeamsList] = useState<TeamTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<'name' | 'type' | 'interest'>('name');
  const [totalPages, setTotalPages] = useState<number>();

  const loadTeams = useCallback(
    async ({ name, page, sort, order }: ILoadTeamProps) => {
      try {
        const { pages, items } = await listTeamsService({
          name: name || undefined,
          page,
          sort,
          order,
        });

        setTotalPages(pages);
        setTeamsList(items);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados da lista de times, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadTeams({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [currentFilters, currentOrdering, currentPage, loadTeams, sortBy, toast]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'name' | 'type' | 'interest') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Times
          </Heading>

          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to="/teams/register"
          >
            Cadastrar novo
          </LinkButton>
        </Flex>

        <TeamTable
          mt="4"
          currentOrder={currentOrdering}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          sortBy={sortBy}
          teams={teamsList}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
