import { AppointmentBase, AppointmentHistory } from '../../models/appointment';
import { LeadBase } from '../../models/lead';
import { MediaBase } from '../../models/media';
import {
  PropertyBase,
  PropertyAddress,
  PropertyImage,
} from '../../models/property';
import { UserBase } from '../../models/user';
import {
  VentureBase,
  VentureAddress,
  VentureImage,
} from '../../models/venture';
import api from '../api';

interface IAppointmentOpportunitiesUnassignProps {
  appointmentId: string;
  propertyId?: string;
  ventureId?: string;
}

interface IAppointmentOpportunitiesUnassignDetailedLead extends LeadBase {
  media: MediaBase;
}

interface IAppointmentOpportunitiesUnassignDetailedProperty
  extends PropertyBase {
  address: PropertyAddress;
  images: PropertyImage[];
}

interface IAppointmentOpportunitiesUnassignDetailedVenture extends VentureBase {
  address: VentureAddress;
  images: VentureImage[];
}

export interface IAppointmentOpportunitiesUnassign extends AppointmentBase {
  lead: IAppointmentOpportunitiesUnassignDetailedLead[];
  user: UserBase[];
  properties: IAppointmentOpportunitiesUnassignDetailedProperty[];
  ventures: IAppointmentOpportunitiesUnassignDetailedVenture[];
  histories: AppointmentHistory[];
}

export const appointmentOpportunitiesUnassignService = async ({
  appointmentId,
  propertyId,
  ventureId,
}: IAppointmentOpportunitiesUnassignProps): Promise<IAppointmentOpportunitiesUnassign> => {
  const { data } = await api.patch<IAppointmentOpportunitiesUnassign>(
    `/appointments/${appointmentId}/opportunities-unassign`,
    { propertyId, ventureId },
  );

  return data;
};
