import api from '../api';
import {
  VentureBase,
  VentureAddress,
  VentureImage,
} from '../../models/venture';

export interface IDetailedVenture extends VentureBase {
  address: VentureAddress;
  images: VentureImage[];
}

export const showVenturesService = async (
  ventureId: string,
): Promise<IDetailedVenture> => {
  const { data } = await api.get<IDetailedVenture>(`/ventures/${ventureId}`);

  return data;
};
