import { AppointmentBase, AppointmentHistory } from '../../models/appointment';
import api from '../api';

interface IUpdateAppointmentStatusProps {
  appointmentId: string;
  description?: string;
  lot?: string;
  square?: string;
  status?:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
  tower?: string;
  unity?: string;
}

export interface IUpdatedAppointmentStatus extends AppointmentBase {
  histories: AppointmentHistory[];
}

export const updateAppointmentStatusService = async ({
  appointmentId,
  description,
  lot,
  square,
  status,
  tower,
  unity,
}: IUpdateAppointmentStatusProps): Promise<IUpdatedAppointmentStatus> => {
  const { data } = await api.patch<IUpdatedAppointmentStatus>(
    `/appointments/${appointmentId}/status`,
    { description, lot, square, status, tower, unity },
  );

  return data;
};
