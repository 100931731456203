import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { Input } from '../../../../../../components/Form/MaskedInput';
import { InternationalPhoneInput } from '../../../../../../components/Form/InternationalPhoneInput';

export type ILeadContactsModalFormData = {
  description?: string;
  email?: string;
  name: string;
  phone?: string;
};

interface ILeadContactsModalProps {
  isOpen: boolean;
  leadContact?: ILeadContactsModalFormData;
  onClose: () => void;
  onConfirm: (data: ILeadContactsModalFormData) => void;
}

const leadContactsFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  email: Yup.string()
    .email('Inválido')
    .nullable()
    .transform((value, originalValue) =>
      originalValue === '' ? null : value.toLowerCase(),
    ),
  name: Yup.string().required('Requerido'),
  phone: Yup.string()
    .when('email', {
      is: (val: string) => !val,
      then: Yup.string().required('Requerido'),
      otherwise: Yup.string(),
    })
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const LeadContactsModal = ({
  isOpen,
  leadContact,
  onClose,
  onConfirm,
}: ILeadContactsModalProps): JSX.Element => {
  const { register, formState, handleSubmit, reset, control } = useForm({
    resolver: yupResolver(leadContactsFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (leadContact) {
      reset(leadContact);
    } else {
      reset({});
    }
  }, [leadContact, reset]);

  const handleClose = useCallback(() => {
    reset({});
    onClose();
  }, [onClose, reset]);

  const handleLeadContactSubmit: SubmitHandler<ILeadContactsModalFormData> =
    useCallback(
      (leadContactData) => {
        reset({});
        onConfirm(leadContactData);
      },
      [onConfirm, reset],
    );

  return (
    <Modal size="lg" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleLeadContactSubmit)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Contato do lead
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            <Input label="Nome" error={errors.name} {...register('name')} />

            <Input
              type="email"
              label="E-mail"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              name="phone"
              label="Telefone"
              control={control}
              error={errors.phone}
            />

            <Input
              as="textarea"
              minHeight="100px"
              resize="none"
              py="2"
              label="Descrição"
              error={errors.description}
              {...register('description')}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>

            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Confirmar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
