import {
  Box,
  Heading,
  Divider,
  VStack,
  HStack,
  Flex,
  Button,
  useToast,
  Switch,
  FormLabel,
  ButtonGroup,
} from '@chakra-ui/react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { showMediasService } from '../../../../../services/Medias/ShowMediasService';
import { updateMediasService } from '../../../../../services/Medias/UpdateMediasService';

type UpdateMediaFormData = {
  isActive: boolean;
  name: string;
  visible: boolean;
};

interface ILocationState {
  mediaId?: string;
}

const mediaRegisterFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome requerido'),
});

export const MediaUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();

  const [updatingMedia, setUpdatingMedia] = useState<UpdateMediaFormData>();
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(mediaRegisterFormSchema),
    defaultValues: updatingMedia,
  });

  const { errors } = formState;
  const { mediaId } = state;

  useEffect(() => {
    async function loadMedia(medId: string): Promise<void> {
      try {
        const mediaDetails = await showMediasService(medId);

        setUpdatingMedia(mediaDetails);
        setIsActive(!!mediaDetails?.isActive);
        setIsVisible(!!mediaDetails?.visible);
        reset(mediaDetails);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados da mídia, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (mediaId) {
      loadMedia(mediaId);
    }
  }, [mediaId, reset, toast]);
  const handleUpdateMedia: SubmitHandler<UpdateMediaFormData> = useCallback(
    async ({ name }) => {
      if (mediaId) {
        try {
          await updateMediasService({
            mediaId,
            isActive,
            name,
            visible: isVisible,
          });

          toast({
            title: 'Editado com sucesso',
            description: 'O tipo de mídia foi editado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description:
                'Ocorreu um erro ao editar o tipo de mídia, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [goBack, isActive, isVisible, mediaId, toast],
  );

  const handleToggleIsActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked);
    },
    [],
  );

  const handleToggleIsVisible = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsVisible(event.target.checked);
    },
    [],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateMedia)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar tipo de mídia
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <VStack spacing="8">
          <Input label="Nome" error={errors.name} {...register('name')} />

          <HStack spacing="8">
            <Flex>
              <FormLabel htmlFor="isActive">Ativo</FormLabel>
              <Switch
                name="isActive"
                id="isActive"
                isChecked={isActive}
                onChange={handleToggleIsActive}
              />
            </Flex>

            <Flex>
              <FormLabel htmlFor="visible">Visível</FormLabel>
              <Switch
                name="visible"
                id="visible"
                isChecked={isVisible}
                onChange={handleToggleIsVisible}
              />
            </Flex>
          </HStack>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
