import api from '../api';
import { PropertyBase } from '../../models/property';
import { PropertyOwnerBase } from '../../models/propertyOwner';

export interface IDetailedPropertyOwner extends PropertyOwnerBase {
  properties: PropertyBase[];
}

export const showPropertyOwnersService = async (
  propertyOwnerId: string,
): Promise<IDetailedPropertyOwner> => {
  const { data } = await api.get<IDetailedPropertyOwner>(
    `/property-owners/${propertyOwnerId}`,
  );

  return data;
};
