import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '../../../../../../../components/Form/MaskedInput';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../../../components/Form/ReactSelect';
import {
  IUpdatedAppointmentStatus,
  updateAppointmentStatusService,
} from '../../../../../../../services/Appointments/UpdateAppointmentStatusService';
import { AppointmentHistory } from '../../../../../../../models/appointment';

interface IStatusModalFormData {
  status:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
  description?: string;
}

interface IChangeStatus extends IUpdatedAppointmentStatus {
  newStatus:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
  newHistory: AppointmentHistory;
}

interface IAppointmentStatusModalProps {
  appointmentId: string;
  isOpen: boolean;
  onClose: () => void;
  onChangeStatus: (statusData: IChangeStatus) => void;
}

const appointmentStatusModalFormSchema = Yup.object().shape({
  description: Yup.string()
    .when('status', {
      is: (status: string) => status === 'inactive',
      then: Yup.string().required('Requerido'),
    })
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  lot: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  square: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  status: Yup.string().required('Requerido'),
  tower: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  unity: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const AppointmentStatusModal = ({
  appointmentId,
  isOpen,
  onClose,
  onChangeStatus,
}: IAppointmentStatusModalProps): JSX.Element => {
  const toast = useToast();

  const { register, formState, handleSubmit, control, watch, reset, setValue } =
    useForm({
      resolver: yupResolver(appointmentStatusModalFormSchema),
    });

  const { errors } = formState;

  const handleAppointmentSubmit: SubmitHandler<IStatusModalFormData> =
    useCallback(
      async (appointmentFormData) => {
        try {
          const updatedAppointment = await updateAppointmentStatusService({
            appointmentId,
            ...appointmentFormData,
          });

          reset({});

          onChangeStatus({
            ...updatedAppointment,
            newStatus: updatedAppointment.status,
            newHistory: updatedAppointment.histories[0],
          });

          toast({
            title: 'Alterado com sucesso',
            description: 'O status foi alterado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          toast({
            title: 'Falha ao alterar',
            description: 'Ocorreu um erro ao alterar status, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      },
      [appointmentId, onChangeStatus, reset, toast],
    );

  const statusSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Aguardando atendimento', value: 'waiting' },
      { label: 'Pré atendimento', value: 'preService' },
      { label: 'Primeiro contato feito', value: 'contacted' },
      { label: 'Informações requisitadas', value: 'informationsRequested' },
      { label: 'Interesse manifestado', value: 'expressedInterest' },
      { label: 'Manter contato', value: 'ongoing' },
      { label: 'Visita agendada', value: 'scheduledVisit' },
      { label: 'Visita realizada', value: 'visited' },
      { label: 'Follow up', value: 'followUp' },
      { label: 'Proposta enviada', value: 'proposal' },
      { label: 'Em negociação', value: 'negotiation' },
      { label: 'Proposta assinada', value: 'deal' },
      { label: 'Inativo', value: 'inactive' },
    ],
    [],
  );

  const selectedStatus = watch('status');

  useEffect(() => {
    if (selectedStatus !== 'deal') {
      setValue('lot', null);
      setValue('square', null);
      setValue('tower', null);
      setValue('unity', null);
    }
  }, [reset, selectedStatus, setValue]);

  const handleCloseModal = useCallback(() => {
    onClose();
    reset({});
  }, [onClose, reset]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleAppointmentSubmit)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Alterar status
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="4">
            <ReactSelect
              name="status"
              control={control}
              label="Status"
              options={statusSelectOptions}
              error={errors.status}
            />

            {selectedStatus === 'deal' && (
              <>
                <Input
                  label="Unidade"
                  error={errors.unity}
                  {...register('unity')}
                />
                <SimpleGrid minChildWidth="80px" spacing="4" w="100%">
                  <Input
                    label="Quadra"
                    error={errors.square}
                    {...register('square')}
                  />

                  <Input label="Lote" error={errors.lot} {...register('lot')} />

                  <Input
                    label="Torre"
                    error={errors.tower}
                    {...register('tower')}
                  />
                </SimpleGrid>
              </>
            )}

            <Input
              as="textarea"
              minHeight="100px"
              resize="none"
              py="2"
              label="Descrição"
              error={errors.description}
              {...register('description')}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
