import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  Icon,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { TiDelete } from 'react-icons/ti';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ImageDropzone } from '../../../../../../components/Form/ImageDropzone';
import { DeleteConfirmationModal } from '../../../../../../components/DeleteConfirmationModal';
import { PropertyImage } from '../../../../../../models/property';
import { createPropertyImagesService } from '../../../../../../services/Properties/CreatePropertyImagesService';
import { updatePropertiesService } from '../../../../../../services/Properties/UpdatePropertiesService';
import { deletePropertyImagesService } from '../../../../../../services/Properties/DeletePropertyImagesService';

interface IHandleImageOrderProps {
  currentIndex: number;
  image: PropertyImage;
  newIndex: number;
}

interface IHandleDeleteImageProps {
  imageFilename: string;
  index: number;
}

interface IPropertyImagesProps {
  images: PropertyImage[];
  propertyId?: string;
}

export const PropertyImages = ({
  images,
  propertyId,
}: IPropertyImagesProps): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const [propertyImages, setPropertyImages] = useState(images);
  const [deleteImage, setDeleteImage] = useState<IHandleDeleteImageProps>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    setPropertyImages(images);
  }, [images]);

  const handleToggleDeleteConfirmationModal = useCallback(
    (data?: IHandleDeleteImageProps) => {
      setDeleteImage(data);
      setIsDeleteConfirmationModalVisible((prevState) => !prevState);
    },
    [],
  );

  const handleNewImage = useCallback(
    async (newImage: File) => {
      if (propertyId) {
        try {
          const formData = new FormData();

          formData.append('image', newImage);

          const propertyImage = await createPropertyImagesService({
            propertyId,
            imageData: formData,
          });

          setPropertyImages((prevState) => [...prevState, propertyImage]);
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha no upload',
              description:
                'Ocorreu um erro ao fazer upload da imagem, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [toast, propertyId],
  );

  const handleImageOrder = useCallback(
    ({ currentIndex, image, newIndex }: IHandleImageOrderProps) => {
      const cleanPropertyImages = propertyImages.filter(
        (_, index) => index !== currentIndex,
      );

      const updatedPropertyImages = [
        ...cleanPropertyImages.slice(0, newIndex),
        { ...image, showOrder: newIndex + 1 },
        ...cleanPropertyImages.slice(newIndex),
      ];

      if (newIndex < currentIndex) {
        const updatedPropertyImageOrders = updatedPropertyImages.map(
          (img, index) =>
            index > newIndex && index <= currentIndex
              ? { ...img, showOrder: img.showOrder + 1 }
              : img,
        );

        setPropertyImages(updatedPropertyImageOrders);
      } else {
        const updatedPropertyImageOrders = updatedPropertyImages.map(
          (img, index) =>
            index < newIndex && index >= currentIndex
              ? { ...img, showOrder: img.showOrder - 1 }
              : img,
        );

        setPropertyImages(updatedPropertyImageOrders);
      }
    },
    [propertyImages],
  );

  const handleCoverImage = useCallback((imageId: string) => {
    setPropertyImages((prevState) =>
      prevState.map((image) =>
        image.id === imageId
          ? { ...image, isCover: true }
          : { ...image, isCover: false },
      ),
    );
  }, []);

  const savePropertyImages = useCallback(async () => {
    if (propertyId) {
      try {
        await updatePropertiesService({ propertyId, images: propertyImages });

        toast({
          title: 'Salvo com sucesso',
          description: 'As imagens foram salvas corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/properties/details', { propertyId });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao salvar',
            description:
              'Ocorreu um erro ao salvar as imagens, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [propertyImages, propertyId, toast, push]);

  const handleDeleteImage = useCallback(async () => {
    if (!deleteImage) {
      return;
    }

    try {
      await deletePropertyImagesService(deleteImage.imageFilename);

      const cleanPropertyImages = propertyImages.filter(
        (file) => file.image !== deleteImage.imageFilename,
      );

      const updatedPropertyImageOrders = cleanPropertyImages.map((img, idx) =>
        idx >= deleteImage.index
          ? {
              ...img,
              showOrder: img.showOrder - 1,
            }
          : img,
      );

      const findCover = updatedPropertyImageOrders.findIndex(
        (image) => image.isCover,
      );

      if (findCover < 0 && updatedPropertyImageOrders.length) {
        updatedPropertyImageOrders[0].isCover = true;
      }

      setDeleteImage(undefined);
      handleToggleDeleteConfirmationModal();
      setPropertyImages(updatedPropertyImageOrders);

      toast({
        title: 'Excluído com sucesso',
        description: 'A imagem foi excluída corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description: 'Ocorreu um erro ao excluir a imagem, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [deleteImage, handleToggleDeleteConfirmationModal, toast, propertyImages]);

  return (
    <Box>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteImage}
      />

      <VStack spacing="8">
        <ImageDropzone onChange={handleNewImage} />

        <Divider borderColor="gray.300" />

        <Grid
          templateColumns={[
            null,
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
          ]}
          gap="4"
          width="100%"
        >
          {propertyImages.map((image, index) => (
            <Flex key={String(index)} justify="center">
              <Flex
                p="2"
                height="240px"
                width="320px"
                direction="column"
                borderRadius={8}
                justifyContent="space-between"
                bgImage={image.imageUrl}
                bgSize="cover"
                bgRepeat="no-repeat"
              >
                <Button
                  p="0"
                  ml="auto"
                  size="xs"
                  colorScheme="red"
                  onClick={() =>
                    handleToggleDeleteConfirmationModal({
                      imageFilename: image.image,
                      index,
                    })
                  }
                >
                  <Icon as={TiDelete} fontSize="md" />
                </Button>

                <ButtonGroup>
                  <Button
                    colorScheme={image.isCover ? 'green' : 'blackAlpha'}
                    flex={1}
                    fontWeight="normal"
                    onClick={() => handleCoverImage(image.id)}
                  >
                    Foto de capa
                  </Button>

                  <Select
                    bg="blue.500"
                    border={0}
                    color="white"
                    value={image.showOrder - 1}
                    width="20%"
                    onChange={(e) =>
                      handleImageOrder({
                        currentIndex: index,
                        image,
                        newIndex: Number(e.target.value),
                      })
                    }
                    _hover={{
                      filter: 'brightness(0.9)',
                      transition: 'filter 0.2s',
                    }}
                  >
                    {propertyImages.map((_, i) => (
                      <Text
                        key={String(i)}
                        color="gray.700"
                        value={i}
                        as="option"
                      >
                        {i + 1}
                      </Text>
                    ))}
                  </Select>
                </ButtonGroup>
              </Flex>
            </Flex>
          ))}
        </Grid>
      </VStack>

      <Flex mt="12" justify="flex-end">
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={goBack}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            isLoading={false}
            onClick={savePropertyImages}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
