import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Flex,
  Box,
  Button,
  Icon,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { RiEyeLine } from 'react-icons/ri';
import debounce from 'lodash.debounce';
import { Input } from '../../../../../../../../../components/Form/MaskedInput';
import { OpportunityTableRow } from './components/OpportunityTableRow';
import { maskMoney } from '../../../../../../../../../utils/formatters/handleMask';
import {
  IPropertyListItem,
  listPropertiesService,
} from '../../../../../../../../../services/Properties/ListPropertiesService';
import {
  IVentureListItem,
  listVenturesService,
} from '../../../../../../../../../services/Ventures/ListVenturesService';

interface IHandleSelectOpportunityIdProps {
  checked: boolean;
  propertyId?: string;
  ventureId?: string;
}
interface IProperty extends IPropertyListItem {
  formattedSellPrice: string;
}

interface IVenture extends IVentureListItem {
  formattedMinPrice: string;
}

interface IOpportunityTableProps {
  appointmentId: string;
  onChangeSelectedProperties: (selectedPropertiesId: string[]) => void;
  onChangeSelectedVentures: (selectedVenturesId: string[]) => void;
  selectedPropertiesId: string[];
  selectedVenturesId: string[];
}

export const OpportunityTable = ({
  appointmentId,
  onChangeSelectedProperties,
  onChangeSelectedVentures,
  selectedPropertiesId,
  selectedVenturesId,
}: IOpportunityTableProps): JSX.Element => {
  const toast = useToast();

  const [showVentures, setShowVentures] = useState(true);
  const [properties, setProperties] = useState<IProperty[]>([]);
  const [ventures, setVentures] = useState<IVenture[]>([]);

  const loadProperties = useCallback(
    async (title?: string) => {
      try {
        const propertiesList = await listPropertiesService({
          ignoreAssignedToAppointmentId: appointmentId,
          title,
        });

        const parsedProperties = propertiesList.items.map((item) => ({
          ...item,
          formattedSellPrice: item.sellPrice ? maskMoney(item.sellPrice) : '',
        }));

        setProperties(parsedProperties);
      } catch (err) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar dados dos imóveis, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    },
    [appointmentId, toast],
  );

  const loadVentures = useCallback(
    async (title?: string) => {
      try {
        const venturesList = await listVenturesService({
          ignoreAssignedToAppointmentId: appointmentId,
          title,
        });

        const parsedVenturesData = venturesList.items.map((item) => ({
          ...item,
          formattedMinPrice: item.minPrice ? maskMoney(item.minPrice) : '',
        }));
        setVentures(parsedVenturesData);
      } catch (err) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar dados dos empreendimentos, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    },
    [appointmentId, toast],
  );

  useEffect(() => {
    loadProperties();
    loadVentures();
  }, [loadProperties, loadVentures]);

  const debounceLoadProperties = debounce(loadProperties, 1000);
  const debounceLoadVentures = debounce(loadVentures, 1000);

  const handleLoadOpportunities = useCallback(
    (name?: string) => {
      if (showVentures) {
        debounceLoadVentures(name);
      } else {
        debounceLoadProperties(name);
      }
    },
    [debounceLoadProperties, debounceLoadVentures, showVentures],
  );

  const handleToggleSubject = useCallback(() => {
    setShowVentures((prevState) => !prevState);
  }, []);

  const handleSelectOpportunities = useCallback(
    ({ propertyId, ventureId, checked }: IHandleSelectOpportunityIdProps) => {
      if (propertyId) {
        if (!checked) {
          onChangeSelectedProperties(
            selectedPropertiesId.filter(
              (selectedPropertyId) => selectedPropertyId !== propertyId,
            ),
          );
        } else {
          onChangeSelectedProperties([...selectedPropertiesId, propertyId]);
        }
      }

      if (ventureId) {
        if (!checked) {
          onChangeSelectedVentures(
            selectedVenturesId.filter(
              (selectedVentureId) => selectedVentureId !== ventureId,
            ),
          );
        } else {
          onChangeSelectedVentures([...selectedVenturesId, ventureId]);
        }
      }
    },
    [
      onChangeSelectedProperties,
      onChangeSelectedVentures,
      selectedPropertiesId,
      selectedVenturesId,
    ],
  );

  return (
    <Box width="100%">
      <Flex align="flex-end">
        <Input
          label="Filtrar por nome"
          name="name"
          size="sm"
          onChange={(e) => handleLoadOpportunities(e.target.value)}
        />

        <ButtonGroup ml="4">
          <Button
            colorScheme="blue"
            disabled={showVentures}
            size="sm"
            leftIcon={<Icon as={RiEyeLine} fontSize="16" />}
            onClick={handleToggleSubject}
          >
            Ver empreendimentos
          </Button>

          <Button
            colorScheme="blue"
            disabled={!showVentures}
            size="sm"
            leftIcon={<Icon as={RiEyeLine} fontSize="16" />}
            onClick={handleToggleSubject}
          >
            Ver imóveis
          </Button>
        </ButtonGroup>
      </Flex>
      <Table colorScheme="blue">
        <Thead>
          <Tr>
            <Th px="6" w="8" />
            <Th>Título</Th>
            <Th>Endereço</Th>
            <Th>Valor</Th>
          </Tr>
        </Thead>
        <Tbody>
          {showVentures ? (
            <OpportunityTableRow
              opportunities={ventures}
              selectedOpportunitiesId={selectedVenturesId}
              onToggleSelectOpportunity={({ opportunityId, checked }) =>
                handleSelectOpportunities({
                  ventureId: opportunityId,
                  checked,
                })
              }
            />
          ) : (
            <OpportunityTableRow
              opportunities={properties}
              selectedOpportunitiesId={selectedPropertiesId}
              onToggleSelectOpportunity={({ opportunityId, checked }) =>
                handleSelectOpportunities({
                  propertyId: opportunityId,
                  checked,
                })
              }
            />
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
