import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  SimpleGrid,
  Text,
  Switch,
  VStack,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { Input } from '../../../../../../components/Form/MaskedInput';
import { DatePicker } from '../../../../../../components/Form/DatePicker';
import {
  unmaskNumber,
  maskMoney,
  maskArea,
} from '../../../../../../utils/formatters/handleMask';
import { AsyncSelect } from '../../../../../../components/Form/AsyncSelect';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../../components/Form/ReactSelect';
import { LinkButton } from '../../../../../../components/LinkButton';
import { listPropertyOwnersService } from '../../../../../../services/PropertyOwners/ListPropertyOwnersService';
import { listVenturesService } from '../../../../../../services/Ventures/ListVenturesService';
import { IDetailedProperty } from '../../../../../../services/Properties/ShowPropertiesService';

type PropertyOwner = {
  id: string;
  name: string;
};

type Venture = {
  id: string;
  title: string;
};

export interface IPropertyFormData
  extends Omit<IDetailedProperty, 'propertyOwner' | 'venture'> {
  propertyOwner?: PropertyOwner;
  selectedPropertyOwner: SelectOption;
  selectedVenture: SelectOption;
  venture?: Venture;
}

interface IPropertyProps {
  onSubmit: (propertyData: IPropertyFormData) => void;
  property?: Omit<
    IPropertyFormData,
    'selectedPropertyOwner' | 'selectedVenture'
  >;
}

const propertyRegisterFormSchema = Yup.object().shape({
  bathrooms: Yup.number()
    .integer()
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  buildings: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  businessType: Yup.string().nullable().required('Requerido'),
  commercialStatus: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  condominiumName: Yup.string().required('Requerido'),
  condominiumPrice: Yup.number()
    .typeError('Inválido')
    .positive('Inválido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  constructionYear: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  deliveryDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  dormitories: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  keyHolder: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  monthlyUrbanTax: Yup.number()
    .typeError('Inválido')
    .positive('Inválido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  negotiationDetails: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  propertyCategory: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  propertyLevel: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  propertyReg: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ref: Yup.string().required('Requerido'),
  regionStandardLevel: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  rentPrice: Yup.number()
    .typeError('Inválido')
    .positive('Inválido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  rooms: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  stage: Yup.string().required('Requerido'),
  selectedPropertyOwner: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().uuid(),
  }),
  selectedVenture: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().uuid(),
  }),
  sellPrice: Yup.number()
    .typeError('Inválido')
    .positive('Inválido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  suites: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  title: Yup.string().required('Requerido'),
  topography: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  totalArea: Yup.number()
    .typeError('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  usefulArea: Yup.number()
    .typeError('Inválido')
    .positive('Inválido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  vacancies: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .positive('Insira um número válido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  videoUrl: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  virtualTourUrl: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const Property = ({
  onSubmit,
  property,
}: IPropertyProps): JSX.Element => {
  const { goBack } = useHistory();

  const [isActive, setIsActive] = useState(true);
  const [isExclusive, setIsExclusive] = useState(false);
  const [isFurnished, setIsFurnished] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [isShared, setIsShared] = useState(false);
  const [acceptFunding, setAcceptFunding] = useState(false);
  const [acceptExchange, setAcceptExchange] = useState(false);

  const { control, register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(propertyRegisterFormSchema),
  });

  const { errors } = formState;

  const handleToggleAcceptFunding = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAcceptFunding(event.target.checked);
    },
    [],
  );

  const handleToggleIsActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked);
    },
    [],
  );

  const handleToggleIsExclusive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsExclusive(event.target.checked);
    },
    [],
  );

  const handleToggleIsFurnished = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsFurnished(event.target.checked);
    },
    [],
  );

  const handleToggleIsPublished = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsPublished(event.target.checked);
    },
    [],
  );

  const handleToggleIsShared = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsShared(event.target.checked);
    },
    [],
  );

  const handleToggleAcceptExchange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAcceptExchange(event.target.checked);
    },
    [],
  );

  const loadPropertyOwner = useCallback(
    async (name?: string): Promise<PropertyOwner[]> => {
      const { items } = await listPropertyOwnersService({ name });

      return items;
    },
    [],
  );

  const handleLoadPropertyOwnerSelectOption = useCallback(
    async (name?: string): Promise<SelectOption[]> => {
      const propertyOwners = await loadPropertyOwner(name);

      const parsedPropertyOwnersSelectOption = propertyOwners.map(
        (propertyOnwer) => ({
          label: propertyOnwer.name,
          value: propertyOnwer.id,
        }),
      );

      return parsedPropertyOwnersSelectOption;
    },
    [loadPropertyOwner],
  );

  const loadVenture = useCallback(
    async (title?: string): Promise<Venture[]> => {
      const { items } = await listVenturesService({ title });

      return items;
    },
    [],
  );

  const handleLoadVentureSelectOption = useCallback(
    async (title?: string): Promise<SelectOption[]> => {
      const ventures = await loadVenture(title);

      const parsedVenturesSelectOption = ventures.map((venture) => ({
        label: venture.title,
        value: venture.id,
      }));

      return parsedVenturesSelectOption;
    },
    [loadVenture],
  );

  useEffect(() => {
    if (property) {
      reset({
        ...property,
        condominiumPrice:
          property.condominiumPrice && maskMoney(property.condominiumPrice),
        deliveryDate: property.deliveryDate && new Date(property.deliveryDate),
        monthlyUrbanTax:
          property.monthlyUrbanTax && maskMoney(property.monthlyUrbanTax),
        rentPrice: property.rentPrice && maskMoney(property.rentPrice),
        sellPrice: property.sellPrice && maskMoney(property.sellPrice),
        totalArea: property.totalArea && maskArea(property.totalArea),
        usefulArea: property.usefulArea && maskArea(property.usefulArea),
        selectedPropertyOwner: {
          value: property.propertyOwner?.id,
          label: property.propertyOwner?.name,
        },
        selectedVenture: {
          value: property.venture?.id,
          label: property.venture?.title,
        },
      });
      setIsActive(!!property.isActive);
      setIsExclusive(!!property.isExclusive);
      setIsFurnished(!!property.isFurnished);
      setIsPublished(!!property.isPublished);
      setIsShared(!!property.isShared);
      setAcceptFunding(!!property.acceptFunding);
      setAcceptExchange(!!property.acceptExchange);
    }
  }, [reset, property]);

  const businessTypeSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Locação', value: 'rent' },
      { label: 'Venda', value: 'sell' },
      { label: 'Venda e locação', value: 'sellAndRent' },
    ],
    [],
  );

  const commercialStatusSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Futuro lançamento', value: 'futureRelease' },
      { label: 'Lançamento', value: 'release' },
      { label: 'Padrão', value: 'default' },
      { label: 'Pré-lançamento', value: 'preRelease' },
      { label: 'Pronto para morar', value: 'ready' },
      { label: 'Revenda', value: 'resale' },
      { label: 'Últimas unidades', value: 'lastUnits' },
    ],
    [],
  );

  const keyHolderSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Corretor', value: 'user' },
      { label: 'Imobiliária', value: 'realEstate' },
      { label: 'Porteiro', value: 'concierge' },
      { label: 'Proprietário', value: 'propertyOwner' },
    ],
    [],
  );

  const propertyCategorySelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Apartamento', value: 'apartment' },
      { label: 'BoxGaragem', value: 'garageBox' },
      { label: 'Casa', value: 'house' },
      { label: 'Casa de madeira', value: 'woodhouse' },
      { label: 'Chácara', value: 'farm' },
      { label: 'Cobertura', value: 'penthouse' },
      { label: 'Cobertura Duplex', value: 'duplexPenthouse' },
      { label: 'Duplex', value: 'duplex' },
      { label: 'Padrão', value: 'default' },
      { label: 'Fazenda', value: 'farmhouse' },
      { label: 'Sala Comercial', value: 'commercialRoom' },
      { label: 'Sitio', value: 'smallFarm' },
      { label: 'Sobrado', value: 'twoStoryHouse' },
      { label: 'Studio', value: 'studio' },
      { label: 'Triplex', value: 'triplex' },
    ],
    [],
  );

  const propertyLevelSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Alto', value: 'high' },
      { label: 'Médio', value: 'medium' },
      { label: 'Regular', value: 'regular' },
      { label: 'Baixo', value: 'low' },
    ],
    [],
  );

  const regionStandardLevelSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Privilegiado', value: 'privileged' },
      { label: 'Muito bom', value: 'veryGood' },
      { label: 'Bom', value: 'good' },
      { label: 'Médio', value: 'medium' },
      { label: 'Regular', value: 'regular' },
    ],
    [],
  );

  const topographySelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Declive', value: 'downhill' },
      { label: 'Plano', value: 'plain' },
      { label: 'Aclive', value: 'uphill' },
    ],
    [],
  );

  const stageSelectOptions = useMemo<SelectOption[]>(
    () => [
      { label: 'Em construção', value: 'underConstruction' },
      { label: 'Pronto', value: 'done' },
    ],
    [],
  );

  const handleNewProperty: SubmitHandler<IPropertyFormData> = useCallback(
    (propertyData) => {
      const { selectedPropertyOwner, selectedVenture, ...rest } = propertyData;

      onSubmit({
        ...rest,
        acceptExchange,
        acceptFunding,
        isActive,
        isExclusive,
        isFurnished,
        isPublished,
        isShared,
        propertyOwnerId: selectedPropertyOwner.value,
        propertyOwner: {
          id: selectedPropertyOwner.value,
          name: selectedPropertyOwner.label,
        },
        selectedPropertyOwner,
        selectedVenture,
        ventureId: selectedVenture.value,
        venture: {
          id: selectedVenture.value,
          title: selectedVenture.label,
        },
      });
    },
    [
      onSubmit,
      acceptExchange,
      acceptFunding,
      isActive,
      isExclusive,
      isFurnished,
      isPublished,
      isShared,
    ],
  );

  return (
    <Box as="form" onSubmit={handleSubmit(handleNewProperty)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <Input label="Título" error={errors.title} {...register('title')} />

          <Input
            label="Nome do condomínio"
            error={errors.condominiumName}
            {...register('condominiumName')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <Input label="Código" error={errors.ref} {...register('ref')} />

            <Input
              label="Matrícula"
              error={errors.propertyReg}
              {...register('propertyReg')}
            />
          </HStack>

          <HStack spacing="4" align="flex-end">
            <AsyncSelect
              label="Proprietário"
              name="selectedPropertyOwner"
              loadOptions={handleLoadPropertyOwnerSelectOption}
              control={control}
              error={errors.selectedPropertyOwner}
            />

            <LinkButton
              size="lg"
              colorScheme="green"
              fontSize="md"
              to="/property-owners/register"
            >
              <Icon as={RiAddLine} fontSize="24" />
            </LinkButton>
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <AsyncSelect
            label="Empreendimento"
            name="selectedVenture"
            loadOptions={handleLoadVentureSelectOption}
            control={control}
            error={errors.selectedVenture}
          />

          <ReactSelect
            label="Categoria do Imóvel"
            name="propertyCategory"
            control={control}
            error={errors.propertyCategory}
            options={propertyCategorySelectOptions}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <ReactSelect
              label="Padrão de imóvel"
              name="propertyLevel"
              control={control}
              error={errors.propertyLevel}
              options={propertyLevelSelectOptions}
            />

            <ReactSelect
              label="Padrão de local"
              name="regionStandardLevel"
              control={control}
              error={errors.regionStandardLevel}
              options={regionStandardLevelSelectOptions}
            />
          </HStack>
          <HStack spacing="4" align="flex-end">
            <ReactSelect
              name="businessType"
              control={control}
              label="Tipo de negócio"
              options={businessTypeSelectOptions}
              error={errors.businessType}
            />

            <ReactSelect
              label="Status comercial"
              name="commercialStatus"
              control={control}
              error={errors.commercialStatus}
              options={commercialStatusSelectOptions}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <ReactSelect
              name="stage"
              control={control}
              label="Fase"
              options={stageSelectOptions}
              error={errors.stage}
            />

            <DatePicker
              label="Data de entrega"
              control={control}
              error={errors.deliveryDate}
              minDate={new Date()}
              {...register('deliveryDate')}
            />
          </HStack>

          <HStack spacing="4" align="flex-end">
            <Input
              type="number"
              maxLength={4}
              label="Ano de construção"
              error={errors.constructionYear}
              {...register('constructionYear')}
            />

            <ReactSelect
              label="Topografia"
              name="topography"
              control={control}
              error={errors.topography}
              options={topographySelectOptions}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="340px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <Input
              label="Nº de blocos/torres"
              type="number"
              error={errors.buildings}
              {...register('buildings')}
            />

            <Input
              label="Área útil"
              mask="area"
              error={errors.usefulArea}
              {...register('usefulArea')}
            />

            <Input
              label="Área total"
              mask="area"
              error={errors.totalArea}
              {...register('totalArea')}
            />
          </HStack>

          <HStack spacing="4" align="flex-end">
            <Input
              type="number"
              label="Salas"
              error={errors.rooms}
              {...register('rooms')}
            />

            <Input
              type="number"
              label="Dormitórios"
              error={errors.dormitories}
              {...register('dormitories')}
            />

            <Input
              type="number"
              label="Suítes"
              error={errors.suites}
              {...register('suites')}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4" align="flex-end">
            <Input
              type="number"
              label="Banheiros"
              error={errors.bathrooms}
              {...register('bathrooms')}
            />

            <Input
              type="number"
              label="Vagas"
              error={errors.vacancies}
              {...register('vacancies')}
            />
          </HStack>

          <HStack spacing="4" align="flex-end">
            <Input
              label="Preço de venda"
              error={errors.sellPrice}
              mask="money"
              {...register('sellPrice')}
            />

            <Input
              label="Preço de locação"
              error={errors.rentPrice}
              mask="money"
              {...register('rentPrice')}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid
          minChildWidth="240px"
          spacing="8"
          alignItems="flex-end"
          w="100%"
        >
          <HStack spacing="4" align="flex-end">
            <Input
              label="Preço de condomínio"
              error={errors.condominiumPrice}
              mask="money"
              {...register('condominiumPrice')}
            />

            <Input
              label="IPTU mensal"
              error={errors.monthlyUrbanTax}
              mask="money"
              {...register('monthlyUrbanTax')}
            />
          </HStack>

          <ReactSelect
            label="Local da chave"
            name="keyHolder"
            control={control}
            error={errors.keyHolder}
            options={keyHolderSelectOptions}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <Input
            label="Vídeo link"
            error={errors.videoUrl}
            {...register('videoUrl')}
          />

          <Input
            label="Tour virtual link"
            error={errors.virtualTourUrl}
            {...register('virtualTourUrl')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <Input
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Descrição"
            error={errors.description}
            {...register('description')}
          />

          <Input
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Detalhes da negociação"
            error={errors.negotiationDetails}
            {...register('negotiationDetails')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <Flex>
            <Switch
              isChecked={isFurnished}
              onChange={handleToggleIsFurnished}
            />

            <Text ml="2">Mobiliado</Text>
          </Flex>

          <Flex>
            <Switch
              isChecked={isExclusive}
              onChange={handleToggleIsExclusive}
            />

            <Text ml="2">Exclusivo</Text>
          </Flex>

          <Flex>
            <Switch isChecked={isShared} onChange={handleToggleIsShared} />

            <Text ml="2">Compartilhado</Text>
          </Flex>

          <Flex>
            <Switch
              isChecked={acceptExchange}
              onChange={handleToggleAcceptExchange}
            />

            <Text ml="2">Aceita permuta</Text>
          </Flex>

          <Flex>
            <Switch
              isChecked={acceptFunding}
              onChange={handleToggleAcceptFunding}
            />

            <Text ml="2">Aceita financiamento</Text>
          </Flex>

          <Flex>
            <Switch
              label="Ativo"
              isChecked={isActive}
              onChange={handleToggleIsActive}
            />

            <Text ml="2">Ativo</Text>
          </Flex>

          <Flex>
            <Switch
              isChecked={isPublished}
              onChange={handleToggleIsPublished}
            />

            <Text ml="2">Publicado</Text>
          </Flex>
        </SimpleGrid>
      </VStack>

      <Flex mt="12" justify="flex-end">
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={goBack}>
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme="green"
            isLoading={formState.isSubmitting}
          >
            Preencher endereço
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
