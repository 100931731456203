import api from '../api';
import { PropertyBase } from '../../models/property';
import { PropertyOwnerBase } from '../../models/propertyOwner';

interface IUpdatePropertyOwnerProps {
  propertyOwnerId: string;
  description?: string;
  email: string;
  idCard?: string;
  mainPhone: string;
  name: string;
  secondaryPhone?: string;
  taxPayerId: string;
}

export interface IUpdatedPropertyOwner extends PropertyOwnerBase {
  properties: PropertyBase[];
}

export const updatePropertyOwnersService = async ({
  propertyOwnerId,
  description,
  email,
  idCard,
  mainPhone,
  name,
  secondaryPhone,
  taxPayerId,
}: IUpdatePropertyOwnerProps): Promise<IUpdatedPropertyOwner> => {
  const { data } = await api.patch<IUpdatedPropertyOwner>(
    `/property-owners/${propertyOwnerId}`,
    {
      description,
      email,
      idCard,
      mainPhone,
      name,
      secondaryPhone,
      taxPayerId,
    },
  );

  return data;
};
