import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Input as ChakraInput,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { ReactMultiSelect } from '../../../../../components/Form/ReactMultiSelect';
import { useAuth } from '../../../../../hooks/auth';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { showUsersService } from '../../../../../services/Users/ShowUsersService';
import { listUserRolesService } from '../../../../../services/Users/ListUserRolesService';
import { updateUsersService } from '../../../../../services/Users/UpdateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { UserRoleBase } from '../../../../../models/userRole';
import { deleteUserAvatarsService } from '../../../../../services/Users/DeleteUserAvatarsService';
import { linkPasswordsService } from '../../../../../services/Auth/ResetPasswordLinksService';

type SelectOptions = {
  label: string;
  value: string;
};

type UpdateUserFormData = {
  name: string;
  email: string;
  bio?: string;
  creci?: string;
  phone?: string;
  password?: string;
  passwordConfirmation?: string;
  userRoles: UserRoleBase[];
};

interface ILocationState {
  userId?: string;
}

const userUpdateFormSchema = Yup.object().shape({
  name: Yup.string().required('Requerido'),
  email: Yup.string()
    .email('Inválido')
    .required('Requerido')
    .transform((value) => value.toLowerCase()),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  creci: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  phone: Yup.string()
    .nullable()
    .test('phoneLength', 'Inválido', (value) =>
      value ? value.length > 9 : true,
    )
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  password: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  passwordConfirmation: Yup.string()
    .oneOf([null, Yup.ref('password')], 'Senhas não coincidem')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  userRoles: Yup.array()
    .min(1)
    .of(Yup.object())
    .required('Requerido')
    .transform((_, originalValue) =>
      originalValue.map((userRole: { value: string }) => ({
        id: userRole.value,
      })),
    ),
});

export const UserUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();
  const { updateUser, user } = useAuth();

  const [passwordResetUrl, setPasswordResetUrl] = useState<string>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [userRolesSelectOptions, setUserRolesSelectOptions] = useState<
    SelectOptions[]
  >([]);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const { register, handleSubmit, formState, reset, control, getValues } =
    useForm({
      resolver: yupResolver(userUpdateFormSchema),
    });

  const { errors } = formState;
  const { userId } = state;

  useEffect(() => {
    async function loadUser(showUserId: string): Promise<void> {
      try {
        const userDetails = await showUsersService(showUserId);

        setAvatarUrl(userDetails.avatarUrl || undefined);

        const parsedUserRoles = userDetails.userRoles.map((role) => ({
          label: role.name,
          value: role.id,
        }));

        reset({
          name: userDetails.name,
          email: userDetails.email,
          bio: userDetails.bio,
          creci: userDetails.creci,
          phone: userDetails.phone,
          userRoles: parsedUserRoles,
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    async function loadUserRoles(): Promise<void> {
      try {
        const userRolesList = await listUserRolesService();

        setUserRolesSelectOptions(
          userRolesList.map((role) => ({
            label: role.name,
            value: role.id,
          })),
        );
      } catch (err) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar os dados dos cargos de usuário, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }

    loadUserRoles();
    if (userId) {
      loadUser(userId);
    }
  }, [userId, reset, toast]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    if (userId) {
      await deleteUserAvatarsService(userId);

      setAvatar(undefined);
      setAvatarUrl(undefined);
      handleToggleDeleteConfirmationModal();

      if (userId === user.id) {
        delete user.avatar;
        delete user.avatarUrl;

        updateUser(user);
      }
    }
  }, [userId, handleToggleDeleteConfirmationModal, updateUser, user]);

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = useCallback(
    async (userData) => {
      if (userId) {
        try {
          const updatedUser = await updateUsersService({ userId, ...userData });

          if (avatar) {
            const formData = new FormData();

            formData.append('avatar', avatar);

            const userWithUpdatedAvatar = await updateUserAvatarsService({
              userId,
              avatarData: formData,
            });

            if (userWithUpdatedAvatar.id === user.id) {
              updateUser(userWithUpdatedAvatar);
            }
          } else if (updatedUser.id === user.id) {
            updateUser(updatedUser);
          }

          toast({
            title: 'Editado com sucesso',
            description: 'O usuário foi editado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description:
                'Ocorreu um erro ao editar o usuário, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [avatar, userId, goBack, toast, updateUser, user.id],
  );

  const handleGeneratePasswordResetUrl = useCallback(async () => {
    const link = await linkPasswordsService(getValues('email'));

    setPasswordResetUrl(link);
  }, [getValues]);

  const handlePasswordResetUrlCopy = useCallback(
    async (resetPasswordUrl: string) => {
      await navigator.clipboard.writeText(resetPasswordUrl);

      setPasswordResetUrl('');
    },
    [],
  );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateUser)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar usuário
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" w="80%">
            <ReactMultiSelect
              name="userRoles"
              label="Cargos"
              control={control}
              error={errors.userRoles}
              options={userRolesSelectOptions}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="Nome completo"
              error={errors.name}
              {...register('name')}
            />
            <Input
              type="email"
              label="E-mail"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="CRECI"
              mask="creci"
              error={errors.creci}
              {...register('creci')}
            />
            <InternationalPhoneInput
              name="phone"
              label="Telefone"
              control={control}
              error={errors.phone}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              label="Biografia"
              error={errors.bio}
              {...register('bio')}
            />

            <VStack spacing="8">
              <FormControl>
                <FormLabel htmlFor="reset-password-url">
                  Link alterar senha
                </FormLabel>

                <InputGroup size="lg">
                  <ChakraInput
                    name="reset-password-url"
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    variant="outline"
                    size="lg"
                    disabled
                    value={passwordResetUrl}
                  />
                  {!passwordResetUrl && (
                    <InputRightAddon>
                      <Button
                        fontWeight="normal"
                        onClick={handleGeneratePasswordResetUrl}
                      >
                        Gerar Url
                      </Button>
                    </InputRightAddon>
                  )}

                  {passwordResetUrl && (
                    <InputRightAddon>
                      <Button
                        fontWeight="normal"
                        onClick={() =>
                          handlePasswordResetUrlCopy(passwordResetUrl)
                        }
                      >
                        Copiar
                      </Button>
                    </InputRightAddon>
                  )}
                </InputGroup>
              </FormControl>
              {/* <Input
                type="password"
                label="Senha"
                error={errors.password}
                {...register('password')}
              />
              <Input
                type="password"
                label="Confirmação de senha"
                error={errors.passwordConfirmation}
                {...register('passwordConfirmation')}
              /> */}
            </VStack>
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
