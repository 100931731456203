import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import { Input } from '../../../../../../components/Form/MaskedInput';
import 'react-datepicker/dist/react-datepicker.css';

export type PropertyAddressFormData = {
  city: string;
  complement?: string;
  neighborhood?: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
};

interface IPropertyAddressProps {
  address?: PropertyAddressFormData;
  onSubmit: (addressData: PropertyAddressFormData) => void;
}

const propertyRegisterFormSchema = Yup.object().shape({
  city: Yup.string().required('Requerido'),
  complement: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  neighborhood: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  number: Yup.string().required('Requerido'),
  state: Yup.string().required('Requerido'),
  street: Yup.string().required('Requerido'),
  zipCode: Yup.string().required('Requerido'),
});

export const PropertyAddress = ({
  address,
  onSubmit,
}: IPropertyAddressProps): JSX.Element => {
  const { goBack } = useHistory();

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(propertyRegisterFormSchema),
  });

  useEffect(() => {
    reset(address);
  }, [reset, address]);

  const { errors } = formState;

  const handleNewProperty: SubmitHandler<PropertyAddressFormData> = useCallback(
    (addressData) => {
      onSubmit(addressData);
    },
    [onSubmit],
  );

  return (
    <Box as="form" onSubmit={handleSubmit(handleNewProperty)}>
      <VStack spacing="8">
        <Grid templateColumns="repeat(6, 1fr)" gap="4">
          <GridItem colSpan={5}>
            <Input
              label="Nome da rua"
              error={errors.street}
              {...register('street')}
            />
          </GridItem>

          <Input label="Número" error={errors.number} {...register('number')} />
        </Grid>

        <SimpleGrid minChildWidth="160px" spacing="4" w="100%">
          <Input
            label="Bairro"
            error={errors.neighborhood}
            {...register('neighborhood')}
          />

          <Input
            label="Complemento"
            error={errors.complement}
            {...register('complement')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="210px" spacingY="8" spacingX="4" w="100%">
          <Input label="Cidade" error={errors.city} {...register('city')} />

          <Input label="Estado" error={errors.state} {...register('state')} />

          <Input
            label="Cep"
            mask="cep"
            error={errors.zipCode}
            {...register('zipCode')}
          />
        </SimpleGrid>
      </VStack>

      <Flex mt="12" justify="flex-end">
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={goBack}>
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme="green"
            isLoading={formState.isSubmitting}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
