import api from '../api';
import {
  PropertyBase,
  PropertyAddress,
  PropertyImage,
} from '../../models/property';
import { PropertyOwnerBase } from '../../models/propertyOwner';
import { VentureBase } from '../../models/venture';

export interface IDetailedProperty extends PropertyBase {
  address: PropertyAddress;
  images: PropertyImage[];
  propertyOwner?: PropertyOwnerBase;
  venture?: VentureBase;
}

export const showPropertiesService = async (
  propertyId: string,
): Promise<IDetailedProperty> => {
  const { data } = await api.get<IDetailedProperty>(
    `/properties/${propertyId}`,
  );

  return data;
};
