import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  SimpleGrid,
  Divider,
  useToast,
  ButtonGroup,
  VStack,
  Image,
  HStack,
} from '@chakra-ui/react';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
} from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';

import venturePlaceholder from '../../../../../assets/venture_placeholder.png';
import { LinkButton } from '../../../../../components/LinkButton';
import {
  maskDate,
  maskMoney,
} from '../../../../../utils/formatters/handleMask';
import {
  IDetailedVenture,
  showVenturesService,
} from '../../../../../services/Ventures/ShowVenturesService';
import { deleteVenturesService } from '../../../../../services/Ventures/DeleteVenturesService';
import { useAuth } from '../../../../../hooks/auth';

enum BusinessType {
  rent = 'Locação',
  sell = 'Venda',
  sellAndRent = 'Venda e Locação',
}

enum Stage {
  done = 'Pronto',
  underConstruction = 'Em construção',
}

interface IVenture extends IDetailedVenture {
  formattedCreatedAt: string;
  formattedDeliveryDate: string;
  formattedMaxPrice: string;
  formattedMinPrice?: string;
}

interface ILocationState {
  ventureId?: string;
}

export const VentureDetails = (): JSX.Element => {
  const { user } = useAuth();
  const toast = useToast();
  const {
    state: { ventureId },
  } = useLocation<ILocationState>();
  const { push } = useHistory();

  const [venture, setVenture] = useState<IVenture>();
  const [previewIndex, setPeviewIndex] = useState(0);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadVenture(showVentureId: string): Promise<void> {
      try {
        const ventureDetails = await showVenturesService(showVentureId);

        const parsedVenture = {
          ...ventureDetails,
          formattedCreatedAt: maskDate(ventureDetails.createdAt),
          formattedDeliveryDate: ventureDetails.deliveryDate
            ? maskDate(ventureDetails.deliveryDate)
            : '-',
          formattedMaxPrice: ventureDetails.maxPrice
            ? maskMoney(ventureDetails.maxPrice)
            : '-',
          formattedMinPrice: ventureDetails.minPrice
            ? maskMoney(ventureDetails.minPrice)
            : '-',
        };

        setVenture(parsedVenture);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do emprendimento, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (ventureId) {
      loadVenture(ventureId);
    }
  }, [ventureId, toast]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteVenture = useCallback(async () => {
    if (ventureId) {
      try {
        await deleteVenturesService(ventureId);

        toast({
          title: 'Excluído com sucesso',
          description: 'O empreendimento foi excluído corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/ventures');
      } catch (err) {
        toast({
          title: 'Falha ao excluir',
          description:
            'Ocorreu um erro ao excluir o empreendimento, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [ventureId, push, toast]);

  const handleChangePreview = useCallback(
    (amount: number) => {
      if (
        !venture?.images.length ||
        amount < 0 ||
        amount >= venture.images.length
      ) {
        return;
      }

      setPeviewIndex(amount);
    },
    [venture?.images.length],
  );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteVenture}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do empreendimento
          </Heading>

          {user.isAdmin && (
            <ButtonGroup>
              <LinkButton
                colorScheme="yellow"
                color="white"
                icon={<Icon as={RiEditLine} fontSize="16" />}
                to={{
                  pathname: '/ventures/update',
                  state: {
                    ventureId,
                  },
                }}
              >
                Editar
              </LinkButton>

              <Button
                size="sm"
                fontSize="sm"
                colorScheme="red"
                onClick={handleToggleDeleteConfirmationModal}
                leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
              >
                Excluir
              </Button>
            </ButtonGroup>
          )}
        </Flex>

        {venture && (
          <Flex direction="column">
            <Flex align="center" justify="center" px="8">
              <Button
                colorScheme="blackAlpha"
                h="12"
                w="12"
                mr="8"
                borderRadius="50%"
                display="flex"
                p="2"
                onClick={() => handleChangePreview(previewIndex - 1)}
              >
                <Icon fontSize="xx-large" color="white" as={RiArrowLeftSLine} />
              </Button>

              <Box maxH="480px" minH="240px" minW="384px">
                <Image
                  borderRadius="8"
                  maxH="480px"
                  srcSet={
                    venture.images.length
                      ? venture.images[previewIndex].imageUrl
                      : venturePlaceholder
                  }
                />
              </Box>

              <Button
                colorScheme="blackAlpha"
                h="12"
                w="12"
                ml="8"
                borderRadius="50%"
                display="flex"
                p="2"
                onClick={() => handleChangePreview(previewIndex + 1)}
              >
                <Icon
                  fontSize="xx-large"
                  color="white"
                  as={RiArrowRightSLine}
                />
              </Button>
            </Flex>

            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {venture.title}
              </Text>
              <Text color="gray.500">{venture.condominiumName}</Text>
            </Flex>

            <HStack justify="center" spacing="8" mt="8">
              <Flex align="center">
                <Icon
                  as={
                    venture.isActive
                      ? RiCheckboxCircleLine
                      : RiCheckboxBlankCircleLine
                  }
                  fontSize="xx-large"
                  color={venture.isActive ? 'green' : 'gray.400'}
                />
                <Text as="span" fontSize="xl" color="gray.500" ml="2">
                  Ativo
                </Text>
              </Flex>

              <Flex align="center">
                <Icon
                  as={
                    venture.isPublished
                      ? RiCheckboxCircleLine
                      : RiCheckboxBlankCircleLine
                  }
                  fontSize="xx-large"
                  color={venture.isPublished ? 'green' : 'gray.400'}
                />
                <Text as="span" fontSize="xl" color="gray.500" ml="2">
                  Publicado
                </Text>
              </Flex>
            </HStack>

            <Divider my="6" borderColor="gray.300" />

            <VStack spacing="4">
              <SimpleGrid
                minChildWidth="315px"
                spacingX="8"
                spacingY="4"
                px="8"
                w="100%"
              >
                <Text>
                  Disponível para:
                  <Text as="span" color="blue.500" ml="2">
                    {BusinessType[venture.businessType] || '-'}
                  </Text>
                </Text>

                <Text>
                  Valor:
                  <Text as="span" color="blue.500" ml="2">
                    {`De ${venture.formattedMinPrice} até ${venture.formattedMaxPrice}`}
                  </Text>
                </Text>

                <Text>
                  Data de entrega:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.formattedDeliveryDate}
                  </Text>
                </Text>

                <Text>
                  Código:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.ref || '-'}
                  </Text>
                </Text>

                <Text>
                  Incorporadora:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.developerReg || '-'}
                  </Text>
                </Text>

                <Text>
                  Ref. anterior:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.previousRef || '-'}
                  </Text>
                </Text>

                <Text>
                  Blocos:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.buildings || '-'}
                  </Text>
                </Text>

                <Text>
                  Andares:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.floors || '-'}
                  </Text>
                </Text>

                <Text>
                  Unidades por andar:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.floorUnits || '-'}
                  </Text>
                </Text>

                <Text>
                  Dormitórios:
                  <Text as="span" color="gray.500" ml="2">
                    {`De ${venture.minDormitories || '-'} até ${
                      venture.maxDormitories || '-'
                    }`}
                  </Text>
                </Text>

                <Text>
                  Suítes:
                  <Text as="span" color="gray.500" ml="2">
                    {`De ${venture.minSuites || '-'} até ${
                      venture.maxSuites || '-'
                    }`}
                  </Text>
                </Text>

                <Text>
                  Vagas:
                  <Text as="span" color="gray.500" ml="2">
                    {`De ${venture.minVacancies || '-'} até ${
                      venture.maxVacancies || '-'
                    }`}
                  </Text>
                </Text>

                <Text>
                  Área priv.:
                  <Text as="span" color="gray.500" ml="2">
                    {`De ${venture.minPrivativeArea || '-'} até ${
                      venture.maxPrivativeArea || '-'
                    }`}
                  </Text>
                </Text>

                <Text>
                  Área útil:
                  <Text as="span" color="gray.500" ml="2">
                    {`De ${venture.minUsefulArea || '-'} até ${
                      venture.maxUsefulArea || '-'
                    }`}
                  </Text>
                </Text>

                <Text>
                  Área total:
                  <Text as="span" color="gray.500" ml="2">
                    {`De ${venture.minTotalArea || '-'} até ${
                      venture.maxTotalArea || '-'
                    }`}
                  </Text>
                </Text>

                <Text>
                  Garagens:
                  <Text as="span" color="gray.500" ml="2">
                    {`${venture.coveredGarages || '-'} cobertas, ${
                      venture.uncoveredGarages || '-'
                    } descobertas`}
                  </Text>
                </Text>

                <Text>
                  Fase:
                  <Text as="span" color="gray.500" ml="2">
                    {Stage[venture.stage] || '-'}
                  </Text>
                </Text>

                <Text>
                  Cadastrado em:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.formattedCreatedAt}
                  </Text>
                </Text>
              </SimpleGrid>

              <Divider borderColor="gray.300" />

              <SimpleGrid
                minChildWidth="315px"
                spacingX="8"
                spacingY="4"
                px="8"
                w="100%"
              >
                <Text>
                  Cidade:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.address.city || '-'}
                  </Text>
                </Text>

                <Text>
                  Estado:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.address.state || '-'}
                  </Text>
                </Text>

                <Text>
                  Rua:
                  <Text as="span" color="gray.500" ml="2">
                    {`${venture.address.street || '-'},
                     ${venture.address.number || '-'}`}
                  </Text>
                </Text>

                <Text>
                  Complemento:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.address.complement || '-'}
                  </Text>
                </Text>

                <Text>
                  Bairro:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.address.neighborhood || '-'}
                  </Text>
                </Text>

                <Text>
                  CEP:
                  <Text as="span" color="gray.500" ml="2">
                    {venture.address.zipCode || '-'}
                  </Text>
                </Text>
              </SimpleGrid>

              <SimpleGrid
                minChildWidth="50%"
                spacingX="8"
                spacingY="4"
                px="8"
                w="100%"
              >
                <Text>
                  Descrição:
                  <Text color="gray.500" ml="2">
                    {venture.description || '-'}
                  </Text>
                </Text>

                <Text>
                  Detalhes da negociação:
                  <Text color="gray.500" ml="2">
                    {venture.negotiationDetails || '-'}
                  </Text>
                </Text>
              </SimpleGrid>
            </VStack>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
