import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Checkbox,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Heading,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Input } from '../../../../../../../components/Form/MaskedInput';
import { UserBase } from '../../../../../../../models/user';
import { teamUsersAssignService } from '../../../../../../../services/Teams/TeamUsersAssignService';
import {
  IUserListItem,
  listUsersService,
} from '../../../../../../../services/Users/ListUsersService';

interface IHandleSelectUserProps {
  userId: string;
  checked: boolean;
  index: number;
}

interface IUsersAssignModalProps {
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (users: UserBase[]) => void;
}

export const UsersAssignModal = ({
  teamId,
  isOpen,
  onClose,
  onSubmit,
}: IUsersAssignModalProps): JSX.Element => {
  const toast = useToast();

  const [usersList, setUsersList] = useState<IUserListItem[]>([]);
  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);
  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  const loadUsers = useCallback(async (name?: string) => {
    const { items } = await listUsersService({
      limit: 10,
      name,
    });

    setUsersList(items);
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    setCheckedItems(Array.from({ length: usersList.length }, () => false));
  }, [usersList]);

  const handleSubmit = useCallback(async () => {
    try {
      const { users } = await teamUsersAssignService({
        teamId,
        usersId: selectedUsersId,
      });

      setCheckedItems((prevState) => prevState.fill(false));
      setSelectedUsersId([]);

      toast({
        title: 'Vinculado com sucesso',
        description: 'O usuário foi vinculado corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      onSubmit(users);
    } catch (err) {
      toast({
        title: 'Falha ao vincular',
        description: 'Ocorreu um erro ao vincular usuário, tente novamente',
        status: 'error',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    }
  }, [onSubmit, selectedUsersId, teamId, toast]);

  const handleToggleSelectAllUsers = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setCheckedItems((prevState) => prevState.fill(event.target.checked));

      if (!event.target.checked) {
        setSelectedUsersId([]);
      } else {
        setSelectedUsersId(usersList.map((user) => user.id));
      }
    },
    [usersList],
  );

  const debounceLoadUsers = debounce(loadUsers, 1000);

  const handleSelectUser = useCallback(
    ({ userId, checked, index }: IHandleSelectUserProps) => {
      setCheckedItems((prevState) =>
        prevState.map((check, checkIndex) =>
          checkIndex === index ? !check : check,
        ),
      );

      if (!checked) {
        setSelectedUsersId((prevState) =>
          prevState.filter((selectedUserId) => selectedUserId !== userId),
        );
      } else {
        setSelectedUsersId((prevState) => [...prevState, userId]);
      }
    },
    [],
  );

  return (
    <Modal size="6xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Vincular corretores
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            label="Filtrar por nome"
            name="name"
            size="sm"
            width="40%"
            onChange={(e) => debounceLoadUsers(e.target.value)}
          />

          <Table colorScheme="blue">
            <Thead>
              <Tr>
                <Th px="6" w="8">
                  <Checkbox
                    colorScheme="blue"
                    borderColor="gray.300"
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={handleToggleSelectAllUsers}
                  />
                </Th>
                <Th>Corretor</Th>
                <Th>Telefone</Th>
                <Th>CRECI</Th>
              </Tr>
            </Thead>

            <Tbody>
              {usersList.map((user, index) => (
                <Tr key={user.id}>
                  <Td px="6">
                    <Checkbox
                      colorScheme="blue"
                      isChecked={checkedItems[index]}
                      borderColor="gray.300"
                      onChange={(event) =>
                        handleSelectUser({
                          userId: user.id,
                          checked: event.target.checked,
                          index,
                        })
                      }
                    />
                  </Td>

                  <Td>
                    <Box>
                      <Text fontWeight="bold">{user.name}</Text>
                      <Text fontSize="sm" color="gray.500">
                        {user.email}
                      </Text>
                    </Box>
                  </Td>

                  <Td>{user.phone}</Td>

                  <Td>{user.creci}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleSubmit}>
              Vincular
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
