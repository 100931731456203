import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import {
  RiCheckboxCircleLine,
  RiCheckboxBlankCircleLine,
  RiEditLine,
  RiDeleteBinLine,
} from 'react-icons/ri';
import { DeleteConfirmationModal } from '../../../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters/index';
import { deleteMediasService } from '../../../../../../../services/Medias/DeleteMediasService';

export type MediaTableItem = {
  id: string;
  isActive: boolean;
  name: string;
  visible: boolean;
};

interface IMediaTableProps extends BoxProps {
  currentOrder?: 'ASC' | 'DESC';
  currentPage?: number;
  filterBy?: string;
  medias: MediaTableItem[];
  onChangeFilterBy?: (value: string) => void;
  onDelete: (mediaId: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'name' | 'isActive' | 'visible') => void;
  sortBy?: string;
  totalPages?: number;
}

export const MediaTable = ({
  currentOrder,
  currentPage,
  filterBy,
  medias,
  onChangeFilterBy,
  onDelete,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  sortBy,
  totalPages,
  ...rest
}: IMediaTableProps): JSX.Element => {
  const toast = useToast();

  const [deletingMediaId, setDeletingMediaId] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(
    (mediaId?: string) => {
      setDeletingMediaId(mediaId);

      setIsDeleteConfirmationModalVisible((prevState) => !prevState);
    },
    [],
  );

  const handleDeleteMedia = useCallback(async () => {
    if (deletingMediaId) {
      try {
        await deleteMediasService(deletingMediaId);

        toast({
          title: 'Excluído com sucesso',
          description: 'O tipo de mídia foi excluído corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        onDelete(deletingMediaId);

        handleToggleDeleteConfirmationModal();
      } catch (err) {
        toast({
          title: 'Falha ao excluir',
          description:
            'Ocorreu um erro ao excluir o tipo de mídia, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [deletingMediaId, handleToggleDeleteConfirmationModal, onDelete, toast]);

  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(sort as 'name' | 'isActive' | 'visible');
      }
    },
    [onSort],
  );

  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Ativo',
        value: 'isActive',
      },
      {
        label: 'Visível',
        value: 'visible',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteMedia}
      />

      <TableFilters
        currentOrder={currentOrder}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={handleSort}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th textAlign="center">Ativo</Th>
            <Th textAlign="center">Visível</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {medias.map((media) => (
            <Tr key={media.id}>
              <Td>
                <Text fontWeight="bold">{media.name}</Text>
              </Td>

              <Td textAlign="center">
                {media.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td textAlign="center">
                {media.visible ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td>
                <ButtonGroup>
                  <LinkButton
                    colorScheme="yellow"
                    color="white"
                    icon={<Icon as={RiEditLine} fontSize="16" />}
                    to={{
                      pathname: '/medias/update',
                      state: {
                        mediaId: media.id,
                      },
                    }}
                  >
                    Editar
                  </LinkButton>

                  <Button
                    size="sm"
                    fontSize="sm"
                    colorScheme="red"
                    onClick={() =>
                      handleToggleDeleteConfirmationModal(media.id)
                    }
                    leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                  >
                    Excluir
                  </Button>
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
