import { Switch } from 'react-router-dom';
import { MediaList } from '../pages/App/Adm/Medias/MediaList';
import { MediaRegister } from '../pages/App/Adm/Medias/MediaRegister';
import { MediaUpdate } from '../pages/App/Adm/Medias/MediaUpdate';
import { PropertyOwnerDetails } from '../pages/App/Adm/PropertyOwners/PropertyOwnerDetails';
import { PropertyOwnerList } from '../pages/App/Adm/PropertyOwners/PropertyOwnerList';
import { PropertyOwnerRegister } from '../pages/App/Adm/PropertyOwners/PropertyOwnerRegister';
import { PropertyOwnerUpdate } from '../pages/App/Adm/PropertyOwners/PropertyOwnerUpdate';
import Reports from '../pages/App/Adm/Reports';
import { TeamDetails } from '../pages/App/Adm/Teams/TeamDetails';
import { TeamList } from '../pages/App/Adm/Teams/TeamList';
import { TeamRegister } from '../pages/App/Adm/Teams/TeamRegister';
import { TeamUpdate } from '../pages/App/Adm/Teams/TeamUpdate';
import { UserDetails } from '../pages/App/Adm/Users/UserDetails';
import { UserList } from '../pages/App/Adm/Users/UserList';
import { UserRegister } from '../pages/App/Adm/Users/UserRegister';
import { UserUpdate } from '../pages/App/Adm/Users/UserUpdate';
import { Dashboard } from '../pages/App/General/Dashboard';
import { AppointmentDetails } from '../pages/App/Ownership/Appointments/AppointmentDetails';
import { AppointmentList } from '../pages/App/Ownership/Appointments/AppointmentsList';
import { LeadDetails } from '../pages/App/Ownership/Leads/LeadDetails';
import { LeadList } from '../pages/App/Ownership/Leads/LeadList';
import { LeadRegister } from '../pages/App/Ownership/Leads/LeadRegister';
import { LeadUpdate } from '../pages/App/Ownership/Leads/LeadUpdate';
import { HandleProperty } from '../pages/App/Ownership/Properties/HandleProperty';
import { PropertyDetails } from '../pages/App/Ownership/Properties/PropertyDetails';
import { PropertyList } from '../pages/App/Ownership/Properties/PropertyList';
import { HandleVenture } from '../pages/App/Ownership/Ventures/HandleVenture';
import { VentureDetails } from '../pages/App/Ownership/Ventures/VentureDetails';
import { VentureList } from '../pages/App/Ownership/Ventures/VentureList';
import { Profile } from '../pages/App/Profile';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { ResetPassword } from '../pages/Auth/ResetPassword';
import { SignIn } from '../pages/Auth/SignIn';
import { Route } from './Route';

export const Routes = (): JSX.Element => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/appointments" exact component={AppointmentList} isPrivate />
    <Route
      path="/appointments/details"
      component={AppointmentDetails}
      isPrivate
    />
    <Route path="/users" exact component={UserList} isPrivate />
    <Route path="/users/details" component={UserDetails} isPrivate />
    <Route path="/users/register" component={UserRegister} isPrivate />
    <Route path="/users/update" component={UserUpdate} isPrivate />
    <Route path="/users/passwords/forgot" component={ForgotPassword} />
    <Route path="/users/passwords/reset" component={ResetPassword} />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/leads" exact component={LeadList} isPrivate />
    <Route path="/leads/details" component={LeadDetails} isPrivate />
    <Route path="/leads/register" component={LeadRegister} isPrivate />
    <Route path="/leads/update" component={LeadUpdate} isPrivate />
    <Route path="/medias" exact component={MediaList} isPrivate />
    <Route path="/medias/register" component={MediaRegister} isPrivate />
    <Route path="/medias/update" component={MediaUpdate} isPrivate />
    <Route path="/profile" exact component={Profile} isPrivate />
    <Route path="/properties" exact component={PropertyList} isPrivate />
    <Route path="/properties/details" component={PropertyDetails} isPrivate />
    <Route path="/properties/register" component={HandleProperty} isPrivate />
    <Route path="/properties/update" component={HandleProperty} isPrivate />
    <Route
      path="/property-owners"
      exact
      component={PropertyOwnerList}
      isPrivate
    />
    <Route
      path="/property-owners/details"
      component={PropertyOwnerDetails}
      isPrivate
    />
    <Route
      path="/property-owners/register"
      component={PropertyOwnerRegister}
      isPrivate
    />
    <Route
      path="/property-owners/update"
      component={PropertyOwnerUpdate}
      isPrivate
    />
    <Route path="/reports" component={Reports} isPrivate />
    <Route path="/teams" exact component={TeamList} isPrivate />
    <Route path="/teams/details" component={TeamDetails} isPrivate />
    <Route path="/teams/register" component={TeamRegister} isPrivate />
    <Route path="/teams/update" component={TeamUpdate} isPrivate />
    <Route path="/ventures" exact component={VentureList} isPrivate />
    <Route path="/ventures/details" component={VentureDetails} isPrivate />
    <Route path="/ventures/register" component={HandleVenture} isPrivate />
    <Route path="/ventures/update" component={HandleVenture} isPrivate />
  </Switch>
);
