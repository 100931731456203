import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Heading,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Input } from '../../../../../../../components/Form/MaskedInput';
import { useAuth } from '../../../../../../../hooks/auth';
import { TeamBase } from '../../../../../../../models/team';
import {
  ITeamListItem,
  listTeamsService,
} from '../../../../../../../services/Teams/ListTeamsService';
import { userTeamsAssignService } from '../../../../../../../services/Users/UserTeamsAssignService';

interface IHandleSelectTeamProps {
  teamId: string;
  checked: boolean;
  index: number;
}

interface ITeamsAssignModalProps {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (teams: TeamBase[]) => void;
}

export const TeamsAssignModal = ({
  userId,
  isOpen,
  onClose,
  onSubmit,
}: ITeamsAssignModalProps): JSX.Element => {
  const toast = useToast();
  const { signOut } = useAuth();

  const [teamsList, setTeamsList] = useState<ITeamListItem[]>([]);
  const [selectedTeamsId, setSelectedTeamsId] = useState<string[]>([]);
  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  const loadTeams = useCallback(async (name?: string) => {
    const { items } = await listTeamsService({
      limit: 10,
      name,
    });

    setTeamsList(items);
  }, []);

  useEffect(() => {
    loadTeams();
  }, [loadTeams]);

  useEffect(() => {
    setCheckedItems(Array.from({ length: teamsList.length }, () => false));
  }, [teamsList]);

  const handleSubmit = useCallback(async () => {
    try {
      const { teams } = await userTeamsAssignService({
        userId,
        teamsId: selectedTeamsId,
      });

      setCheckedItems((prevState) => prevState.fill(false));
      setSelectedTeamsId([]);

      toast({
        title: 'Time vinculado',
        description: 'O time foi vinculado com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      onSubmit(teams);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        signOut();
      }

      toast({
        title: 'Falha ao vincular',
        description: 'Ocorreu um erro ao vincular time, tente novamente',
        status: 'error',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    }
  }, [userId, selectedTeamsId, toast, onSubmit, signOut]);

  const handleToggleSelectAllTeams = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setCheckedItems((prevState) => prevState.fill(event.target.checked));

      if (!event.target.checked) {
        setSelectedTeamsId([]);
      } else {
        setSelectedTeamsId(teamsList.map((team) => team.id));
      }
    },
    [teamsList],
  );

  const debounceLoadUsers = debounce(loadTeams, 1000);

  const handleSelectTeam = useCallback(
    ({ teamId, checked, index }: IHandleSelectTeamProps) => {
      setCheckedItems((prevState) =>
        prevState.map((check, checkIndex) =>
          checkIndex === index ? !check : check,
        ),
      );

      if (!checked) {
        setSelectedTeamsId((prevState) =>
          prevState.filter((selectedTeamId) => selectedTeamId !== teamId),
        );
      } else {
        setSelectedTeamsId((prevState) => [...prevState, teamId]);
      }
    },
    [],
  );

  return (
    <Modal size="4xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Vincular times
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            label="Filtrar por nome"
            name="name"
            size="sm"
            width="40%"
            onChange={(e) => debounceLoadUsers(e.target.value)}
          />

          <Table colorScheme="blue">
            <Thead>
              <Tr>
                <Th px="6" w="8">
                  <Checkbox
                    colorScheme="blue"
                    borderColor="gray.300"
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={handleToggleSelectAllTeams}
                  />
                </Th>
                <Th>Nome</Th>
                <Th>Tipo</Th>
                <Th>Interesse</Th>
                <Th>Máximo de leads</Th>
              </Tr>
            </Thead>

            <Tbody>
              {teamsList.map((team, index) => (
                <Tr key={team.id}>
                  <Td px="6">
                    <Checkbox
                      colorScheme="blue"
                      isChecked={checkedItems[index]}
                      borderColor="gray.300"
                      onChange={(event) =>
                        handleSelectTeam({
                          teamId: team.id,
                          checked: event.target.checked,
                          index,
                        })
                      }
                    />
                  </Td>

                  <Td>
                    <Text fontWeight="bold">{team.name}</Text>
                  </Td>

                  <Td>{team.type}</Td>

                  <Td>{team.interest}</Td>

                  <Td>{team.leadsMax}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleSubmit}>
              Vincular
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
