import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';

interface ITeamUsersAssignProps {
  teamId: string;
  usersId: Array<string>;
}

export interface ITeamUsersAssign extends TeamBase {
  users: UserBase[];
}

export const teamUsersAssignService = async ({
  teamId,
  usersId,
}: ITeamUsersAssignProps): Promise<ITeamUsersAssign> => {
  const { data } = await api.post<ITeamUsersAssign>(
    `/teams/${teamId}/users-assign`,
    { usersId },
  );

  return data;
};
