import axios from 'axios';
import {
  Box,
  Heading,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import 'react-datepicker/dist/react-datepicker.css';
import { IPropertyFormData, Property } from './Property';
import { PropertyImages } from './PropertyImages';
import { PropertyAddress, PropertyAddressFormData } from './PropertyAddress';
import {
  IDetailedProperty,
  showPropertiesService,
} from '../../../../../services/Properties/ShowPropertiesService';
import { createPropertiesService } from '../../../../../services/Properties/CreatePropertiesService';
import { updatePropertiesService } from '../../../../../services/Properties/UpdatePropertiesService';

type PropertyOwner = {
  id: string;
  name: string;
};

type Venture = {
  id: string;
  title: string;
};

interface IProperty
  extends Omit<IDetailedProperty, 'propertyOwner' | 'venture'> {
  propertyOwner?: PropertyOwner;
  venture?: Venture;
}

interface ILocationState {
  propertyId?: string;
}

export const HandleProperty = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();

  const [tabIndex, setTabIndex] = useState(0);
  const [property, setProperty] = useState<IProperty>();

  useEffect(() => {
    async function loadProperty(showPropertyId: string): Promise<void> {
      const propertyDetails = await showPropertiesService(showPropertyId);

      setProperty(propertyDetails);
    }

    if (state?.propertyId) {
      loadProperty(state?.propertyId);
    }
  }, [state?.propertyId]);

  const handleTabChange = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const handleNewProperty = useCallback((propertyData: IPropertyFormData) => {
    setProperty(propertyData);
    setTabIndex(1);
  }, []);

  const handleSubmitProperty = useCallback(
    async (addressData: PropertyAddressFormData) => {
      if (!state?.propertyId && property) {
        try {
          const newPropeprty = await createPropertiesService({
            ...property,
            address: addressData,
          });

          setProperty({ ...property, ...newPropeprty });
          setTabIndex(2);

          toast({
            title: 'Cadastrado com sucesso',
            description:
              'O imóvel foi cadastrado corretamente, agora adicione algumas fotos',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao cadastrar',
              description:
                'Ocorreu um erro ao cadastrar o imóvel, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      } else if (property?.id) {
        try {
          const updatedProperty = await updatePropertiesService({
            propertyId: property.id,
            ...property,
            address: addressData,
          });

          setProperty({ ...property, ...updatedProperty });

          toast({
            title: 'Editado com sucesso',
            description: 'O imóvel foi editado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description:
                'Ocorreu um erro ao editar o imóvel, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [state?.propertyId, property, toast, goBack],
  );

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Cadastro de imóvel
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Tabs
          index={tabIndex}
          onChange={handleTabChange}
          variant="enclosed-colored"
        >
          <TabList>
            <Tab>Imóvel</Tab>
            <Tab isDisabled={!property}>Endereço</Tab>
            <Tab isDisabled={!property?.id}>Fotos</Tab>
          </TabList>

          <TabPanels>
            <TabPanel mt="4">
              <Property property={property} onSubmit={handleNewProperty} />
            </TabPanel>

            <TabPanel mt="4">
              <PropertyAddress
                address={property?.address}
                onSubmit={handleSubmitProperty}
              />
            </TabPanel>

            <TabPanel>
              <PropertyImages
                images={property?.images || []}
                propertyId={property?.id}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </DefaultLayout>
  );
};
