import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Flex,
  Box,
  Heading,
  VStack,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { RiPencilLine } from 'react-icons/ri';
import venturePlaceholder from '../../../../../assets/venture_placeholder.png';
import { LinkButton } from '../../../../../components/LinkButton';
import { Pagination } from '../../../../../components/Pagination';
import { TableFilters } from '../../../../../components/TableFilters';
import { IPropertyListItem } from '../../../../../services/Properties/ListPropertiesService';

enum BusinessType {
  sell = 'Venda',
  rent = 'Locação',
  sellAndRent = 'Venda e Locação',
}

export interface IPropertyTableItem extends IPropertyListItem {
  coverImageUrl?: string;
  formattedCondominiumPrice?: string;
  formattedDeliveryDate?: string;
  formattedRentPrice?: string;
  formattedSellPrice?: string;
  formattedTotalArea?: string;
  formattedUsefulArea?: string;
}

interface IPropertyTableProps extends BoxProps {
  currentOrder?: 'ASC' | 'DESC';
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (
    sort: 'title' | 'businessType' | 'isActive' | 'isPublished',
  ) => void;
  properties: IPropertyTableItem[];
  sortBy?: string;
  totalPages?: number;
}

export const PropertyTable = ({
  currentOrder,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  properties,
  sortBy,
  totalPages,
  ...rest
}: IPropertyTableProps): JSX.Element => {
  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(sort as 'title' | 'businessType' | 'isActive' | 'isPublished');
      }
    },
    [onSort],
  );

  const filterByOptions = useMemo(
    () => [
      {
        label: 'Título',
        value: 'title',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Título',
        value: 'title',
      },
      {
        label: 'Tipo de negócio',
        value: 'businessType',
      },
      {
        label: 'Ativo',
        value: 'isActive',
      },
      {
        label: 'Publicado',
        value: 'isPublished',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        currentOrder={currentOrder}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={handleSort}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th w="55%" />
            <Th w="25%" />
            <Th w="10%" />
            <Th w="10%" />
          </Tr>
        </Thead>

        <Tbody>
          {properties?.map((property) => (
            <Tr key={property.id}>
              <Td>
                <Flex>
                  <Box
                    bgImage={property.coverImageUrl || venturePlaceholder}
                    bgRepeat="no-repeat"
                    bgSize="cover"
                    borderRadius="8"
                    h="40"
                    minW="240px"
                  />
                  <Flex direction="column" justify="space-between" ml="4">
                    <Box>
                      <Heading fontSize="xl">{property.title}</Heading>
                      <Text color="gray.500">{property.condominiumName}</Text>
                    </Box>
                    <Box>
                      <Text fontSize="sm" color="gray.500" mt="2">
                        {`${property.address.street},  ${property.address.number}`}
                      </Text>

                      <Text fontSize="sm" color="gray.500" mb="4">
                        {`${property.address.neighborhood}, ${property.address.city} - ${property.address.state}`}
                      </Text>

                      <Text as="strong">
                        {property.ref && `${property.ref} / `}
                        <Text as="span" fontWeight="normal">
                          {property.propertyReg}
                        </Text>
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Td>

              <Td>
                <Flex direction="column" justify="space-between" h="40">
                  <Box>
                    <Text fontSize="sm">A partir de:</Text>
                    <Text as="strong" color="blue.500">
                      {property.formattedCondominiumPrice}
                    </Text>
                  </Box>

                  <Box>
                    <Text fontSize="sm">Aluguel partir de:</Text>
                    <Text as="strong" color="blue.500">
                      {property.formattedRentPrice}
                    </Text>
                  </Box>

                  {property.totalArea && (
                    <Box>
                      <Text fontSize="sm">Área total:</Text>
                      <Text as="strong">{property.formattedTotalArea}</Text>
                    </Box>
                  )}

                  <Box>
                    <Text fontSize="sm" color="blue.500">
                      {property.formattedDeliveryDate}
                    </Text>
                  </Box>
                </Flex>
              </Td>

              <Td>
                <Flex direction="column" justify="space-between" h="40">
                  <Box>
                    <Text fontSize="sm">
                      {'Disponível para: '}
                      <Text as="strong" color="blue.500">
                        {BusinessType[property.businessType]}
                      </Text>
                    </Text>
                  </Box>

                  <VStack flex="1" justify="center" align="left" spacing="1">
                    {property.bathrooms && (
                      <Flex align="center">
                        <Box
                          textAlign="center"
                          bgColor="gray.200"
                          borderRadius="8"
                          minW="10"
                          py="0.5"
                        >
                          <Text as="strong" fontSize="sm">
                            {property.bathrooms}
                          </Text>
                        </Box>
                        <Text
                          as="span"
                          fontWeight="normal"
                          ml="2"
                          fontSize="sm"
                        >
                          Banheiros
                        </Text>
                      </Flex>
                    )}

                    {property.rooms && (
                      <Flex align="center">
                        <Box
                          textAlign="center"
                          bgColor="gray.200"
                          borderRadius="8"
                          minW="10"
                          py="0.5"
                        >
                          <Text as="strong" fontSize="sm">
                            {property.rooms}
                          </Text>
                        </Box>
                        <Text
                          as="span"
                          fontWeight="normal"
                          ml="2"
                          fontSize="sm"
                        >
                          Salas
                        </Text>
                      </Flex>
                    )}

                    {property.dormitories && (
                      <Flex align="center">
                        <Box
                          textAlign="center"
                          bgColor="gray.200"
                          borderRadius="8"
                          minW="10"
                          py="0.5"
                        >
                          <Text as="strong" fontSize="sm">
                            {property.dormitories}
                          </Text>
                        </Box>
                        <Text
                          as="span"
                          fontWeight="normal"
                          ml="2"
                          fontSize="sm"
                        >
                          Dormitórios
                        </Text>
                      </Flex>
                    )}

                    {property.suites && (
                      <Flex align="center">
                        <Box
                          textAlign="center"
                          bgColor="gray.200"
                          borderRadius="8"
                          minW="10"
                          py="0.5"
                        >
                          <Text as="strong" fontSize="sm">
                            {property.suites}
                          </Text>
                        </Box>
                        <Text
                          as="span"
                          fontWeight="normal"
                          ml="2"
                          fontSize="sm"
                        >
                          Suítes
                        </Text>
                      </Flex>
                    )}
                  </VStack>
                </Flex>
              </Td>

              <Td>
                <LinkButton
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/properties/details',
                    state: {
                      propertyId: property.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
