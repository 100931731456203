import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';

interface IListTeamProps {
  limit?: number;
  name?: string;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'name' | 'type' | 'interest';
}

export interface ITeamListItem extends TeamBase {
  users: UserBase[];
}

export interface ITeamList {
  items: ITeamListItem[];
  pages: number;
  total: number;
}

export const listTeamsService = async (
  filters?: IListTeamProps,
): Promise<ITeamList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          limit: filters.limit,
          name: filters.name || undefined,
          order: filters.order,
          page: filters.page,
          sort: filters.sort,
        }
      : undefined;

  const { data } = await api.get<ITeamList>('/teams', {
    params,
  });

  return data;
};
