import api from '../api';
import {
  VentureBase,
  VentureAddress,
  VentureImage,
} from '../../models/venture';

interface IUpdateVentureAddressProps {
  city: string;
  complement?: string;
  neighborhood?: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface IUpdateVentureProps {
  ventureId: string;
  address?: IUpdateVentureAddressProps;
  buildings?: number;
  businessType?: 'rent' | 'sell' | 'sellAndRent';
  condominiumName?: string;
  coveredGarages?: number;
  deliveryDate?: string;
  description?: string;
  developerReg?: string;
  floors?: number;
  floorUnits?: number;
  isActive?: boolean;
  isPublished?: boolean;
  images?: VentureImage[];
  maxDormitories?: number;
  maxPrice?: number;
  maxPrivativeArea?: number;
  maxSuites?: number;
  maxTotalArea?: number;
  maxUsefulArea?: number;
  maxVacancies?: number;
  minDormitories?: number;
  minPrice?: number;
  minPrivativeArea?: number;
  minSuites?: number;
  minTotalArea?: number;
  minUsefulArea?: number;
  minVacancies?: number;
  negotiationDetails?: string;
  previousRef?: string;
  ref?: string;
  stage?: 'done' | 'underConstruction';
  title?: string;
  uncoveredGarages?: number;
  videoUrl?: string;
  virtualTourUrl?: string;
}

export interface IUpdatedVenture extends VentureBase {
  address: VentureAddress;
}

export const updateVenturesService = async ({
  ventureId,
  address,
  buildings,
  businessType,
  condominiumName,
  coveredGarages,
  deliveryDate,
  description,
  developerReg,
  floors,
  floorUnits,
  isActive,
  isPublished,
  images,
  maxDormitories,
  maxPrice,
  maxPrivativeArea,
  maxSuites,
  maxTotalArea,
  maxUsefulArea,
  maxVacancies,
  minDormitories,
  minPrice,
  minPrivativeArea,
  minSuites,
  minTotalArea,
  minUsefulArea,
  minVacancies,
  negotiationDetails,
  previousRef,
  ref,
  stage,
  title,
  uncoveredGarages,
  videoUrl,
  virtualTourUrl,
}: IUpdateVentureProps): Promise<IUpdatedVenture> => {
  const { data } = await api.patch<IUpdatedVenture>(`/ventures/${ventureId}`, {
    address,
    buildings,
    businessType,
    condominiumName,
    coveredGarages,
    deliveryDate,
    description,
    developerReg,
    floors,
    floorUnits,
    isActive,
    isPublished,
    images,
    maxDormitories,
    maxPrice,
    maxPrivativeArea,
    maxSuites,
    maxTotalArea,
    maxUsefulArea,
    maxVacancies,
    minDormitories,
    minPrice,
    minPrivativeArea,
    minSuites,
    minTotalArea,
    minUsefulArea,
    minVacancies,
    negotiationDetails,
    previousRef,
    ref,
    stage,
    title,
    uncoveredGarages,
    videoUrl,
    virtualTourUrl,
  });

  return data;
};
