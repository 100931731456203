import {
  Box,
  HStack,
  Select,
  Text,
  Input,
  BoxProps,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useMemo } from 'react';

export type TableFiltersOption = {
  label: string;
  value?: string;
};

interface ITableFiltersProps extends BoxProps {
  filterBy?: string;
  filterByOptions?: TableFiltersOption[];
  filterInputType?: 'input' | 'select';
  filterSelectOptions?: TableFiltersOption[];
  onChangeFilterBy?: (filter: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onSearch?: (searchValue: string) => void;
  onSort?: (sort: string) => void;
  currentOrder?: 'ASC' | 'DESC';
  searchLabel?: string;
  sortBy?: string;
  sortByOptions?: TableFiltersOption[];
}

export const TableFilters = ({
  filterBy,
  filterByOptions = [],
  filterInputType = 'input',
  filterSelectOptions = [],
  onChangeFilterBy,
  onFilter,
  onOrder,
  onSearch,
  onSort,
  currentOrder = 'ASC',
  searchLabel,
  sortBy,
  sortByOptions = [],
  ...rest
}: ITableFiltersProps): JSX.Element => {
  const orderOptions = useMemo(
    () => [
      { label: 'Crescente', value: 'ASC' },
      { label: 'Decrescente', value: 'DESC' },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <HStack spacing="4" align="flex-end">
        {onSearch && (
          <Box>
            <Text>{searchLabel}</Text>
            <Input
              w="96"
              borderColor="gray.300"
              bg="gray.100"
              focusBorderColor="blue.300"
              size="sm"
              variant="outline"
              onChange={(e) => onSearch(e.target.value)}
            />
          </Box>
        )}

        {!!onFilter && (
          <Box>
            <Text>Buscar por</Text>
            <InputGroup size="sm">
              <InputLeftAddon p="0">
                <Select
                  variant="outline"
                  borderColor="gray.300"
                  focusBorderColor="blue.300"
                  size="sm"
                  value={filterBy}
                  onChange={(e) =>
                    !!onChangeFilterBy && onChangeFilterBy(e.target.value)
                  }
                >
                  {filterByOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              </InputLeftAddon>

              {filterInputType === 'input' && (
                <Input
                  w="96"
                  borderColor="gray.300"
                  borderLeftWidth={0}
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  onChange={(e) => onFilter(e.target.value)}
                />
              )}

              {filterInputType === 'select' && (
                <Select
                  w="96"
                  borderColor="gray.300"
                  borderLeftWidth={0}
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  onChange={(e) => onFilter(e.target.value)}
                >
                  {filterSelectOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              )}
            </InputGroup>
          </Box>
        )}

        <Box>
          <HStack>
            {!!onSort && (
              <Box>
                <Text>Ordenar por:</Text>
                <Select
                  borderColor="gray.300"
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  size="sm"
                  value={sortBy}
                  onChange={(e) => onSort(e.target.value)}
                >
                  {sortByOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              </Box>
            )}

            {!!onOrder && (
              <Box>
                <Text>Ordenação:</Text>
                <Select
                  borderColor="gray.300"
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  size="sm"
                  value={currentOrder}
                  onChange={(e) => onOrder(e.target.value as 'ASC' | 'DESC')}
                >
                  {orderOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};
