import {
  Box,
  BoxProps,
  Link as ChakraLink,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AppointmentStatusColor } from '../../../../../../models/appointment';

export interface IAppointmentsFunnel {
  contacted: number;
  deal: number;
  expressedInterest: number;
  followUp: number;
  inactive: number;
  informationsRequested: number;
  negotiation: number;
  ongoing: number;
  preService: number;
  proposal: number;
  scheduledVisit: number;
  visited: number;
  waiting: number;
}

interface IAppointmentsFunnelProps extends BoxProps {
  appointmentsFunnel: IAppointmentsFunnel;
}

export const AppointmentsFunnel = ({
  appointmentsFunnel,
  ...rest
}: IAppointmentsFunnelProps): JSX.Element => (
  <Box p="8" bg="white" borderRadius={8} {...rest}>
    <Text fontSize="lg">Funil de vendas</Text>

    <VStack mt="4" spacing={1}>
      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.waiting}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="100%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'waiting',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Aguardando atendimento: ${appointmentsFunnel.waiting}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.preService}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="95%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'preService',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Pré atendimento: ${appointmentsFunnel.preService}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.contacted}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="90%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'contacted',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Primeiro contato feito: ${appointmentsFunnel.contacted}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.informationsRequested}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="85%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'informationsRequested',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Informações requisitadas: ${appointmentsFunnel.informationsRequested}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.expressedInterest}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="80%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'expressedInterest',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Interesse especificado: ${appointmentsFunnel.expressedInterest}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.ongoing}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="75%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'ongoing',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Manter contato: ${appointmentsFunnel.ongoing}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.scheduledVisit}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="70%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'scheduledVisit',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Visita agendada: ${appointmentsFunnel.scheduledVisit}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.visited}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="65%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'visited',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Visita realizada: ${appointmentsFunnel.visited}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.followUp}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="60%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'followUp',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Follow up: ${appointmentsFunnel.followUp}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.proposal}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="55%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'proposal',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Proposta enviada: ${appointmentsFunnel.proposal}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.negotiation}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="50%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'negotiation',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Em negociação: ${appointmentsFunnel.negotiation}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.deal}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="45%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'deal',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Proposta assinada: ${appointmentsFunnel.deal}`}
        </Text>
      </ChakraLink>

      <ChakraLink
        as={Link}
        bg={AppointmentStatusColor.inactive}
        borderRadius={4}
        color="white"
        px="4"
        py="2"
        textAlign="center"
        width="40%"
        to={{
          pathname: '/appointments',
          state: {
            status: 'inactive',
          },
        }}
        _hover={{
          filter: 'brightness(0.9)',
          transition: 'filter 0.2s',
          textDecoration: 'none',
        }}
      >
        <Text fontSize={['sm', 'md']}>
          {`Inativo: ${appointmentsFunnel.inactive}`}
        </Text>
      </ChakraLink>
    </VStack>
  </Box>
);
