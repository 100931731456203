import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  Text,
  Heading,
} from '@chakra-ui/react';

interface IExistingLeadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ExistingLeadModal = ({
  isOpen,
  onClose,
  onConfirm,
}: IExistingLeadModalProps): JSX.Element => (
  <Modal size="lg" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="lg" fontWeight="normal">
          Lead já cadastrado
        </Heading>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody align="center">
        <Text fontSize="lg">Já existe um lead cadastrado</Text>
        <Text fontSize="lg">com esse endereço de e-mail ou telefone.</Text>
        <Text mt="4">
          Gostaria de navegar até os detalhes do lead existente?
        </Text>
      </ModalBody>

      <ModalFooter>
        <HStack>
          <Button colorScheme="blackAlpha" onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme="green" onClick={onConfirm}>
            Ir para lead existente
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
