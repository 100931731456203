import api from '../api';

type GlobalAppointmentFunnel = {
  contacted: number;
  deal: number;
  expressedInterest: number;
  followUp: number;
  inactive: number;
  informationsRequested: number;
  negotiation: number;
  ongoing: number;
  preService: number;
  proposal: number;
  scheduledVisit: number;
  visited: number;
  waiting: number;
};

export type GlobalStatistic = {
  properties: number;
  totalLeads: number;
  ventures: number;
  appointmentsFunnel: GlobalAppointmentFunnel;
};

export const showGlobalStatisticsService =
  async (): Promise<GlobalStatistic> => {
    const { data } = await api.get<GlobalStatistic>('/statistics');

    return data;
  };
