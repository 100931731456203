import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import {
  PropertyOwnerTableItem,
  PropertyOwnerTable,
} from './components/PropertyOwnerTable';
import { listPropertyOwnersService } from '../../../../../services/PropertyOwners/ListPropertyOwnersService';

interface ILoadPropertyOwnerProps {
  name?: string;
  page?: number;
  sort?: 'name' | 'email' | 'mainPhone';
  order?: 'ASC' | 'DESC';
}
export const PropertyOwnerList = (): JSX.Element => {
  const toast = useToast();

  const [propertyOwnerList, setPropertyOwnerList] = useState<
    PropertyOwnerTableItem[]
  >([]);

  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<'name' | 'email' | 'mainPhone'>('name');
  const [totalPages, setTotalPages] = useState<number>();

  const loadPropertyOwners = useCallback(
    async ({ name, page, sort, order }: ILoadPropertyOwnerProps) => {
      try {
        const { pages, items } = await listPropertyOwnersService({
          name: name || undefined,
          page,
          sort,
          order,
        });

        setTotalPages(pages);
        setPropertyOwnerList(items);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados dos proprietários, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadPropertyOwners({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [
    currentFilters,
    currentOrdering,
    currentPage,
    loadPropertyOwners,
    sortBy,
    toast,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'name' | 'email' | 'mainPhone') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Proprietários
          </Heading>

          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to="/property-owners/register"
          >
            Cadastrar novo
          </LinkButton>
        </Flex>

        <PropertyOwnerTable
          mt="4"
          currentOrder={currentOrdering}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          propertyOwners={propertyOwnerList}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
