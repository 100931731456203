import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  HStack,
  Icon,
} from '@chakra-ui/react';

import axios from 'axios';
import { RiAddLine } from 'react-icons/ri';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { Switch } from '../../../../../components/Form/Switch';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';
import { LeadContactsTable } from '../components/LeadContactsTable';
import {
  ILeadContactsModalFormData,
  LeadContactsModal,
} from '../components/LeadContactsModal';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { listMediasService } from '../../../../../services/Medias/ListMediasService';
import {
  IDetailedLead,
  showLeadsService,
} from '../../../../../services/Leads/ShowLeadsService';
import { updateLeadsService } from '../../../../../services/Leads/UpdateLeadsService';

type SelectOptions = {
  label: string;
  value: string;
};

interface IHandleLeadContactProps extends ILeadContactsModalFormData {
  index?: number;
}

type UpdatingLeadFormData = {
  description?: string;
  email?: string;
  interest: 'rent' | 'sell' | 'sellAndRent';
  isActive: boolean;
  name: string;
  nickname?: string;
  phone?: string;
  mediaId?: string;
};

interface ILocationState {
  leadId?: string;
}

const leadUpdateFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Requerido'),
  nickname: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  email: Yup.string()
    .email('E-mail inválido')
    .nullable()
    .transform((value, originalValue) =>
      !originalValue ? null : value.toLowerCase(),
    ),
  phone: Yup.string()
    .when('email', {
      is: (val: string) => !val,
      then: Yup.string().nullable().required('Requerido'),
    })
    .nullable()
    .transform((value, originalValue) => (!originalValue ? null : value)),
  interest: Yup.string().required('Requerido'),
  isActive: Yup.boolean().required(),
  mediaId: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const LeadUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();

  const [leadContacts, setLeadContacts] = useState<
    ILeadContactsModalFormData[]
  >([]);
  const [updatingLead, setUpdatingLead] = useState<IDetailedLead>();
  const [mediaSelectOptions, setMediaSelectOptions] = useState<SelectOptions[]>(
    [],
  );
  const [updatingLeadContact, setUpdatingLeadContact] =
    useState<ILeadContactsModalFormData>();
  const [isLeadContactsModalVisible, setIsLeadContactsModalVisible] =
    useState(false);

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(leadUpdateFormSchema),
  });

  const { errors } = formState;
  const { leadId } = state;

  useEffect(() => {
    async function loadMediaSelectOptions(): Promise<void> {
      try {
        const { items } = await listMediasService();

        const parsedMediaSelectOptions = items.map((media) => ({
          label: media.name,
          value: media.id,
        }));

        setMediaSelectOptions(parsedMediaSelectOptions);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados dos tipos de mídia, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    async function loadLead(showLeadId: string): Promise<void> {
      try {
        const leadDetails = await showLeadsService(showLeadId);

        setUpdatingLead(leadDetails);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do lead, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadMediaSelectOptions();

    if (leadId) {
      loadLead(leadId);
    }
  }, [leadId, toast]);

  useEffect(() => {
    setLeadContacts(updatingLead?.contacts || []);

    reset({
      description: updatingLead?.description,
      email: updatingLead?.email,
      interest: updatingLead?.interest,
      isActive: updatingLead?.isActive,
      name: updatingLead?.name,
      nickname: updatingLead?.nickname,
      phone: updatingLead?.phone,
      mediaId: updatingLead?.mediaId,
    });
  }, [reset, updatingLead, mediaSelectOptions]);

  const handleLeadContactsModal = useCallback(
    (leadContact?: ILeadContactsModalFormData) => {
      setUpdatingLeadContact(leadContact);

      setIsLeadContactsModalVisible((prevState) => !prevState);
    },
    [],
  );

  const handleLeadContact = useCallback(
    ({ index, ...rest }: IHandleLeadContactProps) => {
      if (index || index === 0) {
        setLeadContacts((prevState) =>
          prevState.map((contact, idx) =>
            idx === index ? { ...contact, ...rest } : contact,
          ),
        );

        setUpdatingLeadContact(undefined);
        handleLeadContactsModal();
      } else {
        setLeadContacts((prevState) => [...prevState, rest]);
        handleLeadContactsModal();
      }
    },
    [handleLeadContactsModal],
  );

  const handleDeleteContact = useCallback((contactIndex: number) => {
    setLeadContacts((prevState) =>
      prevState.filter((contact, index) => index !== contactIndex),
    );
  }, []);

  const handleUpdatingLead: SubmitHandler<UpdatingLeadFormData> = useCallback(
    async (data) => {
      if (leadId) {
        try {
          await updateLeadsService({
            leadId,
            ...data,
            mediaId: data.mediaId || undefined,
            contacts: leadContacts,
          });

          toast({
            title: 'Editado com sucesso',
            description: 'O lead foi editado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description: 'Ocorreu um erro ao editar o lead, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [leadId, leadContacts, toast, goBack],
  );

  const interestSelectOptions = useMemo<SelectOptions[]>(
    () => [
      { label: 'Locação', value: 'rent' },
      { label: 'Compra', value: 'sell' },
      { label: 'Compra e locação', value: 'sellAndRent' },
    ],
    [],
  );

  return (
    <DefaultLayout>
      <LeadContactsModal
        isOpen={isLeadContactsModalVisible}
        leadContact={updatingLeadContact}
        onClose={() => handleLeadContactsModal(undefined)}
        onConfirm={handleLeadContact}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdatingLead)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar lead
        </Heading>

        <Divider my="6" borderColor="gray.300" />
        {updatingLead && (
          <>
            <VStack spacing="8">
              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <Input
                  label="Cliente"
                  error={errors.name}
                  {...register('name')}
                />
                <Input
                  label="Apelido"
                  error={errors.nickname}
                  {...register('nickname')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <Input
                  label="E-mail"
                  textTransform="lowercase"
                  error={errors.email}
                  {...register('email')}
                />

                <HStack>
                  <InternationalPhoneInput
                    name="phone"
                    label="Telefone"
                    control={control}
                    error={errors.phone}
                  />
                  <ReactSelect
                    name="interest"
                    control={control}
                    label="Interesse"
                    options={interestSelectOptions}
                    error={errors.interest}
                  />
                </HStack>
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <ReactSelect
                  name="mediaId"
                  control={control}
                  label="Tipo de mídia"
                  options={mediaSelectOptions}
                  error={errors.mediaId}
                />

                <Switch
                  label="Ativo"
                  error={errors.isActive}
                  {...register('isActive')}
                />
              </SimpleGrid>

              <Input
                as="textarea"
                minHeight="100px"
                resize="none"
                py="2"
                label="Descrição"
                error={errors.description}
                {...register('description')}
              />

              <Box w="100%">
                <HStack spacing="8" mb="8" align="flex-end">
                  <Heading size="md" fontWeight="normal">
                    Contatos
                  </Heading>

                  <Button
                    size="sm"
                    fontSize="sm"
                    colorScheme="green"
                    variant="link"
                    onClick={() => handleLeadContactsModal(undefined)}
                    leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                  >
                    Cadastrar contato
                  </Button>
                </HStack>

                <LeadContactsTable
                  leadContacts={leadContacts}
                  onDelete={handleDeleteContact}
                  onEdit={handleLeadContactsModal}
                />
              </Box>
            </VStack>

            <Flex mt="12" justify="flex-end">
              <ButtonGroup>
                <Button colorScheme="blackAlpha" onClick={goBack}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  colorScheme="green"
                  isLoading={formState.isSubmitting}
                >
                  Salvar
                </Button>
              </ButtonGroup>
            </Flex>
          </>
        )}
      </Box>
    </DefaultLayout>
  );
};
