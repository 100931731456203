import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import { MouseEventHandler, useCallback, useState } from 'react';
import { appointmentOpportunitiesAssignService } from '../../../../../../../services/Appointments/AppointmentOpportunitiesAssignService';
import { IDetailedProperty } from '../../../../../../../services/Properties/ShowPropertiesService';
import { IDetailedVenture } from '../../../../../../../services/Ventures/ShowVenturesService';
import {
  maskArea,
  maskDate,
  maskMoney,
} from '../../../../../../../utils/formatters/handleMask';
import { OpportunityTable } from './components/OpportunityTable';

export interface IVenture extends IDetailedVenture {
  coverImageUrl?: string;
  formattedDeliveryDate: string;
  formattedPrice: string;
  formattedMinPrivativeArea: string;
}
interface IProperty extends IDetailedProperty {
  coverImageUrl?: string;
  formattedDeliveryDate?: string;
  formattedRentPrice?: string;
  formattedSellPrice?: string;
  formattedTotalArea?: string;
  formattedUsefulArea?: string;
}

interface IAppointmentOpportunityAssignResponse {
  properties: IProperty[];
  ventures: IVenture[];
}

interface ILeadsAssignModalProps {
  appointmentId: string;
  isOpen: boolean;
  onClose: () => void;
  onOpportunityAssign: (data: IAppointmentOpportunityAssignResponse) => void;
}

export const AppointmentOportunityAssignModal = ({
  appointmentId,
  isOpen,
  onClose,
  onOpportunityAssign,
}: ILeadsAssignModalProps): JSX.Element => {
  const toast = useToast();

  const [selectedVenturesId, setSelectedVenturesId] = useState<string[]>([]);
  const [selectedPropertiesId, setSelectedPropertiesId] = useState<string[]>(
    [],
  );

  const handleSelectedProperties = useCallback(
    (selectedProperties: string[]) => {
      setSelectedPropertiesId(selectedProperties);
    },
    [],
  );

  const handleSelectedVentures = useCallback((selectedVentures: string[]) => {
    setSelectedVenturesId(selectedVentures);
  }, []);

  const handleAppointmentSubmit: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      try {
        const updatedAppointment = await appointmentOpportunitiesAssignService({
          appointmentId,
          propertiesId: selectedPropertiesId,
          venturesId: selectedVenturesId,
        });

        const parsedProperties = updatedAppointment.properties.map(
          (property) => ({
            ...property,
            coverImageUrl: property.images.find((image) => image.isCover)
              ?.imageUrl,
            formattedDeliveryDate: property.deliveryDate
              ? maskDate(property.deliveryDate)
              : '-',
            formattedRentPrice: property.rentPrice
              ? maskMoney(property.rentPrice)
              : '-',
            formattedSellPrice: property.sellPrice
              ? maskMoney(property.sellPrice)
              : '-',
            formattedTotalArea: maskArea(String(property.totalArea)),
            formattedUsefulArea: property.usefulArea
              ? maskArea(String(property.usefulArea))
              : '-',
          }),
        );

        const parsedVentures = updatedAppointment.ventures.map((venture) => ({
          ...venture,
          coverImageUrl: venture.images.find((image) => image.isCover)
            ?.imageUrl,
          formattedDeliveryDate: venture.deliveryDate
            ? maskDate(venture.deliveryDate)
            : '-',
          formattedPrice: venture.minPrice ? maskMoney(venture.minPrice) : '-',
          formattedMinPrivativeArea: venture.minPrivativeArea
            ? maskArea(venture.minPrivativeArea)
            : '-',
        }));

        onOpportunityAssign({
          properties: parsedProperties,
          ventures: parsedVentures,
        });
        setSelectedPropertiesId([]);
        setSelectedVenturesId([]);

        toast({
          title: 'Vinculado com sucesso',
          description: 'As oportunidades foram vinculadas corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        toast({
          title: 'Falha ao vincular',
          description:
            'Ocorreu um erro ao vincular oportunidades, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }, [
      appointmentId,
      onOpportunityAssign,
      selectedPropertiesId,
      selectedVenturesId,
      toast,
    ]);

  return (
    <Modal size="6xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Vincular oportunidade
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <OpportunityTable
            appointmentId={appointmentId}
            onChangeSelectedProperties={handleSelectedProperties}
            onChangeSelectedVentures={handleSelectedVentures}
            selectedPropertiesId={selectedPropertiesId}
            selectedVenturesId={selectedVenturesId}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              type="submit"
              onClick={handleAppointmentSubmit}
            >
              Vincular
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
