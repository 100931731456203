import {
  Box,
  BoxProps,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import {
  TableFilters,
  TableFiltersOption,
} from '../../../../../../../components/TableFilters';
import {
  AppointmentStatus,
  AppointmentStatusColor,
} from '../../../../../../../models/appointment';
import { IAppointmentListItem } from '../../../../../../../services/Appointments/ListAppointmentsService';
import { listVenturesService } from '../../../../../../../services/Ventures/ListVenturesService';

enum Interest {
  sell = 'Venda',
  rent = 'Locação',
  sellAndRent = 'Venda e locação',
}

export interface IAppointmentTableItem extends IAppointmentListItem {
  parsedDate?: string;
}

interface IAppointmentTableProps extends BoxProps {
  appointments: IAppointmentTableItem[];
  currentOrder?: 'ASC' | 'DESC';
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (
    sort: 'status' | 'leadName' | 'userName' | 'createdAt' | 'interest',
  ) => void;
  sortBy?: string;
  totalPages?: number;
}

export const AppointmentTable = ({
  appointments,
  currentOrder,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  sortBy,
  totalPages,
  ...rest
}: IAppointmentTableProps): JSX.Element => {
  const [filterSelectOptions, setFilterSelectOptions] = useState<
    TableFiltersOption[]
  >([]);

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items } = await listVenturesService();

      setFilterSelectOptions([
        {
          label: 'Todos',
          value: '',
        },
        ...items.map((venture) => ({
          label: venture.title,
          value: venture.id,
        })),
      ]);
    }

    switch (filterBy) {
      case 'leadStatus':
        setFilterSelectOptions([
          { value: '', label: 'Todos' },
          { value: 'active', label: 'Ativos' },
          { value: 'inactive', label: 'Inativos' },
        ]);
        break;

      case 'status':
        setFilterSelectOptions([
          { value: '', label: 'Todos' },
          { value: 'waiting', label: 'Aguardando atendimento' },
          { value: 'preService', label: 'Pré atendimento' },
          { value: 'contacted', label: 'Primeiro contato feito' },
          { value: 'informationsRequested', label: 'Informações requisitadas' },
          { value: 'expressedInterest', label: 'Interesse especificado' },
          { value: 'ongoing', label: 'Manter contato' },
          { value: 'scheduledVisit', label: 'Visita agendada' },
          { value: 'visited', label: 'Visita realizada' },
          { value: 'followUp', label: 'Follow up' },
          { value: 'proposal', label: 'Proposta enviada' },
          { value: 'negotiation', label: 'Em negociação' },
          { value: 'deal', label: 'Proposta assinada' },
          { value: 'inactive', label: 'Inativo' },
        ]);

        break;

      case 'ventureId':
        loadVentures();

        break;

      default:
        break;
    }
  }, [filterBy]);

  const filterInputType = useMemo(() => {
    switch (filterBy) {
      case 'leadStatus':
        return 'select';

      case 'status':
        return 'select';

      case 'ventureId':
        return 'select';

      default:
        return 'input';
    }
  }, [filterBy]);

  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(
          sort as 'status' | 'leadName' | 'userName' | 'createdAt' | 'interest',
        );
      }
    },
    [onSort],
  );

  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome do lead',
        value: 'leadName',
      },
      {
        label: 'Nome do corretor',
        value: 'userName',
      },
      {
        label: 'Empreendimento',
        value: 'ventureId',
      },
      {
        label: 'Status do atendimento',
        value: 'status',
      },
      {
        label: 'Status do lead',
        value: 'leadStatus',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome do lead',
        value: 'leadName',
      },
      {
        label: 'Nome do corretor',
        value: 'userName',
      },
      {
        label: 'Tipo de interesse',
        value: 'interest',
      },
      {
        label: 'Data de abertura',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        currentOrder={currentOrder}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        filterInputType={filterInputType}
        filterSelectOptions={filterSelectOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={handleSort}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Lead</Th>
            <Th>Corretor</Th>
            <Th>Status</Th>
            <Th>Data de abertura</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {appointments.map((appointment) => (
            <Tr key={appointment.id}>
              <Td>
                <Box>
                  <Text fontWeight="bold">{appointment.lead.name}</Text>
                  <Text>{appointment.lead.nickname}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {Interest[appointment.lead.interest]}
                  </Text>
                </Box>
              </Td>

              <Td>{appointment.user.name}</Td>

              <Td>
                <Box
                  borderRadius={8}
                  bg={AppointmentStatusColor[appointment.status]}
                  textAlign="center"
                  padding="2"
                >
                  <Text color="white">
                    {AppointmentStatus[appointment.status]}
                  </Text>
                </Box>
              </Td>

              <Td>{appointment.parsedDate}</Td>

              <Td>
                <LinkButton
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/appointments/details',
                    state: {
                      appointmentId: appointment.id,
                      leadId: appointment.lead.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
