import api from '../api';
import { AppointmentBase } from '../../models/appointment';
import { UserBase } from '../../models/user';

interface IUpdateAppointmentProps {
  appointmentId: string;
  observation?: string;
  userId?: string;
}

export interface IUpdatedAppointment extends AppointmentBase {
  user: UserBase;
}

export const updateAppointmentsService = async ({
  appointmentId,
  observation,
  userId,
}: IUpdateAppointmentProps): Promise<IUpdatedAppointment> => {
  const { data } = await api.patch<IUpdatedAppointment>(
    `/appointments/${appointmentId}`,
    { observation, userId },
  );

  return data;
};
