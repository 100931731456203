import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';
import { UserRoleBase } from '../../models/userRole';

interface IUserTeamsAssignProps {
  userId: string;
  teamsId: Array<string>;
}

export interface IUserTeamsAssign extends UserBase {
  teams: TeamBase[];
  userRoles: UserRoleBase[];
}

export const userTeamsAssignService = async ({
  teamsId,
  userId,
}: IUserTeamsAssignProps): Promise<IUserTeamsAssign> => {
  const { data } = await api.post<IUserTeamsAssign>(
    `/users/${userId}/teams-assign`,
    { teamsId },
  );

  return data;
};
