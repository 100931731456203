import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';

interface IUpdateTeamProps {
  teamId: string;
  interest: string;
  leadsMax: number;
  maxValue: number;
  minValue: number;
  name: string;
  type: string;
}

export interface IUpdatedTeam extends TeamBase {
  users: UserBase[];
}

export const updateTeamsService = async ({
  teamId,
  interest,
  leadsMax,
  maxValue,
  minValue,
  name,
  type,
}: IUpdateTeamProps): Promise<IUpdatedTeam> => {
  const { data } = await api.patch<IUpdatedTeam>(`/teams/${teamId}`, {
    interest,
    leadsMax,
    maxValue,
    minValue,
    name,
    type,
  });

  return data;
};
