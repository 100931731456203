import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import api from '../services/api';
import {
  authenticateSessionsService,
  ISessionUser,
} from '../services/Auth/AuthenticateSessionsService';

interface IAuthUser extends ISessionUser {
  isAdmin: boolean;
}

interface IAuthState {
  token: string;
  user: IAuthUser;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContextData {
  user: IAuthUser;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut: () => void;
  loading: boolean;
  updateUser: (user: ISessionUser) => void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@JHSF-BROKER:token');
    const user = localStorage.getItem('@JHSF-BROKER:user');
    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      const userJSON: IAuthUser = JSON.parse(user);

      return {
        token,

        user: {
          ...userJSON,
          isAdmin: !!userJSON.userRoles.find(
            (userRole) => userRole.name === 'Administrador',
          ),
        },
      };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        setLoading(true);
        const { token, user } = await authenticateSessionsService({
          email,
          password,
        });

        if (user.isLimited) {
          toast({
            title: 'Não autorizado',
            description: 'Usuário não autorizado.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          return;
        }

        localStorage.setItem('@JHSF-BROKER:token', token);
        localStorage.setItem('@JHSF-BROKER:user', JSON.stringify(user));

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({
          token,
          user: {
            ...user,
            isAdmin: !!user.userRoles.find(
              (userRole) => userRole.name === 'Administrador',
            ),
          },
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha na autenticação',
            description:
              'Ocorreu um erro ao fazer o login, verifique os dados e tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [toast],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@JHSF-BROKER:token');
    localStorage.removeItem('@JHSF-BROKER:user');

    setData({} as IAuthState);
  }, []);

  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response.status === 401) {
          toast({
            title: 'Sessão expirada',
            description: 'Por favor, faça login novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          signOut();
        }

        return Promise.reject(err);
      },
    );
  }, [signOut, toast]);

  const updateUser = useCallback((user: ISessionUser) => {
    localStorage.setItem('@JHSF-BROKER:user', JSON.stringify(user));

    setData((prev) => ({
      token: prev.token,
      user: {
        ...user,
        isAdmin: !!user.userRoles.find(
          (userRole) => userRole.name === 'Administrador',
        ),
      },
    }));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        loading,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IAuthContextData => useContext(AuthContext);

export { AuthProvider, useAuth };
