import {
  Box,
  Heading,
  Divider,
  VStack,
  HStack,
  Flex,
  Button,
  useToast,
  Switch,
  FormLabel,
  ButtonGroup,
} from '@chakra-ui/react';
import { ChangeEvent, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import axios from 'axios';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { createMediasService } from '../../../../../services/Medias/CreateMediasService';

type NewMediaFormData = {
  isActive: boolean;
  name: string;
  visible: boolean;
};

const mediaRegisterFormSchema = Yup.object().shape({
  name: Yup.string().required('Requerido'),
});

export const MediaRegister = (): JSX.Element => {
  const toast = useToast();
  const { push } = useHistory();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(mediaRegisterFormSchema),
  });

  const { errors } = formState;

  const [isActive, setIsActive] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const handleNewMedia: SubmitHandler<NewMediaFormData> = useCallback(
    async ({ name }) => {
      try {
        await createMediasService({ isActive, name, visible: isVisible });

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O tipo de mídia foi cadastrado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/medias');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao cadastrar',
            description:
              'Ocorreu um erro ao cadastrar o tipo de mídia, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [isActive, isVisible, push, toast],
  );

  const handleToggleIsActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked);
    },
    [],
  );

  const handleToggleIsVisible = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsVisible(event.target.checked);
    },
    [],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewMedia)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastro de tipo de mídia
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <VStack spacing="8">
          <Input label="Nome" error={errors.name} {...register('name')} />

          <HStack spacing="8">
            <Flex>
              <FormLabel htmlFor="isActive">Ativo</FormLabel>
              <Switch
                name="isActive"
                id="isActive"
                defaultChecked={isActive}
                onChange={handleToggleIsActive}
              />
            </Flex>

            <Flex>
              <FormLabel htmlFor="visible">Visível</FormLabel>
              <Switch
                name="visible"
                id="visible"
                defaultChecked={isVisible}
                onChange={handleToggleIsVisible}
              />
            </Flex>
          </HStack>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={() => push('/medias')}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
