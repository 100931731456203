import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '../../../../../../../components/Form/MaskedInput';
import { updateAppointmentStatusService } from '../../../../../../../services/Appointments/UpdateAppointmentStatusService';
import { AppointmentHistory } from '../../../../../../../models/appointment';

interface IObservationModalFormData {
  observation?: string;
}

interface IAppointmentObservationModalProps {
  appointmentId: string;
  defaultValue?: string;
  isOpen: boolean;
  onClose: () => void;
  onChangeObservation: (newHistory: AppointmentHistory) => void;
}

const appointmentObservationModalFormSchema = Yup.object().shape({
  observation: Yup.string()
    .nullable()
    .transform((value, originalValue) => (!originalValue ? null : value)),
});

export const AppointmentObservationModal = ({
  appointmentId,
  defaultValue,
  isOpen,
  onClose,
  onChangeObservation,
}: IAppointmentObservationModalProps): JSX.Element => {
  const toast = useToast();
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(appointmentObservationModalFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    reset({ observation: defaultValue });
  }, [defaultValue, reset]);

  const handleObservationSubmit: SubmitHandler<IObservationModalFormData> =
    useCallback(
      async ({ observation }) => {
        try {
          const updatedAppointment = await updateAppointmentStatusService({
            appointmentId,
            description: observation,
          });

          reset({});

          onChangeObservation(updatedAppointment.histories[0]);

          toast({
            title: 'Cadastrado com sucesso',
            description: 'Observação cadastrada corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          toast({
            title: 'Falha ao cadastrar',
            description:
              'Ocorreu um erro ao cadastrar observação, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      },
      [appointmentId, onChangeObservation, reset, toast],
    );

  const handleCloseModal = useCallback(() => {
    onClose();
    reset({});
  }, [onClose, reset]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleObservationSubmit)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Observação
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Input
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Observação"
            error={errors.observation}
            {...register('observation')}
          />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
