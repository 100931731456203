import { SimpleGrid, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { showGlobalStatisticsService } from '../../../../services/Statistics/ShowGlobalStatisticsService';

import { DefaultLayout } from '../../_layout/DefaultLayout';
import {
  AppointmentsFunnel,
  IAppointmentsFunnel,
} from './components/AppointmentsFunnel';

// const options = {
//   chart: {
//     toolbar: {
//       show: false,
//     },
//     zoom: {
//       enabled: false,
//     },
//     foreColor: theme.colors.gray[500],
//   },
//   grid: {
//     show: false,
//   },
//   // dataLabels: {
//   //   enabled: false,
//   // },
//   // tooltip: {
//   //   enabled: false,
//   // },
//   xaxis: {
//     type: 'datetime',
//     axisBorder: {
//       color: theme.colors.gray[500],
//     },
//     axisTicks: {
//       color: theme.colors.gray[500],
//     },
//     categories: [
//       '2021-05-21T00:00:00.000Z',
//       '2021-05-22T00:00:00.000Z',
//       '2021-05-23T00:00:00.000Z',
//       '2021-05-24T00:00:00.000Z',
//       '2021-05-25T00:00:00.000Z',
//       '2021-05-26T00:00:00.000Z',
//       '2021-05-27T00:00:00.000Z',
//     ],
//   },
//   // fill: {
//   //   opacity: 0.3,
//   //   type: 'gradient',
//   //   gradient: {
//   //     shade: 'dark',
//   //     opacityFrom: 0.7,
//   //     opacityTo: 0.3,
//   //   },
//   // },
// } as ApexOptions;

// const pieOptions = {
//   series: [44, 55, 41, 17, 15],
//   labels: ['Apartamentos', 'Fazendas', 'Casas', 'Loteamentos', 'Ilhas'],
// } as ApexOptions;

// const radialOptions = {
//   chart: {
//     offsetY: -20,
//   },
//   series: [70],
//   labels: ['Progress'],
// } as ApexOptions;

// const series = [{ name: 'series1', data: [31, 120, 10, 28, 51, 18, 109] }];
// const pieSeries = [44, 55, 41, 17, 15];
// const radialSeries = [70];
interface IStatistics {
  properties: number;
  ventures: number;
  appointmentsFunnel: IAppointmentsFunnel;
}

export const Dashboard = (): JSX.Element => {
  const toast = useToast();
  const [statistics, setStatistics] = useState<IStatistics>();

  useEffect(() => {
    async function loadStatistics(): Promise<void> {
      try {
        const globalStatistics = await showGlobalStatisticsService();

        setStatistics(globalStatistics);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar dados das estatísticas, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
    loadStatistics();
  }, [toast]);

  return (
    <DefaultLayout>
      <SimpleGrid flex="1" gap="4" minChildWidth="320px" align="flex-start">
        {statistics && (
          <AppointmentsFunnel
            maxW="35rem"
            maxH="42rem"
            appointmentsFunnel={statistics.appointmentsFunnel}
          />
        )}
        {/* <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Vendas da semana</Text>
          <Chart options={options} series={series} type="area" height={160} />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Taxa de negócios concluídos</Text>
          <Chart options={options} series={series} type="area" height={160} />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Novos empreendimentos</Text>
          <Chart
            options={options}
            series={series}
            type="histogram"
            height={160}
          />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Tipos de empreendimentos</Text>
          <Chart
            options={pieOptions}
            series={pieSeries}
            type="donut"
            height={160}
          />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Meta mensal</Text>
          <Chart
            options={radialOptions}
            series={radialSeries}
            type="radialBar"
            height={220}
          />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Vendas da semana</Text>
          <Chart options={options} series={series} type="area" height={160} />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Taxa de negócios concluídos</Text>
          <Chart options={options} series={series} type="area" height={160} />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Novos empreendimentos</Text>
          <Chart
            options={options}
            series={series}
            type="histogram"
            height={160}
          />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Tipos de empreendimentos</Text>
          <Chart
            options={pieOptions}
            series={pieSeries}
            type="donut"
            height={160}
          />
        </Box>
        <Box maxH="250px" p="8" bg="white" borderRadius={8}>
          <Text fontSize="lg">Meta mensal</Text>
          <Chart
            options={radialOptions}
            series={radialSeries}
            type="radialBar"
            height={220}
          />
        </Box> */}
      </SimpleGrid>
    </DefaultLayout>
  );
};
