import api from '../api';
import { MediaBase } from '../../models/media';

interface IUpdateMediaProps {
  mediaId: string;
  isActive: boolean;
  name: string;
  visible: boolean;
}

export type IUpdatedMedia = MediaBase;

export const updateMediasService = async ({
  mediaId,
  isActive,
  name,
  visible,
}: IUpdateMediaProps): Promise<IUpdatedMedia> => {
  const { data } = await api.patch<IUpdatedMedia>(`/medias/${mediaId}`, {
    isActive,
    name,
    visible,
  });

  return data;
};
