import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Flex,
  Button,
  Stack,
  Text,
  Link as ChakraLink,
  useToast,
} from '@chakra-ui/react';

import { Input } from '../../../components/Form/MaskedInput';
import { forgotPasswordsService } from '../../../services/Auth/ForgotPasswordsService';

type ForgotPasswordFormData = {
  email: string;
};

const forgotPasswordFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
});

export const ForgotPassword = (): JSX.Element => {
  const toast = useToast();
  const { push } = useHistory();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  const { errors } = formState;

  const handleForgotPassword: SubmitHandler<ForgotPasswordFormData> =
    useCallback(
      async ({ email }) => {
        try {
          await forgotPasswordsService(email);

          toast({
            title: 'Recuperação de senha solicitada com sucesso',
            description:
              'Foi requisitada uma recuperação de senha, verifique sua caixa de e-mail',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          push('/');
        } catch (err) {
          toast({
            title: 'Falha ao recuperar senha',
            description:
              'Ocorreu um erro ao enviar e-mail de recuperação de senha, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      },
      [push, toast],
    );

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={360}
        bg="white"
        p="8"
        borderRadius={8}
        flexDir="column"
        onSubmit={handleSubmit(handleForgotPassword)}
      >
        <Text fontSize="4xl" fontWeight="bold" letterSpacing="tight">
          JHSF-BROKER
          <Text as="span" ml="1" color="blue.500">
            .
          </Text>
        </Text>

        <Stack spacing="4" mt="10">
          <Input
            label="E-mail"
            type="email"
            textTransform="lowercase"
            error={errors.email}
            {...register('email')}
          />
        </Stack>

        <Button
          type="submit"
          isLoading={formState.isSubmitting}
          mt="8"
          colorScheme="green"
          size="lg"
        >
          Enviar
        </Button>

        <ChakraLink as={Link} to="/" mt="2" mx="auto">
          Voltar para o login
        </ChakraLink>
      </Flex>
    </Flex>
  );
};
