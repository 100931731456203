import { useEffect, useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Link as ChakraLink,
  Text,
  ButtonGroup,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
  VStack,
  SimpleGrid,
  HStack,
} from '@chakra-ui/react';
import {
  RiAddLine,
  RiDeleteBinLine,
  RiEditLine,
  RiFilePaperLine,
  RiHistoryLine,
} from 'react-icons/ri';
import { useToast } from '@chakra-ui/toast';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { AppointmentHistoryModal } from './components/AppointmentHistoryModal';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { AppointmentOportunityAssignModal } from './components/AppointmentOportunityAssignModal';
import {
  AppointmentVentureTable,
  IVentureTableItem,
} from './components/AppointmentVentureTable';
import { AppointmentPropertyTable } from './components/AppointmentPropertyTable';
import {
  maskArea,
  maskDate,
  maskDateTime,
  maskMoney,
} from '../../../../../utils/formatters/handleMask';
import { AppointmentStatusModal } from './components/AppointmentStatusModal';
import { AppointmentUserAssignModal } from '../../components/AppointmentUserAssignModal';
import {
  AppointmentHistory,
  AppointmentStatus,
  AppointmentStatusColor,
} from '../../../../../models/appointment';
import {
  IAppointmentDetailedProperty,
  IDetailedAppointment,
  showAppointmentsService,
} from '../../../../../services/Appointments/ShowAppointmentsService';
import { updateAppointmentsService } from '../../../../../services/Appointments/UpdateAppointmentsService';
import { deleteAppointmentsService } from '../../../../../services/Appointments/DeleteAppointmentsService';
import { useAuth } from '../../../../../hooks/auth';
import { AppointmentObservationModal } from './components/AppointmentObservationModal';

enum Interest {
  sell = 'Venda',
  rent = 'Locação',
  sellAndRent = 'Venda e locação',
}

interface IProperty extends IAppointmentDetailedProperty {
  coverImageUrl?: string;
  formattedDeliveryDate?: string;
  formattedRentPrice?: string;
  formattedSellPrice?: string;
  formattedTotalArea?: string;
  formattedUsefulArea?: string;
}

interface IAppointmentOpportunityAssign {
  properties: IProperty[];
  ventures: IVentureTableItem[];
}

interface IHistory extends AppointmentHistory {
  parsedDate: string;
}

interface IAppointment extends IDetailedAppointment {
  formattedDate: string;
  histories: IHistory[];
  properties: IProperty[];
  ventures: IVentureTableItem[];
}

interface IChangeStatus {
  isDoneDeal: boolean;
  lot?: string;
  square?: string;
  newStatus:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
  newHistory: AppointmentHistory;
  tower?: string;
  unity?: string;
}

interface ILocationState {
  appointmentId?: string;
}

export const AppointmentDetails = (): JSX.Element => {
  const { user } = useAuth();
  const toast = useToast();
  const { goBack } = useHistory();
  const {
    state: { appointmentId },
  } = useLocation<ILocationState>();

  const [tabIndex, setTabIndex] = useState(0);
  const [appointment, setAppointment] = useState<IAppointment>();
  const [isAssignOportunityModalVisible, setIsAssignOportunityModalVisible] =
    useState(false);
  const [isEditStatusModalVisible, setIsEditStatusModalVisible] =
    useState(false);
  const [isObservationModalVisible, setIsObservationModalVisible] =
    useState(false);
  const [isEditAppointmentModalVisible, setIsEditAppointmentModalVisible] =
    useState(false);
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadAppointment(showAppointmentId: string): Promise<void> {
      const appointmentDetails = await showAppointmentsService(
        showAppointmentId,
      );
      const parsedProperties = appointmentDetails.properties.map(
        (property) => ({
          ...property,
          coverImageUrl: property.images.find((image) => image.isCover)
            ?.imageUrl,
          formattedDeliveryDate: property.deliveryDate
            ? maskDate(property.deliveryDate)
            : '-',
          formattedRentPrice: property.rentPrice
            ? maskMoney(property.rentPrice)
            : '-',
          formattedSellPrice: property.sellPrice
            ? maskMoney(property.sellPrice)
            : '-',
          formattedTotalArea: property.totalArea
            ? maskArea(String(property.totalArea))
            : '-',
          formattedUsefulArea: property.usefulArea
            ? maskArea(String(property.usefulArea))
            : '-',
        }),
      );

      const parsedVentures = appointmentDetails.ventures.map((venture) => ({
        ...venture,
        coverImageUrl: venture.images.find((image) => image.isCover)?.imageUrl,
        formattedDeliveryDate: venture.deliveryDate
          ? maskDate(venture.deliveryDate)
          : '-',
        formattedPrice: venture.minPrice ? maskMoney(venture.minPrice) : '-',
        formattedMinPrivativeArea: venture.minPrivativeArea
          ? maskArea(venture.minPrivativeArea)
          : '-',
      }));

      const parsedHistories = appointmentDetails.histories.map((history) => ({
        ...history,
        parsedDate: maskDateTime(history.createdAt),
      }));

      setAppointment({
        ...appointmentDetails,
        formattedDate: maskDate(appointmentDetails.createdAt),
        properties: parsedProperties,
        ventures: parsedVentures,
        histories: parsedHistories,
      });
    }

    if (appointmentId) {
      loadAppointment(appointmentId);
    }
  }, [appointmentId, toast]);

  const handleToggleAssignOportunityModal = useCallback(
    () => setIsAssignOportunityModalVisible((prevState) => !prevState),
    [],
  );

  const handleToggleEditAppointmentModal = useCallback(
    () => setIsEditAppointmentModalVisible((prevState) => !prevState),
    [],
  );

  const handleToggleObservationModal = useCallback(
    () => setIsObservationModalVisible((prevState) => !prevState),
    [],
  );

  const handleToggleEditStatusModal = useCallback(
    () => setIsEditStatusModalVisible((prevState) => !prevState),
    [],
  );

  const handleToggleDeleteConfirmationModal = useCallback(
    () => setIsDeleteConfirmationModalVisible((prevState) => !prevState),
    [],
  );

  const handleToggleHistoryModal = useCallback(
    () => setIsHistoryModalVisible((prevState) => !prevState),
    [],
  );

  const handleAppointmentUserUpdate = useCallback(
    async (selectedUserId: string) => {
      if (appointmentId) {
        try {
          const { user: updatedUser } = await updateAppointmentsService({
            appointmentId,
            userId: selectedUserId,
          });
          setAppointment(
            (prevState) => prevState && { ...prevState, user: updatedUser },
          );

          handleToggleEditAppointmentModal();

          toast({
            title: 'Editado com sucesso',
            description: 'O atendimento foi editado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          toast({
            title: 'Falha ao editar',
            description:
              'Ocorreu um erro ao editar atendimento, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [appointmentId, handleToggleEditAppointmentModal, toast],
  );

  const handleObservationChange = useCallback(
    (newHistory: AppointmentHistory) => {
      setAppointment(
        (prevState) =>
          prevState && {
            ...prevState,
            histories: [
              {
                ...newHistory,
                parsedDate: maskDateTime(newHistory.createdAt),
              },
              ...prevState.histories,
            ],
          },
      );

      handleToggleObservationModal();
    },
    [handleToggleObservationModal],
  );

  const handleStatusChange = useCallback(
    ({ newStatus, newHistory, ...rest }: IChangeStatus) => {
      setAppointment(
        (prevState) =>
          prevState && {
            ...prevState,
            ...rest,
            status: newStatus,
            histories: [
              {
                ...newHistory,
                parsedDate: maskDateTime(newHistory.createdAt),
              },
              ...prevState.histories,
            ],
          },
      );

      handleToggleEditStatusModal();
    },
    [handleToggleEditStatusModal],
  );

  const handleOpportunityAssign = useCallback(
    (data: IAppointmentOpportunityAssign) => {
      setAppointment((prevState) => prevState && { ...prevState, ...data });

      handleToggleAssignOportunityModal();
    },
    [handleToggleAssignOportunityModal],
  );

  const handleVentureUnassign = useCallback((ventureId: string) => {
    setAppointment(
      (prevState) =>
        prevState && {
          ...prevState,
          ventures: prevState.ventures.filter(
            (venture) => venture.id !== ventureId,
          ),
        },
    );
  }, []);

  const handlePropertyUnassign = useCallback((propertyId: string) => {
    setAppointment(
      (prevState) =>
        prevState && {
          ...prevState,
          properties: prevState.properties.filter(
            (property) => property.id !== propertyId,
          ),
        },
    );
  }, []);

  const handleDeleteAppointment = useCallback(async () => {
    if (appointmentId) {
      try {
        await deleteAppointmentsService(appointmentId);

        toast({
          title: 'Excluído com sucesso',
          description: 'O atendimento foi excluído corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        toast({
          title: 'Erro ao excluir',
          description:
            'Ocorreu um erro ao excluir o atendimento, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [appointmentId, goBack, toast]);

  const handleTabChanges = useCallback(() => {
    if (tabIndex === 0) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [tabIndex]);

  return (
    <DefaultLayout>
      {appointment && (
        <>
          <AppointmentOportunityAssignModal
            appointmentId={appointment.id}
            isOpen={isAssignOportunityModalVisible}
            onClose={handleToggleAssignOportunityModal}
            onOpportunityAssign={handleOpportunityAssign}
          />

          <AppointmentUserAssignModal
            defaultValue={appointment.user.id}
            isOpen={isEditAppointmentModalVisible}
            onClose={handleToggleEditAppointmentModal}
            onSubmit={handleAppointmentUserUpdate}
          />

          <AppointmentHistoryModal
            histories={appointment.histories}
            isOpen={isHistoryModalVisible}
            onClose={handleToggleHistoryModal}
          />
        </>
      )}

      {appointmentId && (
        <AppointmentStatusModal
          appointmentId={appointmentId}
          isOpen={isEditStatusModalVisible}
          onClose={handleToggleEditStatusModal}
          onChangeStatus={handleStatusChange}
        />
      )}

      {appointmentId && (
        <AppointmentObservationModal
          appointmentId={appointmentId}
          defaultValue={appointment?.observation}
          isOpen={isObservationModalVisible}
          onClose={handleToggleObservationModal}
          onChangeObservation={handleObservationChange}
        />
      )}

      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAppointment}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do atendimento
          </Heading>

          {user.isAdmin && (
            <ButtonGroup>
              <Button
                size="sm"
                fontSize="sm"
                colorScheme="yellow"
                color="white"
                onClick={handleToggleEditAppointmentModal}
                leftIcon={<Icon as={RiEditLine} fontSize="20" />}
              >
                Editar
              </Button>

              <Button
                size="sm"
                fontSize="sm"
                colorScheme="red"
                onClick={handleToggleDeleteConfirmationModal}
                leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
              >
                Excluir
              </Button>
            </ButtonGroup>
          )}
        </Flex>

        {appointment && (
          <>
            <Flex direction="column">
              <Text>Lead</Text>

              <Box px="2">
                <Text mt="1" fontSize="lg" fontWeight="bold">
                  {appointment.lead.name}
                </Text>

                <SimpleGrid columns={4} spacing="4" w="100%">
                  <Text fontSize="sm" color="gray.500">
                    {`Interesse: ${Interest[appointment.lead.interest]}`}
                  </Text>

                  <Text fontSize="sm" color="gray.500">
                    {`Telefone: ${appointment.lead.phone}`}
                  </Text>

                  <Text fontSize="sm" color="gray.500">
                    {`E-mail: ${appointment.lead.email}`}
                  </Text>

                  <ChakraLink
                    ml="auto"
                    as={Link}
                    to={{
                      pathname: '/leads/details',
                      state: { leadId: appointment.lead.id },
                    }}
                  >
                    <Text color="blue.300">Detalhes</Text>
                  </ChakraLink>
                </SimpleGrid>
              </Box>
            </Flex>

            <Divider my="4" borderColor="gray.300" />

            <Flex direction="column">
              <Text>Corretor</Text>

              <Box px="2">
                <Text mt="1" fontSize="lg" fontWeight="bold">
                  {appointment.user.name}
                </Text>

                <SimpleGrid columns={4} spacing="4" w="100%">
                  <Text fontSize="sm" color="gray.500">
                    {`CRECI: ${appointment.user.creci || '-'}`}
                  </Text>

                  <Text fontSize="sm" color="gray.500">
                    {`Telefone: ${appointment.user.phone || '-'}`}
                  </Text>

                  <Text fontSize="sm" color="gray.500">
                    {`E-mail: ${appointment.user.email}`}
                  </Text>

                  <ChakraLink
                    ml="auto"
                    as={Link}
                    to={{
                      pathname: '/users/details',
                      state: { userId: appointment.user.id },
                    }}
                  >
                    <Text color="blue.300">Detalhes</Text>
                  </ChakraLink>
                </SimpleGrid>
              </Box>
            </Flex>

            <Divider my="4" borderColor="gray.300" />

            <VStack spacing="4">
              <HStack mt="8" justify="center" spacing="8">
                <Text>
                  Data de abertura:
                  <Text as="strong" ml="2" color="blue.500">
                    {appointment.formattedDate}
                  </Text>
                </Text>

                <Text>
                  Status:
                  <Text
                    ml="2"
                    as="strong"
                    color={AppointmentStatusColor[appointment.status]}
                    fontSize="lg"
                  >
                    {AppointmentStatus[appointment.status]}
                  </Text>
                </Text>
              </HStack>
            </VStack>

            {appointment.status === 'deal' && (
              <>
                <VStack alignItems="flex-start" px="4">
                  <Text fontSize="xl" fontWeight="bold" color="green.300">
                    Negócio Fechado
                  </Text>
                  <Text>
                    Unidade:
                    <Text ml="2" as="span" color="gray.500">
                      {appointment.unity || '-'}
                    </Text>
                  </Text>

                  <Flex mt="2" w="50%" align="center" justify="space-between">
                    <Text>
                      Quadra:
                      <Text ml="2" as="span" color="gray.500">
                        {appointment.square || '-'}
                      </Text>
                    </Text>

                    <Text>
                      Lote:
                      <Text ml="2" as="span" color="gray.500">
                        {appointment.lot || '-'}
                      </Text>
                    </Text>

                    <Text>
                      Torre:
                      <Text ml="2" as="span" color="gray.500">
                        {appointment.tower || '-'}
                      </Text>
                    </Text>
                  </Flex>
                </VStack>

                <Divider my="4" borderColor="gray.300" />
              </>
            )}
          </>
        )}

        <Flex mt="8" justify="flex-end" align="center">
          <ButtonGroup>
            <Button
              size="sm"
              fontSize="sm"
              colorScheme="yellow"
              color="white"
              disabled={appointment?.status === 'deal'}
              onClick={handleToggleEditStatusModal}
              leftIcon={<Icon as={RiFilePaperLine} fontSize="20" />}
            >
              Alterar Status
            </Button>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="blue"
              color="white"
              onClick={handleToggleHistoryModal}
              leftIcon={<Icon as={RiHistoryLine} fontSize="20" />}
            >
              Ver histórico
            </Button>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="green"
              color="white"
              onClick={handleToggleObservationModal}
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Observação
            </Button>

            {user.isAdmin && (
              <Button
                size="sm"
                fontSize="sm"
                colorScheme="green"
                color="white"
                disabled={appointment?.status === 'deal'}
                onClick={handleToggleAssignOportunityModal}
                leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              >
                Vincular Oportunidade
              </Button>
            )}
          </ButtonGroup>
        </Flex>

        <Heading size="md" fontWeight="normal">
          Oportunidades vinculadas
        </Heading>

        <Tabs
          index={tabIndex}
          onChange={handleTabChanges}
          variant="enclosed-colored"
          mt="4"
        >
          <TabList>
            <Tab>Empreendimentos</Tab>
            <Tab>Imóveis</Tab>
          </TabList>

          <TabPanels>
            <TabPanel mt="4">
              {appointment?.ventures.map((venture) => (
                <AppointmentVentureTable
                  venture={venture}
                  appointmentId={appointment.id}
                  onVentureUnassign={handleVentureUnassign}
                />
              ))}
            </TabPanel>
            <TabPanel mt="4">
              {appointment?.properties.map((property) => (
                <AppointmentPropertyTable
                  property={property}
                  appointmentId={appointment.id}
                  onPropertyUnassign={handlePropertyUnassign}
                />
              ))}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </DefaultLayout>
  );
};
