import axios from 'axios';
import {
  Box,
  Heading,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import 'react-datepicker/dist/react-datepicker.css';
import { Venture, VentureFormData } from './Venture';
import { VentureAddress, VentureAddressFormData } from './VentureAddress';
import { VentureImages } from './VentureImages';
import { VentureImage } from '../../../../../models/venture';
import { showVenturesService } from '../../../../../services/Ventures/ShowVenturesService';
import { createVenturesService } from '../../../../../services/Ventures/CreateVenturesService';
import { updateVenturesService } from '../../../../../services/Ventures/UpdateVenturesService';

interface ILocationState {
  ventureId?: string;
}

interface IVenture extends VentureFormData {
  id?: string;
  address?: VentureAddressFormData;
  images?: VentureImage[];
}

export const HandleVenture = (): JSX.Element => {
  const toast = useToast();
  const { state } = useLocation<ILocationState | undefined>();
  const { goBack } = useHistory();

  const [tabIndex, setTabIndex] = useState(0);
  const [venture, setVenture] = useState<IVenture>();

  const ventureId = state?.ventureId;

  useEffect(() => {
    async function loadVenture(showVentureId: string): Promise<void> {
      const ventureDetails = await showVenturesService(showVentureId);

      setVenture(ventureDetails);
    }

    if (ventureId) {
      loadVenture(ventureId);
    }
  }, [ventureId]);

  const handleTabChange = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const handleNewVenture = useCallback((ventureData: IVenture) => {
    setVenture(ventureData);
    setTabIndex(1);
  }, []);

  const handleSubmitVenture = useCallback(
    async (addressData: VentureAddressFormData) => {
      if (!ventureId && venture) {
        try {
          const newVenture = await createVenturesService({
            ...venture,
            address: addressData,
            maxPrice: venture.maxPrice ? Number(venture.maxPrice) : undefined,
            maxPrivativeArea: venture.maxPrivativeArea
              ? Number(venture.maxPrivativeArea)
              : undefined,
            maxTotalArea: venture.maxTotalArea
              ? Number(venture.maxTotalArea)
              : undefined,
            maxUsefulArea: venture.maxUsefulArea
              ? Number(venture.maxUsefulArea)
              : undefined,
            minPrice: venture.minPrice ? Number(venture.minPrice) : undefined,
            minPrivativeArea: venture.minPrivativeArea
              ? Number(venture.minPrivativeArea)
              : undefined,
            minTotalArea: venture.minTotalArea
              ? Number(venture.minTotalArea)
              : undefined,
            minUsefulArea: venture.minUsefulArea
              ? Number(venture.minUsefulArea)
              : undefined,
          });

          setVenture(newVenture);
          setTabIndex(2);

          toast({
            title: 'Cadastrado com sucesso',
            description:
              'O empreendimento foi cadastrado corretamente, agora adicione algumas fotos',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao cadastrar',
              description:
                'Ocorreu um erro ao cadastrar empreendimento, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      } else if (venture?.id) {
        try {
          const updatedVenture = await updateVenturesService({
            ventureId: venture.id,
            ...venture,
            address: addressData,
            maxPrice: venture.maxPrice ? Number(venture.maxPrice) : undefined,
            maxPrivativeArea: venture.maxPrivativeArea
              ? Number(venture.maxPrivativeArea)
              : undefined,
            maxTotalArea: venture.maxTotalArea
              ? Number(venture.maxTotalArea)
              : undefined,
            maxUsefulArea: venture.maxUsefulArea
              ? Number(venture.maxUsefulArea)
              : undefined,
            minPrice: venture.minPrice ? Number(venture.minPrice) : undefined,
            minPrivativeArea: venture.minPrivativeArea
              ? Number(venture.minPrivativeArea)
              : undefined,
            minTotalArea: venture.minTotalArea
              ? Number(venture.minTotalArea)
              : undefined,
            minUsefulArea: venture.minUsefulArea
              ? Number(venture.minUsefulArea)
              : undefined,
          });

          setVenture(updatedVenture);

          toast({
            title: 'Editado com sucesso',
            description: 'O empreendimento foi editado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description:
                'Ocorreu um erro ao editar empreendimento, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [goBack, toast, venture, ventureId],
  );

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Cadastro de empreendimento
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Tabs
          index={tabIndex}
          onChange={handleTabChange}
          variant="enclosed-colored"
        >
          <TabList>
            <Tab>Empreendimento</Tab>
            <Tab isDisabled={!venture}>Endereço</Tab>
            <Tab isDisabled={!venture?.id}>Fotos</Tab>
          </TabList>

          <TabPanels>
            <TabPanel mt="4">
              <Venture venture={venture} onSubmit={handleNewVenture} />
            </TabPanel>

            <TabPanel mt="4">
              <VentureAddress
                address={venture?.address}
                onSubmit={handleSubmitVenture}
              />
            </TabPanel>

            <TabPanel>
              <VentureImages
                images={venture?.images || []}
                ventureId={venture?.id}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </DefaultLayout>
  );
};
