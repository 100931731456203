import api from '../api';

interface IListAppointmentProps {
  leadName?: string;
  leadStatus?: string;
  userName?: string;
  status?:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
  sort?: 'leadName' | 'interest' | 'userName' | 'status' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const getAppointmentsReportService = async (
  filters?: IListAppointmentProps,
): Promise<Blob> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          leadName: filters.leadName || undefined,
          leadStatus: filters.leadStatus || undefined,
          userName: filters.userName || undefined,
          status: filters.status,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await api.get<Blob>('/appointments/reports', {
    params,
    responseType: 'blob',
  });

  return data;
};
