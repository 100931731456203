import api from '../api';
import { LeadBase, LeadContact } from '../../models/lead';
import { MediaBase } from '../../models/media';
import { AppointmentBase } from '../../models/appointment';
import { PropertyBase } from '../../models/property';
import { UserBase } from '../../models/user';
import { VentureBase } from '../../models/venture';

export interface ILeadDeailedAppointment extends AppointmentBase {
  properties: PropertyBase[];
  user: UserBase;
  ventures: VentureBase[];
}

export interface IDetailedLead extends LeadBase {
  appointments: ILeadDeailedAppointment[];
  contacts: LeadContact[];
  media: MediaBase;
}

export const showLeadsService = async (
  leadId: string,
): Promise<IDetailedLead> => {
  const { data } = await api.get<IDetailedLead>(`/leads/${leadId}`);

  return data;
};
