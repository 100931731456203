import { Checkbox, Td, Text, Tr } from '@chakra-ui/react';

type Address = {
  number: string;
  street: string;
};

interface IOpportunity {
  address: Address;
  id: string;
  formattedMinPrice?: string;
  formattedSellPrice?: string;
  title: string;
}

interface IToggleSelectOpportunityProps {
  opportunityId: string;
  checked: boolean;
}

interface IOpportunityTableRowProps {
  onToggleSelectOpportunity: (data: IToggleSelectOpportunityProps) => void;
  opportunities: IOpportunity[];
  selectedOpportunitiesId: string[];
}

export const OpportunityTableRow = ({
  onToggleSelectOpportunity,
  opportunities,
  selectedOpportunitiesId,
}: IOpportunityTableRowProps): JSX.Element => (
  <>
    {opportunities.map((opportunity) => (
      <Tr>
        <Td px="6">
          <Checkbox
            colorScheme="blue"
            isChecked={selectedOpportunitiesId.includes(opportunity.id)}
            borderColor="gray.300"
            onChange={(event) =>
              onToggleSelectOpportunity({
                opportunityId: opportunity.id,
                checked: event.target.checked,
              })
            }
          />
        </Td>
        <Td>
          <Text fontWeight="bold">{opportunity.title}</Text>
        </Td>
        <Td>{`${opportunity.address.street}, ${opportunity.address.number}`}</Td>
        <Td>
          {opportunity.formattedSellPrice || opportunity.formattedMinPrice}
        </Td>
      </Tr>
    ))}
  </>
);
