import api from '../api';
import { TeamBase } from '../../models/team';
import { UserBase } from '../../models/user';

export interface IDetailedTeam extends TeamBase {
  users: UserBase[];
}

export const showTeamsService = async (
  teamId: string,
): Promise<IDetailedTeam> => {
  const { data } = await api.get<IDetailedTeam>(`/teams/${teamId}`);

  return data;
};
