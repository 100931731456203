import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { IUserTableItem, UserTable } from './components/UserTable';
import { listUsersService } from '../../../../../services/Users/ListUsersService';

interface ILoadUsersProps {
  name?: string;
  page?: number;
  sort?: 'name' | 'createdAt' | 'email' | 'creci' | 'phone';
  order?: 'ASC' | 'DESC';
}

export const UserList = (): JSX.Element => {
  const toast = useToast();

  const [usersList, setUsersList] = useState<IUserTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<
    'name' | 'createdAt' | 'email' | 'creci' | 'phone'
  >('createdAt');
  const [totalPages, setTotalPages] = useState<number>();

  const loadUsers = useCallback(
    async ({ name, page, sort, order }: ILoadUsersProps) => {
      try {
        const { items, pages } = await listUsersService({
          name: name || undefined,
          page,
          sort,
          order,
        });

        const parsedUsers = items.map((user) => ({
          ...user,
          parsedDate: maskShortDate(user.createdAt),
        }));

        setTotalPages(pages);
        setUsersList(parsedUsers);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos usuários, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadUsers({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [currentFilters, currentOrdering, currentPage, loadUsers, sortBy]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'name' | 'createdAt' | 'email' | 'creci' | 'phone') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Usuários
          </Heading>

          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to="/users/register"
          >
            Cadastrar novo
          </LinkButton>
        </Flex>

        <UserTable
          mt="4"
          currentOrder={currentOrdering}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          sortBy={sortBy}
          totalPages={totalPages}
          users={usersList}
        />
      </Box>
    </DefaultLayout>
  );
};
