import api from '../api';

interface IGetLeadsMonthReportProps {
  endDate: Date;
  startDate: Date;
}

export const getAppointmentsMonthStatusReportService = async ({
  endDate,
  startDate,
}: IGetLeadsMonthReportProps): Promise<Blob> => {
  const { data } = await api.post<Blob>(
    '/appointments/reports/month-status',
    { endDate, startDate },
    {
      responseType: 'blob',
    },
  );

  return data;
};
