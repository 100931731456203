import api from '../api';
import { PropertyImage } from '../../models/property';

interface ICreatePropertyImageProps {
  imageData: FormData;
  propertyId: string;
}

export type INewPropertyImage = PropertyImage;

export const createPropertyImagesService = async ({
  imageData,
  propertyId,
}: ICreatePropertyImageProps): Promise<INewPropertyImage> => {
  const { data } = await api.post<INewPropertyImage>(
    `/images/properties/${propertyId}`,
    imageData,
  );

  return data;
};
