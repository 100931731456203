import api from '../api';
import { UserRoleBase } from '../../models/userRole';
import { UserBase } from '../../models/user';

interface ICreateUserProps {
  bio?: string;
  creci?: string;
  email: string;
  isLimited?: boolean;
  name: string;
  password: string;
  passwordConfirmation: string;
  phone?: string;
  userRoles: UserRoleBase[];
}

export interface INewUser extends UserBase {
  userRoles: UserRoleBase[];
}

export const createUsersService = async ({
  bio,
  creci,
  email,
  isLimited,
  name,
  password,
  passwordConfirmation,
  phone,
  userRoles,
}: ICreateUserProps): Promise<INewUser> => {
  const { data } = await api.post<INewUser>('/users', {
    bio,
    creci,
    email,
    isLimited,
    name,
    password,
    passwordConfirmation,
    phone,
    userRoles,
  });

  return data;
};
