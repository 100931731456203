import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { Input } from '../../../../../components/Form/MaskedInput';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { createPropertyOwnersService } from '../../../../../services/PropertyOwners/CreatePropertyOwnersService';

type NewPropertyOwnerFormData = {
  description?: string;
  email: string;
  idCard?: string;
  mainPhone: string;
  name: string;
  secondaryPhone?: string;
  taxPayerId: string;
};

const propertyOwnerRegisterFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Requerido'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('Requerido')
    .transform((value) => value.toLowerCase()),
  mainPhone: Yup.string()
    .required('Requerido')
    .test('phoneLength', 'Inválido', (value) =>
      value ? value.length > 9 : false,
    )
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  idCard: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  taxPayerId: Yup.string().required('Requerido'),
  secondaryPhone: Yup.string()
    .nullable()
    .test('phoneLength', 'Inválido', (value) =>
      value ? value.length > 9 : true,
    )
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const PropertyOwnerRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();
  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(propertyOwnerRegisterFormSchema),
  });

  const { errors } = formState;

  const handleNewPropertyOwner: SubmitHandler<NewPropertyOwnerFormData> =
    useCallback(
      async ({
        description,
        email,
        name,
        mainPhone,
        secondaryPhone,
        idCard,
        taxPayerId,
      }) => {
        try {
          const newPropertyOwner = await createPropertyOwnersService({
            description,
            email,
            name,
            mainPhone,
            secondaryPhone,
            idCard,
            taxPayerId,
          });

          toast({
            title: 'Cadastrado com sucesso',
            description: 'O proprietário foi cadastrado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          push('/property-owners/details', {
            propertyOwnerId: newPropertyOwner.id,
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao cadastrar',
              description:
                'Ocorreu um erro ao cadastrar proprietário, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [push, toast],
    );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewPropertyOwner)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastro de Proprietário
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="Nome completo"
              error={errors.name}
              {...register('name')}
            />
            <Input
              type="email"
              label="E-mail"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <InternationalPhoneInput
              name="mainPhone"
              label="Telefone"
              control={control}
              error={errors.mainPhone}
            />
            <InternationalPhoneInput
              name="secondaryPhone"
              label="Telefone 2"
              control={control}
              error={errors.secondaryPhone}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="RG"
              mask="rg"
              error={errors.idCard}
              {...register('idCard')}
            />
            <Input
              label="CPF"
              mask="cpf"
              error={errors.taxPayerId}
              {...register('taxPayerId')}
            />
          </SimpleGrid>

          <Input
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Descrição"
            error={errors.description}
            {...register('description')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
