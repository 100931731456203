import api from '../api';
import { MediaBase } from '../../models/media';

interface IListMediaProps {
  limit?: number;
  name?: string;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'name' | 'isActive' | 'visible';
}

export type IMediaListItem = MediaBase;

export interface IMediaList {
  items: IMediaListItem[];
  pages: number;
  total: number;
}

export const listMediasService = async (
  filters?: IListMediaProps,
): Promise<IMediaList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          limit: filters.limit,
          name: filters.name || undefined,
          order: filters?.order,
          page: filters?.page,
          sort: filters?.sort,
        }
      : undefined;

  const { data } = await api.get<IMediaList>('/medias', {
    params,
  });

  return data;
};
