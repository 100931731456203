import api from '../api';
import { PropertyOwnerBase } from '../../models/propertyOwner';

interface ICreatePropertyOwnerProps {
  description?: string;
  email: string;
  idCard?: string;
  mainPhone: string;
  name: string;
  secondaryPhone?: string;
  taxPayerId: string;
}

export type INewPropertyOwner = PropertyOwnerBase;

export const createPropertyOwnersService = async ({
  description,
  email,
  idCard,
  mainPhone,
  name,
  secondaryPhone,
  taxPayerId,
}: ICreatePropertyOwnerProps): Promise<INewPropertyOwner> => {
  const { data } = await api.post<INewPropertyOwner>('/property-owners', {
    description,
    email,
    idCard,
    mainPhone,
    name,
    secondaryPhone,
    taxPayerId,
  });

  return data;
};
