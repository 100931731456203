export enum AppointmentStatusColor {
  contacted = 'orange.400',
  deal = 'green.300',
  expressedInterest = 'yellow.400',
  followUp = 'blue.300',
  inactive = 'gray.400',
  informationsRequested = 'orange.300',
  negotiation = 'teal.300',
  ongoing = 'pink.500',
  proposal = 'cyan.300',
  preService = 'red.300',
  scheduledVisit = 'purple.500',
  visited = 'purple.300',
  waiting = 'red.500',
}

export enum AppointmentStatus {
  contacted = 'Primeiro contato feito',
  deal = 'Proposta assinada',
  expressedInterest = 'Interesse especificado',
  followUp = 'Follow up',
  inactive = 'Inativo',
  informationsRequested = 'Informações requisitadas',
  negotiation = 'Em negociação',
  ongoing = 'Manter contato',
  preService = 'Pré atendimento',
  proposal = 'Proposta enviada',
  scheduledVisit = 'Visita agendada',
  visited = 'Visita realizada',
  waiting = 'Aguardando atendimento',
}

export type AppointmentHistory = {
  id: string;
  description: string;
  status:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
  createdAt: string;
};

export type AppointmentBase = {
  id: string;
  isDoneDeal: boolean;
  lot?: string;
  observation?: string;
  square?: string;
  status:
    | 'contacted'
    | 'deal'
    | 'expressedInterest'
    | 'followUp'
    | 'inactive'
    | 'informationsRequested'
    | 'negotiation'
    | 'ongoing'
    | 'preService'
    | 'proposal'
    | 'scheduledVisit'
    | 'visited'
    | 'waiting';
  tower?: string;
  unity?: string;
  createdAt: string;
};
