import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import axios from 'axios';
import { Input } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { unmaskNumber } from '../../../../../utils/formatters/handleMask';
import { createTeamsService } from '../../../../../services/Teams/CreateTeamsService';

type NewTeamFormData = {
  interest: string;
  leadsMax: number;
  minValue: number;
  maxValue: number;
  name: string;
  type: string;
};

const teamRegisterFormSchema = Yup.object().shape({
  interest: Yup.string().required('Requerido'),
  leadsMax: Yup.number()
    .integer()
    .positive()
    .required('Requerido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  minValue: Yup.number()
    .positive('Inválido')
    .required('Requerido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  maxValue: Yup.number()
    .positive('Inválido')
    .required('Requerido')
    .nullable()
    .transform((_, originalValue) => unmaskNumber(originalValue)),
  name: Yup.string().required('Requerido'),
  type: Yup.string().required('Requerido'),
});

export const TeamRegister = (): JSX.Element => {
  const toast = useToast();
  const { goBack } = useHistory();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(teamRegisterFormSchema),
  });

  const { errors } = formState;

  const handleNewTeam: SubmitHandler<NewTeamFormData> = useCallback(
    async (teamData) => {
      try {
        await createTeamsService(teamData);

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O time foi cadastrado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao cadastrar',
            description: 'Ocorreu um erro ao cadastrar time, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [goBack, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewTeam)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastro de time
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input label="Nome" error={errors.name} {...register('name')} />
            <Input
              label="Tipo de time"
              error={errors.type}
              {...register('type')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="Tipo de interesse"
              error={errors.interest}
              {...register('interest')}
            />
            <Input
              label="Máximo de leads por corretor"
              type="number"
              error={errors.leadsMax}
              {...register('leadsMax')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <Input
              label="Valor mínimo dos empreendimentos"
              error={errors.minValue}
              mask="money"
              {...register('minValue')}
            />
            <Input
              label="Valor máximo dos empreendimentos"
              error={errors.maxValue}
              mask="money"
              {...register('maxValue')}
            />
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
