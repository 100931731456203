import api from '../api';
import { AppointmentBase, AppointmentHistory } from '../../models/appointment';
import {
  PropertyBase,
  PropertyAddress,
  PropertyImage,
} from '../../models/property';
import { UserBase } from '../../models/user';
import {
  VentureBase,
  VentureAddress,
  VentureImage,
} from '../../models/venture';
import { LeadBase } from '../../models/lead';
import { MediaBase } from '../../models/media';

export interface IAppointmentDetailedLead extends LeadBase {
  media: MediaBase;
}

export interface IAppointmentDetailedProperty extends PropertyBase {
  address: PropertyAddress;
  images: PropertyImage[];
}

export interface IAppointmentDetailedVenture extends VentureBase {
  address: VentureAddress;
  images: VentureImage[];
}

export interface IDetailedAppointment extends AppointmentBase {
  histories: AppointmentHistory[];
  lead: IAppointmentDetailedLead;
  properties: IAppointmentDetailedProperty[];
  user: UserBase;
  ventures: IAppointmentDetailedVenture[];
}

export const showAppointmentsService = async (
  appointmentId: string,
): Promise<IDetailedAppointment> => {
  const { data } = await api.get<IDetailedAppointment>(
    `/appointments/${appointmentId}`,
  );

  return data;
};
