import api from '../api';
import { PropertyOwnerBase } from '../../models/propertyOwner';

interface IListPropertyOwnerProps {
  limit?: number;
  name?: string;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'name' | 'email' | 'mainPhone';
}

export type IPropertyOwnerListItem = PropertyOwnerBase;

export interface IPropertyOwnerList {
  items: IPropertyOwnerListItem[];
  pages: number;
  total: number;
}

export const listPropertyOwnersService = async (
  filters?: IListPropertyOwnerProps,
): Promise<IPropertyOwnerList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          limit: filters.limit,
          name: filters.name || undefined,
          order: filters.order,
          page: filters.page,
          sort: filters.sort,
        }
      : undefined;

  const { data } = await api.get<IPropertyOwnerList>('/property-owners', {
    params,
  });

  return data;
};
